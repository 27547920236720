//@ts-ignore
import img from "../../assets/images/pricing.svg"
import { useTranslation } from "react-i18next"


import {
  BsFillClipboardCheckFill,
  BsFillClipboardXFill
} from "react-icons/bs"
import {
  AiOutlineLoading3Quarters
} from "react-icons/ai"

interface LocalProps {
  id: string,
  style?: Object,
  price: string,
  title: string,
  copy?: boolean | false,
  design?: boolean | false,
  actualization?: boolean | false,
  admin?: boolean | false,
  seasons?: boolean | false,
  inDevelopment?: boolean | false

  onMouseOver?: () => void
}
const PricingElement = (props: LocalProps) => {

  const { t } = useTranslation()
  const handleClick = () => {
    if (!props.inDevelopment) {
      setTimeout(() => {
        // document.getElementById(elementId)?.scrollIntoView({ top: 50, behavior: "smooth" })
        window.scrollTo({
          behavior: 'smooth',
          top:
            document.querySelector(`#CONTACT_CARD_ID`)!.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top - 60,
        })
      }, 100);
    }
  }



  const Icon = (prop: boolean = false) =>
    prop
      ?
      <BsFillClipboardCheckFill
        style={{
          color: "#0AA20A",
          marginRight: "10px",
        }}
        size={"18px"}
      />
      :
      <BsFillClipboardXFill
        style={{
          color: "grey",
          marginRight: "10px",
        }}
        size={"18px"}
      />


  return (
    <div className="element" style={{...props.style, filter: (props.inDevelopment) ? "grayscale(90%)" : "none"}} onMouseOver={props.onMouseOver}>



      <div className="price">
      {
        props.inDevelopment
          ?
          <AiOutlineLoading3Quarters
            className="rotate"
            size={"25px"}
          />
          :
          <span>
            { props.price }
            <br />
            <span>
              /{ t("pricingCard.period") }
            </span>
          </span>
        }
      </div>
      <div className="content">
        <div className="upper_part">
          <img src={img} alt="" />
          <span>
            { props.title } <br /> { t("pricingCard.package") }
          </span>
        </div>
        <div className="description">
          {/* <span style={{visibility: "hidden"}}>.</span> <br /> */}
          <span style={{
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "15px"
          }}>
            { t("pricingCard.containing") }:
          </span>
          <ul>
            <li>
              { Icon(props.copy) }
              { t("pricingCard.copy") }
            </li>
            <li>
              { Icon(props.design) }
              { t("pricingCard.design") }
            </li>
            <li>
              { Icon(props.actualization) }
              {
                props.admin
                  ? t("pricingCard.expressActualization")
                  : t("pricingCard.actualization")
              }
            </li>
            <li>
              { Icon(props.admin) }
              { t("pricingCard.adminSystem") }
            </li>
            <li>
              { Icon(props.seasons) }
              { t("pricingCard.seasonalOffer") }
            </li>
          </ul>
        </div>
      </div>
      <div className="action" id={props.id} onClick={handleClick}>

        {
          props.inDevelopment
            ?
              <>
                <AiOutlineLoading3Quarters
                  className="rotate"
                  style={{
                    marginRight: "10px",
                  }}
                  size={"18px"}
                />
                { t("pricingCard.inDevelopment") }
              </>
            : t("pricingCard.getPackage")
        }

      </div>

    </div>
  )
}

export default PricingElement