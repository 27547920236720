import { OfferItemElementProps } from "../../PropTypes/AdminDashboardProps"
import { TbTrash } from "react-icons/tb";
import { FaStar, FaImage  } from "react-icons/fa";
import { IoEyeOutline, IoEyeOffOutline  } from "react-icons/io5";
import { CgFormatSeparator } from "react-icons/cg";
import { IoDocumentText } from "react-icons/io5";
import { invokeAlertComponent } from "../../services/userInteractionService";
/* import { useState } from "react"; */
import { RiSettings4Fill } from "react-icons/ri";




const ItemElementRow = (props: OfferItemElementProps) => {

  /* const [isDragging, setIsDragging] = useState(false) */

  const formatType = (type: any) => {
    switch (type) {
      case "ITEM_REGULAR":
        return "Podsekcia"
      case "ITEM_IMAGE":
        return "Podsekcia"
      case "ITEM_SEPARATOR":
        return "Podsekcia"

      case "HEADLINE_REGULAR":
        return "Podsekcia"
      case "HEADLINE_ICON":
        return "Podsekcia s ikonou"
      case "HEADLINE_DESCRIPTION":
        return "Podsekcia s popisom"
      case "HEADLINE_ICON_DESCRIPTION":
        return "Podsekcia s ikonou a popisom"
      default:
        return ""
    }
  }

  const handleSetRecommended = () => {
    /* {
      uuid: data?.uuid,
      name: data?.item_name,
      alert_text: `Upravili ste položku ${data.item_name}`,
      data: data
    } */
    console.log(props.offerElement)
    const flag_UUID = props.businessEstablishment!.flag_list.filter(element => element.name === "FLAG_1")[0].uuid
    if (!flag_UUID) {
      alert("Missing flag in current business establishment")
      return
    }
    props.onUpdateElement({
      uuid: props.offerElement?.uuid,
      name: props.offerElement?.item_name,
      alert_text:
        !props.offerElement!.item_flags!.includes(flag_UUID)
          ? `Odporučili ste položku ${props.offerElement?.item_name}`
          : `Zrušili ste odporučenie pre položku položku ${props.offerElement?.item_name}`,
      /* data: {item_flags: props.offerElement?.item_flags.includes("FLAG_1") ? props.offerElement?.item_flags.filter(values => values !== "FLAG_1") : props.offerElement?.item_flags.push("FLAG_1")} */
      data: {
        item_flags: props.offerElement!.item_flags!.includes(props.businessEstablishment!.flag_list.filter(element => element.name === "FLAG_1")[0].uuid) ? [] : [props.businessEstablishment?.flag_list.filter(element => element.name === "FLAG_1")[0].uuid]
      }
    })
  }
  const handleAddImage = () => {
    /* props.onUpdateElement({is_recommended: true}) */
    invokeAlertComponent("INFORMATION_ALERT_COMPONENT", "Ak chcete pridať obrázok, tak ma prosím kontaktujte - Tomáš 🌴")
  }
  const handleHideElement = () => {
    props.onUpdateElement({
      uuid: props.offerElement?.uuid,
      name: props.offerElement?.item_name,
      alert_text:
      props.offerElement?.item_is_visible
        ? `Schovali ste položku ${props.offerElement?.item_name}`
        : `Zobrazili ste položku ${props.offerElement?.item_name}`,
      data: {item_is_visible: !props.offerElement?.item_is_visible}
    })
  }
  const handleAddSeparator = () => {
    props.onUpdateElement({
      uuid: props.offerElement?.uuid,
      name: props.offerElement?.item_name,
      alert_text:
      !props.offerElement?.divide_after
        ? `Pridali ste rozdeľovaciu čiaru pod položku ${props.offerElement?.item_name}`
        : `Odstránili ste rozdeľovaciu čiaru pod položkou ${props.offerElement?.item_name}`,
      data: {divide_after: !props.offerElement?.divide_after}
    })
  }

  return(
    <>
      {/* {
        props.index === 0 &&
        <div
          className="droppable_space droppable_space_ontop"
          style={{
            height: isDragging ? "28px" : "0px"
          }}
        >
          drop on top
        </div>
      } */}
      <div
        className="item_element_row card_element drabbable"
        key={props.index}
        /* onClick={() => {props.onOpen(props.offerElement!)}} */
        draggable="true"
        onDragStart={() => {props.onDragStart(props.offerElement!)}}
        onDragEnd={() => {props.onDragEnd(props.offerElement!)}}
        onDragEnter={() => {props.onDragEnter(props.offerElement!)}}
        onDragLeave={() => {props.onDragLeave(props.offerElement!)}}
        onDragOver={() => {props.onDragOver(props.offerElement!)}}
      >
        <div className="table_class_id">
          {props.offerElement!.item_order + 1}.
        </div>
        <div className="table_class_name">
          { props.offerElement?.item_name } {props.offerElement?.item_order}
        </div>
        <div className="table_class_description">
          {
            props.offerElement?.item_description &&
            <>
              <IoDocumentText
                className="icon"
                size={"20px"}
                color="#35BF64"
              />
            </>
          }
        </div>
        <div className="table_class_price">
          { props.offerElement?.item_price }
        </div>
        <div className="table_class_volume">
          { props.offerElement?.item_volume }
        </div>
        <div className="table_class_flag">
          {/* ERROR IN HERE WHEN FLAG IS NOT EXISTING */}
          <FaStar
            className="flag_icon"
            size={"20px"}
            color={ props.offerElement!.item_flags!.includes(props.businessEstablishment!.flag_list.filter(element => element.name === "FLAG_1")[0]?.uuid) ? "#F7C808" : "#A4A4A4" }
            onClick={(e) => {
              /* e.preventDefault() */
              handleSetRecommended()
              e.stopPropagation()
            }}
          />
          <FaImage
            className="flag_icon"
            size={"20px"}
            color={ /* props.offerElement?.icon ? "#18A0FB" :  */"#A4A4A4"}
            onClick={(e) => {
              handleAddImage()
              e.stopPropagation()
            }}
          />
          <CgFormatSeparator
            className="flag_icon"
            size={"20px"}
            color={ props.offerElement?.divide_after ? "#ff4500" : "#A4A4A4"}
            onClick={(e) => {
              handleAddSeparator()
              e.stopPropagation()
            }}
          />
        </div>
        <div className="table_class_type">
          {
            props.offerElement?.item_type !== "ITEM_REGULAR" &&
            <div
              className="status_type"
            >
              { formatType(props.offerElement?.item_type) }
            </div>
          }
        </div>
        <div className="table_class_status">
          {
            !props.offerElement?.item_is_visible
            ?
              <IoEyeOffOutline className="icon" size={"18px"} color="#2C2C2C" onClick={(e) => {
                handleHideElement()
                e.stopPropagation()
              }}/>
            :
              <IoEyeOutline className="icon" size={"18px"} color="#2C2C2C" onClick={(e) => {
                handleHideElement()
                e.stopPropagation()
              }}/>
          }
        </div>
        <div className="table_class_remove">
          <RiSettings4Fill
            className="icon"
            size={"22px"}
            color="#A4A4A4"
            onClick={(event) => {
              event.stopPropagation(); // Prevents the event from propagating up to the item_element_row
              props.onOpen(props.offerElement!)
            }}
          />
          <TbTrash
            className="icon"
            size={"22px"}
            color="#FF2D55"
            onClick={(event) => {
              event.stopPropagation(); // Prevents the event from propagating up to the item_element_row
              props.onDelete(props.offerElement!);
            }}
          />

        </div>
      </div>
      {/* <div
        className="droppable_space droppable_space_below"
        style={{
          height: isDragging ? "28px" : "0px"
        }}
      >
        drop below
      </div> */}
    </>
  )
}

export default ItemElementRow