import  { useRef, useEffect } from 'react';
// @ts-ignore
const HtmlParser = ({ children }) => {

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      ref.current.innerHTML = children;
    }
  }, [children]);

  return <span ref={ref}></span>
}

export default HtmlParser