
import { useEffect, useState } from "react";
import {Outlet} from "react-router-dom";
import Header from '../components/global/Header';
import HalloweenAnimation from "../components/seasonalAnimations/HalloweenAnimation";

const DefaultLayout = () => {


  const [render, setRender] = useState(false)
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    setTimeout(() => {
      setRender(true)
    }, 700)
  }, [])

  return (
    <div className="default_layout" id="DEFAULT_LAYOUT_ID">
      {
        render &&
        <>
          <Header />
          <Outlet />
          <span
            style={{
              position: "static",
              fontWeight: 400,
              marginLeft: "5px",
              marginBottom: "5px",
              zIndex: 999
            }}
          >
            © Tomáš Meždej {currentYear}
          </span>
        </>
      }
    </div>
  );
};

export default DefaultLayout;

