import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const domace_limonady = [
  {
    item_uuid: uuid(),
    title: "Americká limonáda",
    descr: "čerstvá pomarančová šťava, citrónová šťava, trstinový cukor, bazový sirup, mäta, sóda, ľad",
    volume: "0,4 l / 1,0 l",
    price: "3,90 € / 7,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Citronáda",
    descr: "limetková šťava, sóda, cukor, plátok citróna, ľad",
    volume: "0,4 l / 1,0 l",
    price: "2,40 € / 5,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Malina - Rebarbora",
    descr: "rebarbora - pyré, malinový sirup, ľad, sóda",
    volume: "0,4 l / 1,0 l",
    price: "3,50 € / 7,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kiwi - Mäta",
    descr: "kiwi - pyré, citrónová šťava, mätové listy, sóda, ľad",
    volume: "0,4 l / 1,0 l",
    price: "3,50 € / 7,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mango - Mandarínka",
    descr: "mango - pyré, mandarínka - pyré, citrónová šťava, sóda, ľad",
    volume: "0,4 l / 1,0 l",
    price: "3,50 € / 7,40 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]