//@ts-nocheck
import uuid from "react-uuid"


import { zimnaPonuka } from "./elements/zimna_ponuka"
import { letnaPonuka } from "./elements/letna_ponuka"
import { jedla } from "./elements/jedla"
import { hamburger } from "./elements/hamburger"
import { nieco_k_pivu } from "./elements/nieco_k_pivu"
import { pizza } from "./elements/pizza"
import { vino } from "./elements/vino"
import { alko } from "./elements/alko"
import { alko_nealko_miesane } from "./elements/alko_nealko_miesane"
import { limonady } from "./elements/limonady"
import { kava } from "./elements/kava"
import { pivo } from "./elements/pivo"

import snowGlovesIcon from "../../assets/images/clients/icons/predna_hora/snow_gloves.svg"
import summerOfferIcon from "../../assets/images/clients/icons/predna_hora/summerOfferIcon.svg"
import mealIcon from "../../assets/images/clients/icons/predna_hora/food.svg"
import hamburgerIcon from "../../assets/images/clients/icons/predna_hora/hamburger.svg"
import pizzaIcon from "../../assets/images/clients/icons/predna_hora/pizza.svg"
import niecoKPivu from "../../assets/images/clients/icons/predna_hora/fries.svg"

import wineIcon from "../../assets/images/clients/icons/predna_hora/glass-with-wine.svg"
import alcoIcon from "../../assets/images/clients/icons/predna_hora/whiskey.svg"

import stirredIcon from "../../assets/images/clients/icons/predna_hora/cocktail.svg"
import lemonadesIcon from "../../assets/images/clients/icons/predna_hora/lemonade.svg"

import coffeeIcon from "../../assets/images/clients/icons/predna_hora/coffee-cup.svg"
import beerIcon from "../../assets/images/clients/icons/predna_hora/beer.svg"


const borderWidth = "10px"

export const prednaHoraMenu =  [
  /* [
    {
      id: "ZIMNA_PONUKA_ID",
      icon: snowGlovesIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: "Zimné menu",
      title: "Zimné menu",
      list: zimnaPonuka,
      isOpen: false,
      backgroundColorStyle: `${borderWidth} solid rgba(230,177,29,1)`
      // backgroundColorStyle: "linear-gradient(90deg, rgba(230,177,29,1) 0%, rgba(0,0,0,0.6446953781512605) 100%)"
    },
  ], */
  [
    {
      id: "LETNA_PONUKA_ID",
      icon: summerOfferIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: "Letné menu",
      title: "Letné menu",
      list: letnaPonuka,
      isOpen: false,
      backgroundColorStyle: `${borderWidth} solid rgba(230,177,29,1)`
      // backgroundColorStyle: "linear-gradient(90deg, rgba(230,177,29,1) 0%, rgba(0,0,0,0.6446953781512605) 100%)"
    },
  ],
  [
    {
      id: "PIVO_ID",
      icon: beerIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Pivo",
      title: "Pivo",
      list: pivo,
      isOpen: false,
      backgroundColorStyle: `${borderWidth} solid rgba(230,177,29,1)`
      // backgroundColorStyle: "linear-gradient(90deg, rgba(230,177,29,1) 0%, rgba(0,0,0,0.6446953781512605) 100%)"
    },
    {
      id: "PIZZA_ID",
      icon: pizzaIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Pizza",
      title: "Pizza",
      list: pizza,
      isOpen: false,
      backgroundColorStyle: `${borderWidth} solid  rgba(250,126,30,1)`
      // backgroundColorStyle: "linear-gradient(90deg, rgba(250,126,30,1) 0%, rgba(0,0,0,0.6446953781512605) 100%)"
    },
  ],
  [
    {
      id: "HAMBURGER_ID",
      icon: hamburgerIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Hamburger<br/>Tortilla",
      title: "Hamburger / Tortilla",
      list: hamburger,
      isOpen: false,
      backgroundColorStyle: `${borderWidth} solid  rgba(250,126,30,1)`
      // backgroundColorStyle: "linear-gradient(90deg, rgba(250,126,30,1) 0%, rgba(0,0,0,0.6446953781512605) 100%)"
    },
    {
      id: "NIECO_K_PIVU_ID",
      icon: niecoKPivu,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Niečo k pivu",
      title: "Niečo k pivu",
      list: nieco_k_pivu,
      isOpen: false,
      backgroundColorStyle: `${borderWidth} solid  rgba(250,126,30,1)`
      // backgroundColorStyle: "linear-gradient(90deg, rgba(250,126,30,1) 0%, rgba(0,0,0,0.6446953781512605) 100%)"
    }
  ],
  [
    {
      id: "JEDLA_ID",
      icon: mealIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Hlavné jedlá",
      title: "Hlavné jedlá",
      list: jedla,
      isOpen: false,
      backgroundColorStyle: `${borderWidth} solid rgba(103,53,16,1)`
      // backgroundColorStyle: "linear-gradient(90deg, rgba(103,53,16,1) 0%, rgba(0,0,0,0.6446953781512605) 100%)"
    },
    {
      id: "ALKO_ID",
      icon: alcoIcon,
      iconWidth: "30px",
      page_uuid: uuid(),
      titleShort: "Alko",
      title: "Alko",
      list: alko,
      isOpen: false,
      backgroundColorStyle: `${borderWidth} solid rgba(230,29,29,1)`
      // backgroundColorStyle: "linear-gradient(90deg, rgba(230,29,29,1) 0%, rgba(0,0,0,0.6446953781512605) 100%)"
    }
  ],
  [
    {
      id: "VINO_ID",
      icon: wineIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Vína",
      title: "Vína",
      list: vino,
      isOpen: false,
      backgroundColorStyle: `${borderWidth} solid rgba(134,10,10,1)`
      // backgroundColorStyle: "linear-gradient(90deg, rgba(134,10,10,1) 0%, rgba(0,0,0,0.6446953781512605) 100%)"
    },
    {
      id: "LIMONADY_ID",
      icon: lemonadesIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Limonády<br/>Nealko",
      title: "Limonády a nealkoholické nápoje",
      list: limonady,
      isOpen: false,
      backgroundColorStyle: `${borderWidth} solid rgba(205,230,29,1)`
      // backgroundColorStyle: "linear-gradient(90deg, rgba(205,230,29,1) 0%, rgba(0,0,0,0.6446953781512605) 100%)"
    }
  ],
  [
    {
      id: "ALKO_NEALKO_ID",
      icon: stirredIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Miešané<br/>drinky",
      title: "Alko a nealko miešané drinky",
      list: alko_nealko_miesane,
      isOpen: false,
      backgroundColorStyle: `${borderWidth} solid rgba(151,230,29,1)`
      // backgroundColorStyle: "linear-gradient(90deg, rgba(151,230,29,1) 0%, rgba(0,0,0,0.6446953781512605) 100%)"
    },
    {
      id: "KAVA_ID",
      icon: coffeeIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Káva / Čaje",
      title: "Káva / Čaje",
      list: kava,
      isOpen: false,
      backgroundColorStyle: `${borderWidth} solid rgba(230,214,29,1)`
      // backgroundColorStyle: "linear-gradient(90deg, rgba(230,214,29,1) 0%, rgba(0,0,0,0.6446953781512605) 100%)"
    }
  ],
]

