import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
/* import {
  GiIceCubes
} from "react-icons/gi" */
export const kava = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "KAFFA 80% Arabica",
    descr: "všetky kávy ponúkame aj bez kofeínu",
    volume: "7 g",
    price: "",
    noDescr: false,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Ristretto",
    descr: "",
    volume: "7 g",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso",
    descr: "",
    volume: "7 g",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso lungo",
    descr: "",
    volume: "7 g",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso macchiato",
    descr: "",
    volume: "7 g",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Dvojité espresso",
    descr: "",
    volume: "14 g",
    price: "3,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cappuccino",
    descr: "",
    volume: "7 g",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cappuccino XXL",
    descr: "",
    volume: "7 g",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cappuccino \"special\"",
    descr: "oriešok, karamel, vanilka, čokoláda, škorica, kokos, banán, gaštan",
    volume: "7 g",
    price: "3,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Viedenská káva",
    descr: "",
    volume: "7 g",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Turecká káva",
    descr: "",
    volume: "7 g",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Café \"alžír\"",
    descr: "7 g káva, 2 cl vaječný likér, 20 g šľahačka",
    volume: "7 g",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Café latté macchiato",
    descr: "7g káva, 10 cl šľahané mlieko",
    volume: "",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Café latté macchiato s príchuťou",
    descr: "7g káva, 10 cl šľahané mlieko, 2 cl sirup</br>- oriešok, karamel, vanilka, čokoláda, škorica, kokos, banán, gaštan",
    volume: "",
    price: "3,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Café latté macchiato XXL",
    descr: "7g káva, 15 cl šľahané mlieko",
    volume: "",
    price: "3,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Café latté macchiato XXL s príchuťou",
    descr: "7g káva, 15 cl šľahané mlieko 2 cl sirup</br>- oriešok, karamel, vanilka, čokoláda, škorica, kokos, banán, gaštan",
    volume: "",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Super latté",
    descr: "cvikla 5 g, šľahané mlieko 20 cl",
    volume: "",
    price: "3,30 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]