import { CreateElementModalProps, OfferElement } from "../../PropTypes/AdminDashboardProps"
import { IoClose } from "react-icons/io5";
import { IoEyeOutline, IoEyeOffOutline  } from "react-icons/io5";
import { CgFormatSeparator } from "react-icons/cg";
import { FaStar  } from "react-icons/fa";
import { CiImageOn } from "react-icons/ci";
import { invokeAlertComponent } from "../../services/userInteractionService";
import { useEffect, useState } from "react";
import SelectorElement from "./SelectorElement";



const CreateElementModal = (props: CreateElementModalProps) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [image, setLocalImage] = useState("")
  const [sectionList, setSectionList] = useState<{ title: string | undefined; value: string | undefined; }[]>([]);
  const [element, setElement] = useState<OfferElement>({
    belongs_to: "string",
    divide_after: false,
    flagBehindTitle: false,
    item_description: "",
    item_expanded_description: "",
    item_expanded_image: "",
    item_flags: [], // Replace 'string' with the type of item flags
    item_is_visible: true,
    item_name: "",
    item_order: 0,
    item_price: "",
    item_type: "",
    item_volume: "",
    item_without_description: false,
    uuid: "",
    is_recommended: false,
    icon: "",
    icon_size_px: "50px",
    icon_color: "white"
  })

  const handleClose = () => {
    props.onClose()
  }

  const handleConfirm = () => {
    props.onConfirm(element)
  }
  /* const handleOrderChange = (uuid: any) => {
    const selectedElement = props.sectionElements?.find(element => element.uuid === uuid);
    if (selectedElement) {

      // Use selectedElement.order instead of props.sectionElements[0].order
      setElement({...element, item_order: selectedElement.item_order + 1})
    } else {
      if (uuid === "0") {
        setElement({...element, item_order: 0})
      } else {
        //@ts-ignore
        setElement({...element, order: props.latestOrderNumber})
      }
    }
  } */

  const handleUploadImage = () => {
    invokeAlertComponent("INFORMATION_ALERT_COMPONENT", "Ak chcete pridať obrázok, tak ma prosím kontaktujte - Tomáš 🌴")
    // Create a file input element
    /* const input = document.createElement("input");
    input.type = "file";

    input.addEventListener("change", (event) => {
      //@ts-ignore
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const imageUrl = URL.createObjectURL(selectedFile);
        setElement({ ...element, icon: selectedFile });
      }
    });

    // Click the input element to trigger file selection
    input.click(); */
  };

  useEffect(() => {
    // Map props.sectionElements into sectionList
    const mappedSectionList = props.sectionElements?.map(element => ({
      title: 'Uložiť za - ' + element.item_name,  // Replace with the actual property name in your data
      value: element.uuid,    // Replace with the actual property name in your data
    })) || [];


    // Set the mapped sectionList to the state
    if (mappedSectionList.length === 0) {
      setSectionList([{
        title: "Uložiť na začiatok zoznamu",
        value: "0"
      },...mappedSectionList]);
    } else {
      setSectionList([{
        title: "Uložiť na začiatok zoznamu",
        value: "0"
      }, {
        title: "Uložiť na koniec zoznamu",
        value: "99999"
      },...mappedSectionList]);
    }



  }, [props.sectionElements]);

  useEffect(() => {
    //@ts-ignore
    setElement({...element, order: props.latestOrderNumber})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.latestOrderNumber])
  useEffect(() => {
    if (props.selectedElement) {
      setElement(props.selectedElement)
    }
  }, [props.selectedElement])
  return(
    <div
      className="confirmation_modal create_element_modal card_element"
      /* onClick={() => props.onClose()} */
    >
      <div className="modal" onClick={(event) => {event.stopPropagation()}}>
        <IoClose
          className="close_icon"
          onClick={(event) => {
            event.stopPropagation()
            handleClose()
          }}
          size={"25px"}
          color="#2C2C2C"
        />
        <div className="content">
          <div className="title_row">
            Vytvorte novú položku
          </div>
          {/* <div className="field_element add_after_element_selector_wrap">
            <label>Umiestnenie položky</label>
            <SelectorElement
              list={sectionList}
              onSelect={(selected) => { handleOrderChange(selected) }}
            />
          </div> */}
          <div className="section_row">
          {/*   section selector */}
          </div>
          <div className="fields_wrap">
            <div className="fields_col_1">
              <div className="field_element">
                <label htmlFor="ELEMENT_ID_NAME">Názov</label>
                <div className="field card_element">
                  <input
                    id="ELEMENT_ID_NAME"
                    value={element.item_name}
                    type="text"
                    placeholder="Zadajte názov"
                    onChange={(e) => {
                      setElement({...element, item_name: e.target.value})
                    }}
                  />
                </div>
              </div>
                 <div className="field_element"
                  style={{
                    opacity: (element.item_type === "ITEM_REGULAR" || element.item_type === "description") ? 1 : 0.2,
                    cursor: (element.item_type === "ITEM_REGULAR" || element.item_type === "description") ? "pointer" : "default",
                    transition: "0.3s"
                  }}
                >
                  <label htmlFor="ELEMENT_ID_DESCR">Popis</label>
                  <div className="field description_card_element card_element">
                    <textarea
                      id="ELEMENT_ID_DESCR"
                      value={element.item_description}
                      placeholder="Zadajte popis"
                      disabled={(element.item_type !== "ITEM_REGULAR" && element.item_type !== "description")}
                      rows={6}
                      onChange={(e) => {setElement({...element, item_description: e.target.value})}}
                    />
                  </div>
                </div>
              <button
                className="button"
                onClick={(event) => {
                  event.stopPropagation()
                  handleConfirm()
                }}
              >
                { props.selectedElement !== null ? "Uložiť položku": "Potvrdiť" }
              </button>
            </div>
            <div className="fields_col_2">
              <div className="row_1">
                <div className="field_element"
                  style={{
                    opacity: (element.item_type === "ITEM_REGULAR") ? 1 : 0.2,
                    cursor: (element.item_type === "ITEM_REGULAR") ? "pointer" : "default",
                    transition: "0.3s"
                  }}
                >
                  <label htmlFor="ELEMENT_ID_PRICE">Cena</label>
                  <div className="price_field card_element">
                    <input
                      value={element.item_price}
                      type="text"
                      id="ELEMENT_ID_PRICE"
                      disabled={element.item_type !== "ITEM_REGULAR"}
                      placeholder="Zadajte cenu"
                      onChange={(e) => {setElement({...element, item_price: e.target.value})}}
                    />
                  </div>
                </div>
                <div className="field_element"
                  style={{
                    opacity: (element.item_type === "ITEM_REGULAR") ? 1 : 0.2,
                    cursor: (element.item_type === "ITEM_REGULAR") ? "pointer" : "default",
                    transition: "0.3s"
                  }}
                >
                  <label htmlFor="ELEMENT_ID_VOLUME">Objem</label>
                  <div className="volume_field card_element">
                    <input
                      value={element.item_volume}
                      type="text"
                      id="ELEMENT_ID_VOLUME"
                      disabled={element.item_type !== "ITEM_REGULAR"}
                      placeholder="Zadajte objem"
                      onChange={(e) => {setElement({...element, item_volume: e.target.value})}}
                    />
                  </div>
                </div>
              </div>
              <div className="row_2">
                  <div className="checkbox_wrap"
                  style={{
                    opacity: (element.item_type !== "extra" && element.item_type !== "description" && element.item_type !== "image") ? 1 : 0.2,
                    cursor: (element.item_type !== "extra" && element.item_type !== "description" && element.item_type !== "image") ? "pointer" : "default",
                    transition: "0.3s"
                  }}>
                    <div
                      className="card_element checkbox"
                      onClick={
                        (element.item_type !== "extra" && element.item_type !== "description" && element.item_type !== "image")
                          ? () => {
                            const newFlags = element!.item_flags!.includes(props.businessEstablishment!.flag_list.filter(element => element.name === "FLAG_1")[0].uuid) ? [] : [props.businessEstablishment?.flag_list.filter(element => element.name === "FLAG_1")[0].uuid]
                            //@ts-ignore
                            setElement({...element, item_flags: newFlags})
                          }
                          : () => {}
                      }
                      style={{
                        cursor: (element.item_type !== "extra" && element.item_type !== "description" && element.item_type !== "image") ? "pointer" : "default"
                      }}
                    >
                    {/* {
                      element.is_recommended
                      ?
                      <FaCheck
                        size={"15px"}
                        color="#2C2C2C"
                      />
                      :
                      <IoClose
                        size={"15px"}
                        color="#2C2C2C"
                      />
                    } */}
                    {
                    <FaStar
                      className="flag_icon"
                      size={"15px"}

                      color={
                        element!.item_flags!.includes(props.businessEstablishment!.flag_list.filter(element => element.name === "FLAG_1")[0].uuid) ? "#F7C808" : "#A4A4A4"
                      }
                    />
                    }
                    </div>
                    Odporúčané podnikom
                  </div>
                <div className="checkbox_wrap">

                  <div
                    className="card_element checkbox"
                    onClick={
                      () => { setElement({...element, item_is_visible: !element.item_is_visible}) }
                    }
                  >
                    {
                    !element.item_is_visible ?
                      <IoEyeOffOutline className="icon" size={"15px"} color="#2C2C2C" onClick={
                        () => { setElement({...element, item_is_visible: !element.item_is_visible}) }
                      }/>
                      :
                      <IoEyeOutline className="icon" size={"15px"} color="#2C2C2C" onClick={
                        () => { setElement({...element, item_is_visible: !element.item_is_visible}) }
                      }/>
                    }
                  </div>
                  Schované
                </div>
                  <div className="checkbox_wrap"
                    style={{
                      opacity: element.item_type === "ITEM_REGULAR" ? 1 : 0.2,
                      cursor: element.item_type === "ITEM_REGULAR" ? "pointer" : "default",
                      transition: "0.3s"
                    }}>
                    <div
                      className="card_element checkbox"
                      onClick={
                        element.item_type === "ITEM_REGULAR" ? () => { setElement({...element, divide_after: !element.divide_after}) } : () => {}
                      }

                      style={{
                        cursor: (element.item_type === "ITEM_REGULAR") ? "pointer" : "default"
                      }}
                    >
                      {/* {
                        element.divider_after
                        ?
                        <FaCheck
                          size={"15px"}
                          color="#2C2C2C"
                        />
                        :
                        <IoClose
                          size={"15px"}
                          color="#2C2C2C"
                        />
                      } */}
                      <CgFormatSeparator
                        className="flag_icon"
                        size={"15px"}
                        color={ element.divide_after ? "#ff4500" : "#A4A4A4"}
                      />
                    </div>
                    Posledná položka podsekcie
                  </div>
              </div>
            </div>
            <div className="fields_col_3">
            <div className="field_element">
                <label>Typ</label>
                <SelectorElement
                  list={
                    [
                      {
                        title: "Položka (Názov, popis, objem, cena)",
                        value: "ITEM_REGULAR"
                      },
                      {
                        title: "Položka formou obrázka (bez textu / možnosti rozkliknutia náhľadu)",
                        value: "ITEM_IMAGE"
                      },
                      {
                        title: "Oddeľovač sekcií",
                        value: "ITEM_SEPARATOR"
                      },
                      {
                        title: "Nadpis sekcie",
                        value: "HEADLINE_REGULAR"
                      },
                      {
                        title: "Nadpis s popisom",
                        value: "HEADLINE_DESCRIPTION"
                      },
                      {
                        title: "Nadpis s ikonou (bez ceny a objemu)",
                        value: "HEADLINE_ICON"
                      },
                      {
                        title: "Nadpis s popisom a ikonou (bez ceny a objemu)",
                        value: "HEADLINE_ICON_DESCRIPTION"
                      }
                    ]
                  }
                  onSelect={(new_type) => { setElement({...element, item_type: new_type}) }}
                />
              </div>

              <div className="field_element">
                  <div
                    className="field card_element"
                    style={{
                      opacity: element.item_type === "ITEM_REGULAR" || element.item_type === "image" ? 1 : 0.2,
                      cursor: element.item_type === "ITEM_REGULAR" || element.item_type === "image" ? "pointer" : "default",
                      transition: "0.3s"
                    }}
                    onClick={ element.item_type === "ITEM_REGULAR" || element.item_type === "image" ? handleUploadImage : () => {}}
                  >
                    {
                      element.icon ?
                      <img src={element.icon} alt="" />
                      :
                      <CiImageOn
                        className="icon"
                        size={"55px"}
                        color="#2C2C2C"
                      />
                    }
                    { element.icon ? "Zmeniť obrázok" : "Vybrať obrázok"}
                  </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default CreateElementModal