import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
import { FaInfo  } from "react-icons/fa";

export const studene_napoje = [
  {
    type: "extra",
    icon: null ,
    iconSize: "35px",
    item_uuid: uuid(),
    title: "Mliečne nápoje",
    descr: "",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Jahoda",
    descr: "(frapé, mlieko, ľad)",
    volume: "",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Banán",
    descr: "(frapé, mlieko, ľad)",
    volume: "",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Melón",
    descr: "(frapé, mlieko, ľad)",
    volume: "",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Lesné ovocie",
    descr: "(frapé, mlieko, ľad)",
    volume: "",
    price: "2,60 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null  ,
    iconSize: "35px",
    item_uuid: uuid(),
    title: "Ľadová Čokoláda",
    descr: "",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Čokoláda",
    descr: "(čokoláda, mlieko, ľad)",
    volume: "",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Biela čokoláda",
    descr: "(čokoláda, mlieko, ľad)",
    volume: "",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Banán",
    descr: "(čokoláda, mlieko, ľad)",
    volume: "",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kokos",
    descr: "(čokoláda, mlieko, ľad)",
    volume: "",
    price: "2,60 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaInfo ,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Studený nápoj si viete ešte lepšie vychutnať s pridaním šľahačky",
    descr: "",
    volume: "",
    price: "0,50 €",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]

