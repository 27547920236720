interface LocalProps {
  id: string,
  icon: any,
  title: string,
  description: string,
  index: number
}

const BenefitListElement = (props: LocalProps) => {

  const styleIcon = {
    order: props.index % 2 === 0 ? 1 : 2
  }
  const styleText = {
    order: props.index % 2 === 0 ? 2 : 1
  }

  return (
    <div className="element" id={`BENEFIT_ELEMENT_${props.index}`}>
      <div
        style={styleIcon}
      >
        <props.icon
          className="icon"
          size={"60px"}
        />
      </div>
      <div
        style={styleText}
      >
        <h2>
          {props.title}
        </h2>
        <p>
          { props.description }
        </p>
      </div>
    </div>
  )
}

export default BenefitListElement