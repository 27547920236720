
import Login from "../components/pages/Login"
import { useEffect } from "react"
const LoginPage = () => {


  useEffect(() => {
    document.title = `MojeQr - Prihlásenie`
    document.getElementsByTagName('meta')[3].content = `V prípade akýchkoľvek otázok nás neváhaj kontaktovať na +421 940 850 530 alebo na info@mojeqr.sk!`
    document.getElementsByTagName('link')[2].href = "https://www.mojeqr.sk/admin/prihlasenie"
  },[])

  return (
    <Login />
  )
}

export default LoginPage

