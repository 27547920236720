import uuid from "react-uuid"

import {
  BiDrink
} from "react-icons/bi"
/* import {
  TbSteeringWheel
} from "react-icons/tb" */

export const alko_nealko_miesane = [
  // ALKO
  /* {
    type: "title",
    icon: BiDrink,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Alko",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  }, */
  {
    item_uuid: uuid(),
    title: "Mojito Classico Cubano",
    descr: "0,05 l biely rum, limetka, mäta, trstinový cukor, sóda",
    volume: " 0,4 l",
    price: "5,20 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Mojito strawberry",
    descr: "0,05 l biely rum, jahodové pyré, limetka, mäta, trstinový cukor, sóda",
    volume: "0,4 l",
    price: "5,50 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Cosmopolitan",
    descr: "0,04 l vodka. 0,02 l Cointreau, citrónová šťava, brusnicový džús",
    volume: "0,2 l",
    price: "4,90 €",
    divideAfter: false,
    lastElement: false
  },
  /* {
    item_uuid: uuid(),
    title: "Margarita",
    descr: "0,06 tequilla, 0,04 l Cointreau, limetková šťava, brusnicový džús",
    volume: "0,2 l",
    price: "3,90 €",
    divideAfter: false,
    lastElement: false
  }, */
  {
    item_uuid: uuid(),
    title: "Piňa Colada",
    descr: "0,05 l biely rum, kokosové pyré, smotana, ananásový džús",
    volume: "0,3 l",
    price: "5,50 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Cuba Libre",
    descr: "0,06 l havana rum, limetka, Coca Cola",
    volume: "0,3 l",
    price: "4,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Aperol Spritz",
    descr: "0,09 l processo, 0,06 l aperol, 0,03 l soda",
    volume: "0,2 l",
    price: "4,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Hugo",
    descr: "0,15 l prosecco, 0,02 l bazový sirup, 0,01 l minerálka, limetka, mäta, 0,02 bazový elixír 14,7%",
    volume: "0,2 l",
    price: "4,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Gin & Tonic",
    descr: "0,04 l gin Beefeater, tonic, limetka",
    volume: "0,2 l",
    price: "4,90 €",
    divideAfter: true,
    lastElement: false
  },
  //NEALKO
  {
    type: "title",
    icon: BiDrink,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Nealko",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Mojito",
    descr: "limetka, mäta, trstinový cukor, sóda, ľad",
    volume: "0,4 l",
    price: "4,50 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Mojito Strawberry",
    descr: "jahodové pyré, limetka, mäta, trstinový cukor, sóda, ľad",
    volume: "0,4 l",
    price: "4,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Piňa Colada",
    descr: "kokosové pyré, smotana, ananásový džús",
    volume: "0,3 l",
    price: "4,90 €",
    divideAfter: false,
    lastElement: true
  },
]