import uuid from "react-uuid"

export const kava = [
  // KÁVA
  {
    item_uuid: uuid(),
    title: "Ristretto / Espresso",
    descr: "7g káva, HB cukor",
    volume: "",
    price: "1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso Lungo",
    descr: "7g káva, HB cukor",
    volume: "",
    price: "1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso Dopio",
    descr: "14g káva, HB cukor",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Viedenská",
    descr: "7g káva, HB cukor, šľahačka",
    volume: "",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cappuccino",
    descr: "7g káva, HB cukor, mlieko",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Latte Macchiato",
    descr: "7g káva, HB cukor, mlieko <br /> ochutená / neochutená",
    volume: "",
    price: "2,70 € / 2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ľadová Káva",
    descr: "7g káva, HB cukor, mlieko <br />(vanilka, čokoláda, kokos) <br /> neochutená / ochutená",
    volume: "",
    price: "3,30 € / 3,50 €",
    divideAfter: true
  },
  // ČAJE
  {
    type: "title",
    item_uuid: uuid(),
    title: "Čaje",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Zázvorový čaj",
    descr: "3g zázvoru, citrón, cukor",
    volume: "",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mätový čaj",
    descr: "3g mäty, citrón, cukor",
    volume: "",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zázvorovo-mätový čaj",
    descr: "2g zázvoru a 2g mäty, citrón, cukor",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Čaj podľa ponuky",
    descr: "1,5g čaju, citrón, cukor<br/>(ovocný, zelený, čierny, bylinkový)",
    volume: "",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Čokoláda",
    descr: "cukor<br/>(Mliečna, Biela)",
    volume: "",
    price: "2,50 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Med",
    descr: "",
    volume: "",
    price: "0,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mlieko",
    descr: "",
    volume: "",
    price: "0,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Šľahačka",
    descr: "",
    volume: "",
    price: "0,50 €",
    divideAfter: false,
    lastElement: true
  },
]