import uuid from "react-uuid"
import recommended_flag from "../../../assets/images/clients/icons/obyvacka_bar/recommended_flag.png"
import nealko_flag from "../../../assets/images/clients/icons/obyvacka_bar/nealko_flag.png"

export const kavaCaj = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Káva",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso",
    descr: "7 g káva",
    /* flag: recommended_flag,
    flagHeight: "22px", */
    volume: "",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Double Espresso",
    descr: "14 g káva",
    /* flag: nealko_flag,
    flagHeight: "18px", */
    volume: "",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso Machiato",
    descr: "7 g káva, mlieko",
    volume: "",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cappuchino",
    descr: "7 g káva, mlieko",
    volume: "",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Latte Machiato",
    descr: "7 g káva, mlieko",
    volume: "",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Frappe",
    descr: "karamel, malina, čokoláda 300 ml",
    volume: "",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zalievaná káva",
    descr: "7 g káva",
    volume: "",
    price: "1,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Čaj",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Čaj",
    descr: "podľa ponuky",
    volume: "1,5 g",
    price: "2,20 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Čokoláda",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Biela čokoláda",
    descr: "35 %",
    volume: "130 g",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tmavá čokoláda",
    descr: "70 %",
    volume: "130 g",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mliečna čokoláda",
    descr: "39 %",
    volume: "130 g",
    price: "3,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Extra",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Smotana Maressi",
    descr: "",
    volume: "20 ml",
    price: "0,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Rez citrónu",
    descr: "",
    volume: "",
    price: "0,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Med HB",
    descr: "",
    volume: "15 g",
    price: "0,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Toping",
    descr: "karamel, čokoláda, malina",
    volume: "20 g",
    price: "0,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Sirup do kávy",
    descr: "karamel, kokos, lieskový oriešok, vanilka, čokoláda",
    volume: "40 ml",
    price: "0,30 €",
    divideAfter: false
  },
]