import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

import recommended_flag from "../../../assets/images/clients/icons/panorama/recommended_flag.png"

export const gin_special = [
  /* {
    item_uuid: uuid(),
    title: "Rossa",
    descr: "/&nbsp0,5 l Malfi Gin Rosa, San Pellegrino Aranciata Rossa, grep&nbsp/",
    volume: "",
    price: "5,90 €",
    divideAfter: false
  }, */
 /*  {
    item_uuid: uuid(),
    title: "Chilligin",
    descr: "/&nbsp0,05 l Beefeater London Dry Gin, Schewppes ginger-chilli tonic, zázvor&nbsp/",
    volume: "",
    price: "5,90 €",
    divideAfter: false
  }, */
  {
    item_uuid: uuid(),
    title: "Mediterranean rosemary",
    descr: "/&nbsp0,05 l Gin Mare, Fever Tree Mediterranean tonic, rozmarín&nbsp/",
    volume: "",
    price: "6,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mediterranean citrus",
    descr: "/&nbsp0,05 l Gin Mare, Fentimans Valencian Orange tonic, citrus&nbsp/",
    volume: "",
    price: "6,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Classy Hendricks",
    descr: "/&nbsp0,05 l Hendricks Gin, Fentimans Connoisseurs tonic, uhorka&nbsp/",
    volume: "",
    price: "6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "P!nky glitter",
    descr: "/&nbsp0,05 l Beefeater Pink Strawberry Gin, Fentimans Rhubarb tonic, bobuľové ovocie&nbsp/",
    volume: "",
    price: "6,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pink grep",
    descr: "/&nbsp0,05 l Malfy Gin Rosa, Fentimans Pink Grapefruit tonic, citrus&nbsp/",
    volume: "",/*
    flagBehindTitle: false,
    flagHeight: "18px", */
    flag: recommended_flag,
    price: "6,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Limone",
    descr: "/&nbsp0,05 l Malfy Gin Con Limone, Fentimans Oriental Yuzu tonic, citrus&nbsp/",
    volume: "",
    price: "6,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Arancia",
    descr: "/&nbsp0,05 l Malfy Gin Con Arancia, Fentimans Valencian Orange tonic, citrus&nbsp/",
    volume: "",/*
    flagBehindTitle: true,
    flagHeight: "18px", */
    flag: recommended_flag,
    price: "6,10 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },

]