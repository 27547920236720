import uuid from "react-uuid"

export const limonady = [
  // LIMONÁDY
  {
    type: "title",
    item_uuid: uuid(),
    title: "Limonády",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Kiwi limonáda",
    descr: "kiwi pyré, citrónová šťava, voda, mäta, ovocie",
    volume: "0,4 l",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jahodová limonáda",
    descr: "jahodové pyré, citrónová šťava, jahody, mäta, voda",
    volume: "0,4 l",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Uhorková limonáda",
    descr: "uhorkový sirup, citrónová šťava, bazový sirup, uhorka, limetka, trstinový cukor, voda",
    volume: "0,4 l",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Grepová limonáda",
    descr: "grepový sirup, citrónová šťava, grep, mäta",
    volume: "0,4 l",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Citrusová limonáda",
    descr: "limetkové pyré, grepový sirup, citrónová šťava, mäta",
    volume: "0,4 l",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Džbán",
    descr: "voda, citrónová šťava, citrón, pomaranč, mäta, ľad",
    volume: "1 l",
    price: "2,50 €",

    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Doplnenie džbánu / voda",
    descr: "voda, citrónová šťava",
    volume: "0,4 l",
    price: "0,50 €",
    divideAfter: true
  },
  // NEALKOHOLICKÉ NÁPOJE
  {
    type: "title",
    item_uuid: uuid(),
    title: "Nealkoholické nápoje",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Coca Cola",
    descr: "podľa ponuky",
    volume: "0,2 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Natura - jemne perlivá",
    descr: "",
    volume: "0,25 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Römerquelle",
    descr: "podľa ponuky",
    volume: "0,33 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Sprite",
    descr: "",
    volume: "0,2 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fanta",
    descr: "",
    volume: "0,2 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kinley",
    descr: "podľa ponuky",
    volume: "0,25 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cappy",
    descr: "podľa ponuky",
    volume: "0,25 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fuzetea",
    descr: "podľa ponuky",
    volume: "0,25 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "RedBull",
    descr: "",
    volume: "0,25 l",
    price: "2,90 €",
    divideAfter: false,
    lastElement: true
  },
]