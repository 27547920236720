interface localProps {
  flag?: string,
  flagHeight?: string
  flagOpacity?: string
  descrLength?: number
  flagBehindTitle?:boolean
  hasImage?: any
}

const FlagElement = (props: localProps) => {

  const singleImageMarginLeft = () => {
    /* if (props.flagBehindTitle && props.hasImage) {
      return "22px"
    } */
    if (props.descrLength === 0 && !props.flagBehindTitle) {
      return "0px"
    } else {
      return "5px"
    }
  }




  return (
    <>
      {
        Array.isArray(props.flag)
        ?
        props.flag.map((localFlag, index) =>
          <img
            key={index}
            className="flagImage"
            src={localFlag} alt=""
            style={{
              maxHeight: props.flagHeight ? props.flagHeight[index] : "22px",
              marginLeft: props.descrLength === 0 ? "0px"
              : index === 0 ? "5px" : `${index * 75}px`,
              opacity: 1,
              zIndex: 2
            }}
          />
        )
        :
        <img
          className="flagImage"
          src={props.flag} alt=""
          style={{
            maxHeight: props.flagHeight ? props.flagHeight : "22px",
            marginLeft: singleImageMarginLeft(),
            marginTop: props.flagBehindTitle ? "-5px" : "0px"
          }}
        />


      }
    </>
  )
}

export default FlagElement