import { OfferSectionElement, PriceEditHeaderCardProps } from "../../PropTypes/AdminDashboardProps"
import { IoSearch } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";

const PriceEditHeaderCard = (props: PriceEditHeaderCardProps) => {

  const [open, setOpen] = useState(false)
  const [selectedSection, setSelectedSection] = useState<OfferSectionElement | null>(null)
  const [searchTerm, setSearchTerm] = useState("")
  const handleSelect = (section: OfferSectionElement) => {
    setOpen(false)
    console.log(section)
    setSelectedSection(section)
  }

  const handleCreateOfferBlock = () => {
    alert("open modal")
  }

  useEffect(()=> {
    if (props.sectionList && !selectedSection) {
      setSelectedSection(props.sectionList[0])
    }
    props.setSelectedSection(selectedSection)
  }, [props.sectionList, selectedSection])

  return (
      <div className="header_row" id="EDIT_PRICE_HEADER_ROW">
        <div className="title">
          Editácia ceny
        </div>
        <div className="input_wrap">
        {props.sectionList.length !== 0 ?

          <>
            <div className="search_bar card_element">
            <input
              value={searchTerm}
              type="text"
              placeholder="Vyhľadávať"
              onChange={(e) => {
                props.onSearch(e.target.value)
                setSearchTerm(e.target.value)
              }}
            />
            {
              searchTerm
              ? <MdClear className="icon clear_icon" size={"25px"} onClick={() => {props.clearSearch(); setSearchTerm("")}}/>
              : <IoSearch className="icon" size={"25px"} />
            }


          </div>
          <div
            className="section_selector card_element"
          >
            <div
              className="selector"
              onClick={() => {setOpen(prev => !prev)}}
            >
              <span key={selectedSection?.block_title_long}>
              {
                props.sectionList && selectedSection?.block_title_long
              }
              </span>
            </div>
            <IoIosArrowDown
              className="icon"
              size={"20px"}
              style={{
                transform: `${open ? "rotate(180deg)" : "rotate(0deg)"}`,
                transition: "0.3s"
              }}
              onClick={() => {setOpen(prev => !prev)}}
            />
            <div
              className={`selector_list ${open ? "selector_list_open" : ""}`}
            >
              {
                props.sectionList.map((section, index) =>
                  <div
                    key={index}
                    className={`selector_element ${section.block_title_long === selectedSection?.block_title_long ? "selector_element_active" : ""}`}
                    onClick={() => handleSelect(section)}
                  >
                    {section.block_title_long}
                  </div>
                )
              }
            </div>
          </div>
          </>
          :
          <div>
             <button onClick={handleCreateOfferBlock}>
              Vytvoriť sekciu
            </button>
          </div>
        }
        </div>
      </div>
  )
}

export default PriceEditHeaderCard