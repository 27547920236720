import {
  AiFillFacebook,
  AiFillPhone
} from "react-icons/ai"
import {
  RiInstagramFill
} from "react-icons/ri"
import {
  SiGmail
} from "react-icons/si"


interface localProps {
  config?: {
    iconColor?: string,
    facebook?: string,
    instagram?: string,
    email?: string,
    phone?: string,
    contactBackgroundColor?: string,
    mojeQrContactBgColor?: string
  }
}

const ClientContact = (props: localProps) => {



  return (
    <div
      className="clientContact"
      style={{
        backgroundColor: `${props.config?.contactBackgroundColor}`,
        borderRadius: "8px"
      }}
    >
      {
        props.config?.facebook &&
        <AiFillFacebook
          className="icon"
          size={"30px"}
          color={props.config?.iconColor}
          onClick={() => window.open(props.config?.facebook, "_blank")}
        />
      }
      {
        props.config?.instagram &&
        <RiInstagramFill
          className="icon"
          size={"30px"}
          color={props.config?.iconColor}
          onClick={() => window.open(props.config?.instagram, "_blank")}
        />
      }
      {
        props.config?.email &&
        <a href={`mailto:${props.config?.email}`}>
          <SiGmail
            className="icon"
            size={"30px"}
            color={props.config?.iconColor}
          />
        </a>
      }
      {
        props.config?.phone &&
        <a href={`tel:${props.config?.phone}`}>
          <AiFillPhone
            className="icon"
            size={"30px"}
            color={props.config?.iconColor}
          />
        </a>
      }
        <img
          style={{
            width: "30px",
            cursor: "pointer",
          }}
          src={require("../../../assets/images/logo.jpg")}
          alt="MojeQr"
          onClick={() => { window.open("https://mojeqr.sk", "_blank") }}
        />
      </div>
  )
}

export default ClientContact