import uuid from "react-uuid"

import { FaInfoCircle } from "react-icons/fa";
export const alkohol = [
  {
    item_uuid: uuid(),
    title: "Whisky Nestville Single Barrel",
    descr: "",
    volume: "0,04 l",
    price: "4,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Whisky Jameson",
    descr: "",
    volume: "0,04 l",
    price: "4,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Rum “Don Papa”",
    descr: "",
    volume: "0,04 l",
    price: "6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Gin TOISON",
    descr: "dry / ruby / red",
    volume: "0,04 l",
    price: "4,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Karpatské brandy špeciál",
    descr: "",
    volume: "0,04 l",
    price: "4,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Bentianna",
    descr: "",
    volume: "0,04 l",
    price: "4,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Baileys",
    descr: "",
    volume: "0,04 l",
    price: "2,70 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaInfoCircle,
    iconColor: "#201F1D",
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Pri objednávke so sebou",
    descr: `
    Účtujeme si poplatok za obaly v sume<br/>
    - 0,10 € - pohár<br/>
    - 0,60 € - malá krabička<br/>
    - 1,10 € - veĺká krabica<br/>`,
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false,
    lastElement: false
  },
]