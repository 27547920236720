import { IoMdArrowRoundBack } from "react-icons/io"

export const prednaHoraConfig = {
  isRestaurant: false,
  logo: "predna_hora.jpg",
  autoscrollAfterClick: true,
  autoscrollTimeInMs: 100,
  scrollToTopAferOpen: false,
  scrollToTopAfterClose: true,
  hideAllIcon: IoMdArrowRoundBack,
  hideAllWhenOneOpen: true,
  vibrateOnClick: false,
  closePreviousSectionOn: true,
  iconColor: "white",
  facebook: "https://www.facebook.com/profile.php?id=100041946254993",
  instagram: "https://www.instagram.com/pivaren_prednahora_humenne/",
  email: "pivarenprednahora@gmail.com",
  phone: "+421 911 299 111",
  displayAllOnPhone: true,

  menuUpdating: false
}