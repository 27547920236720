import uuid from "react-uuid"

export const letnaPonuka = [
  {
    type: "image",
    item_uuid: uuid(),
    offerImg: require("../../../assets/images/clients/offer/PrednaHora/01_Jedla.png"),
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    type: "image",
    item_uuid: uuid(),
    offerImg: require("../../../assets/images/clients/offer/PrednaHora/02_Alko.png"),
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    type: "image",
    item_uuid: uuid(),
    offerImg: require("../../../assets/images/clients/offer/PrednaHora/03_Limonada.png"),
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    type: "image",
    item_uuid: uuid(),
    offerImg: require("../../../assets/images/clients/offer/PrednaHora/04_Gin.png"),
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: true
  },
]