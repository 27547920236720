import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const miesane_napoje = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Nealkoholické",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Virgin mojito",
    descr: "10 cl sóda, 5 g cukor, 1 cl limetková šťava, mäta, ľad",
    volume: "",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Don Rico",
    descr: "8 cl ananásový džús, 2 cl mandarínka - pyré, 2 cl limetkový sirup, 6 cl tonic, ľad",
    volume: "",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Virgin colada",
    descr: "10 cl ananásový džús, 2 cl kokosový sirup, 2 cl smotana, ľad",
    volume: "",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Florida kokteil",
    descr: "2 cl Grenadína, 10 cl grepový džús, 1 cl citrónová šťava, 1 cl cukrová trstina, plátok citróna, ľad",
    volume: "",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Strawberry kiss",
    descr: "10 cl jahodový džús, 2 cl smotana, 1 cl cukrová trstina, 1 cl kokosový sirup, ľad",
    volume: "",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Virgin Strawberry colada",
    descr: "10 cl ananásový džús, 2 cl kokosový sirup, 2 cl smotana, jahody, ľad",
    volume: "",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Virgin Strawberry mojito",
    descr: "10 cl sóda, 5 g cukor, 1 cl limetková šťava, mäta, limetka, jahody, ľad",
    volume: "",
    price: "3,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Alkoholické",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Ginger caipirinha",
    descr: "4 cl Havana club, 15 cl Canada dry, 5 g hnedý cukor, limetka, ľad",
    volume: "",
    price: "4,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cuba libre",
    descr: "4 cl Havana club, 15 cl Pepsi cola, 1 cl limetková šťava, ľad, plátok citróna",
    volume: "",
    price: "4,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Piña colada",
    descr: "4 cl Havana club, 10 cl ananásový džús, 2 cl kokosový sirup, 2 cl smotana, ľad",
    volume: "",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mojito",
    descr: "4 cl Havana club, 15 cl sóda, 5 g cukor, 1 cl limetková šťava, mäta, ľad",
    volume: "",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Strawberry colada",
    descr: "4 cl Havana club, 10 cl ananásový džús, 2 cl kokosový sirup, 2 cl smotana, jahody, ľad",
    volume: "",
    price: "4,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Strawberry mojito",
    descr: "4 cl Havana club, 15 cl sóda, 5 g cukor, 1 cl limetková šťava, jahody, limetka, mäta, ľad",
    volume: "",
    price: "4,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Sex on the beach",
    descr: "4 cl vodka, 15 cl pomarančový džús, 2 cl broskyňový likér, 1 cl Grenadína, ľad",
    volume: "",
    price: "4,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Aperol spritz",
    descr: "4 cl aperol, 6 cl prosecco, 2 cl sóda, plátok pomaranča, ľad",
    volume: "",
    price: "4,80 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]