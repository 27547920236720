import uuid from "react-uuid"

export const pivo = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Čapované",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Staroprameň extra chmelená",
    descr: "12 ° čapované",
    volume: "400 ml",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Staroprameň COOL nealko grep",
    descr: "čapované",
    volume: "500 ml",
    price: "2,20 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Fľašové",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Corona",
    descr: "fľaša",
    volume: "355ml",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Stella artois",
    descr: "fľaša",
    volume: "330 ml",
    price: "2,70 €",
    divideAfter: false
  },
  /* {
    item_uuid: uuid(),
    title: "Hoegaardern",
    descr: "fľaša",
    volume: "330 ml",
    price: "2,70 €",
    divideAfter: false
  }, */
  {
    item_uuid: uuid(),
    title: "BlueMoon",
    descr: "fľaša",
    volume: "330 ml",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Staroprameň nealkoholické pivo",
    descr: "fľaša",
    volume: "330 ml",
    price: "2,20 €",
    divideAfter: false
  },
]