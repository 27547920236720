import uuid from "react-uuid"
import { FaInfoCircle } from "react-icons/fa";

export const caj = [
  {
    item_uuid: uuid(),
    title: "Zázvor & citrón",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kamilka & levanduľa",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Lipa & mäta & medovka",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Malina & záhradné ovocie",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zelený čaj s ananásom a mangom",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zelený čaj",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Čierny čaj",
    descr: "sypaný",
    volume: "400 ml",
    price: "3,60 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Čaj z čerstvej mäty",
    descr: "",
    volume: "400 ml",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Chai Latte",
    descr: "",
    volume: "300 ml",
    price: "3,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Matcha čaj",
    descr: "",
    volume: "300 ml",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Matcha Latte",
    descr: "",
    volume: "300 ml",
    price: "3,80 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Horúca čokoláda - mliečna",
    descr: "",
    volume: "150 ml",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Horúca čokoláda - horká",
    descr: "",
    volume: "150 ml",
    price: "3,60 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Med",
    descr: "",
    volume: "20 g",
    price: "0,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Lipový sirup",
    descr: "",
    volume: "20 ml",
    price: "0,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaInfoCircle,
    iconColor: "#201F1D",
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Pri objednávke so sebou",
    descr: `
    Účtujeme si poplatok za obaly v sume<br/>
    - 0,10 € - pohár<br/>
    - 0,60 € - malá krabička<br/>
    - 1,10 € - veĺká krabica<br/>`,
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false,
    lastElement: false
  },
]