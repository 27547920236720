import uuid from "react-uuid"
import { FaInfoCircle } from "react-icons/fa";
export const limonady = [
  {
    item_uuid: uuid(),
    type: "extra",
    title: "Domáca limonáda",
    descr: "100% prírodné zloženie, remeselná výroba, lisované za studena",
    volume: "",
    price: "0,3 l / 1 l",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Lipový kvet",
    descr: "",
    volume: "",
    price: "3,30 € / 6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Levanduľa",
    descr: "",
    volume: "",
    price: "3,30 € / 6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Baza",
    descr: "",
    volume: "",
    price: "3,30 € / 6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Malina",
    descr: "",
    volume: "",
    price: "3,30 € / 6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Brusnica",
    descr: "",
    volume: "",
    price: "3,30 € / 6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jahoda",
    descr: "",
    volume: "",
    price: "3,30 € / 6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Brusnica",
    descr: "",
    volume: "",
    price: "3,30 € / 6,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zázvor",
    descr: "",
    volume: "",
    price: "3,30 € / 6,30 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Citronáda bez cukru",
    descr: "",
    volume: "0,3 l / 1 l",
    price: "2,30 € / 4,20 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaInfoCircle,
    iconColor: "#201F1D",
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Pri objednávke so sebou",
    descr: `
    Účtujeme si poplatok za obaly v sume<br/>
    - 0,10 € - pohár<br/>
    - 0,60 € - malá krabička<br/>
    - 1,10 € - veĺká krabica<br/>`,
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false,
    lastElement: false
  },
]