import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import HtmlParser from "../../services/htmlParser";
import {
  phone,
  email,
  instagramLink,
  facebookLink
} from "../../config/contact"
import {
  BsInstagram
} from "react-icons/bs"
import {
  ImFacebook2
} from "react-icons/im"

interface LocalProps {
  id: string
}

const ContactCard = (props: LocalProps) => {
  const { t } = useTranslation()


  return (
    <div
      id={ props.id }
      className="contactCard"
    >
      <h1>
        { t("contactCard.contact") }
      </h1>

      <div className="contactCard__contact_wrap">
        <h2>
          <HtmlParser>
            { t("contactCard.callMe") }
          </HtmlParser>
          <br className="only_on_mobile"/>
          <a href={`tel:${phone}`} className="call_me_identifier">
            { phone }
          </a>
        </h2>

        {/* <h2>
          { t("contactCard.or") }
        </h2> */}

        <h2>
          <HtmlParser>
            { t("contactCard.textMe") }
          </HtmlParser>
          <br className="only_on_mobile"/>
          <a href={`mailto:${email}`} className="email_me_identifier">
            { email }
          </a>
        </h2>
        <h2>
          { t("contactCard.socials") }
        </h2>
        <div>
          <BsInstagram
            className="icon icon_instagram"
            size={"40px"}
            onClick={() => {window.open(instagramLink, '_blank')}}
          />
          <ImFacebook2
            className="icon icon_facebook"
            size={"40px"}
            onClick={() => {window.open(facebookLink, '_blank')}}
          />
        </div>
        <Link to="/kontakt" className="contactCard__more_button">{ t("contactCard.more") }</Link>
      </div>
    </div>
  )
}

export default ContactCard