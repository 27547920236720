import AdminDashboardCard from "../cards/AdminDashboardCard"

const Dashboard = () => {
  return(
    <div className="admin_page">
      <AdminDashboardCard />
    </div>
  )
}

export default Dashboard