import uuid from "react-uuid"
/* import {
  GoAlert
} from "react-icons/go" */
/* import {
  CiPizza
} from "react-icons/ci" */
import {
  BsPlusCircleFill
} from "react-icons/bs"


export const hamburger = [
  {
    item_uuid: uuid(),
    title: "Predná Hora Burger",
    descr: "hovädzie mäso 150 g, slaninový džem, syr cheddar, šalát, cibuľa, kyslá uhorka, dresing z karamelizovanej cibule (alergény: 1, 7, 3, 10, 11)",
    volume: "",
    price: "9,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Jalapeno Burger",
    descr: "hovädzie mäso 150 g, slanina, syr cheddar, jalapeno, šalát, kyslá uhorka, pečená cibuľa, burger dresing (alergény: 1, 3, 7, 10, 11)",
    volume: "",
    price: "9,00 €",
    spicyLevel: 1,
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Bacon Burger",
    descr: "hovädzie mäso 150 g, slanina, syr cheddar, pražená cibuľka, šalát, paradajka, slaninový dresing (alergény: 1, 3, 7, 10, 11)",
    volume: "",
    price: "9,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Chicken Burger",
    descr: "vyprážané kuracie prsia 130 g, šalát, paradajka, syr cheddar, medovo-horčicový dresing (alergény: 1, 3, 7, 10, 11)",
    volume: "",
    price: "9,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Halloumi Burger",
    descr: "Halloumi - 100 g, šalát, paradajka, bazalkový dresing(alergény: 1, 3, 6, 7, 9, 10, 11)",
    volume: "",
    price: "9,00 €",
    divideAfter: true,
    lastElement: false,
    typeAfter: "description"
  },
  {
    item_uuid: uuid(),
    title: "Tortilla s kuracím mäsom",
    descr: "vyprážané kuracie prsia 100 g, syr, paradajka, paprika, dressing z karamelizovanej cibule, šalát (alergény: 1, 3, 7, 10)",
    volume: "",
    price: "7,30 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Tortilla s halloumi syrom",
    descr: "100 g halloumi syr, šalát, paradajka, paprika, bazalkový dressing (alergény: 1, 3, 7, 8, 10)",
    volume: "",
    price: "7,50 €",
    divideAfter: true,
    lastElement: false,
  },
  {
    type: "extra",
    icon: BsPlusCircleFill,
    iconColor: "#F7C808",
    item_uuid: uuid(),
    title: "Combo Classic",
    descr: "hranolky, cibuľové krúžky, dresing: <br> (cesnak, salsa, BBQ, kečup, tatárska omáčka)",
    volume: "",
    price: "3,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    type: "extra",
    icon: BsPlusCircleFill,
    iconColor: "#F7C808",
    item_uuid: uuid(),
    title: "Combo Sweet",
    descr: "Batátové hranolky, cibuľové krúžky, dresing: <br> (cesnak, salsa, BBQ, kečup, tatárska omáčka)",
    volume: "",
    price: "4,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    type: "extra",
    icon: BsPlusCircleFill,
    iconColor: "#F7C808",
    item_uuid: uuid(),
    title: "Upgraduj si svoj burger na Double",
    descr: "150 g hovädzie mäso, 2x cheddar",
    volume: "",
    price: "3,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    type: "extra",
    icon: BsPlusCircleFill,
    iconColor: "#F7C808",
    item_uuid: uuid(),
    title: "Chilli naviac",
    descr: "Hamburger, tortilla",
    volume: "",
    price: "0,50 €",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Obal na jedlo",
    descr: "",
    volume: "1 ks",
    price: "0,60 €",
    divideAfter: false,
    lastElement: true
  },
]