/* import HalloweenAnimation from "./HalloweenAnimation" */
/* import WinterAnimation from "./WinterAnimation" */

const SeasonalAnimationWrap = () => {
  return (
    <>
      {/* <WinterAnimation /> */}
      {/* <HalloweenAnimation /> */}
    </>
  )
}

export default SeasonalAnimationWrap