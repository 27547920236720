import { IoMdAdd } from "react-icons/io";
import { CreateElementButtonProps } from "../../PropTypes/AdminDashboardProps";

const CreateElementButton = (props:CreateElementButtonProps) => {
  return (
    <div
      className={`create_element_button_wrap ${!props.isEditView && "create_element_button_hidden"}`}
      id="CREATE_ELEMENT_BUTTON_ID"
    >
      <span>
        Vytvoriť položku
      </span>
      <div className="create_element_button">
        <IoMdAdd
          onClick={() => {props.onClick()}}
          className="icon"
          size={"35px"}
        />
      </div>
    </div>
  )
}

export default CreateElementButton
