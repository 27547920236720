import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const nealko = [
  {
    item_uuid: uuid(),
    title: "Pepsi",
    descr: "",
    volume: "0,25 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pepsi Light",
    descr: "",
    volume: "0,25 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "7 up",
    descr: "",
    volume: "0,25 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mirinda",
    descr: "",
    volume: "0,25 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tonic",
    descr: "",
    volume: "0,25 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tonic Zázvor",
    descr: "",
    volume: "0,25 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tonic Pink",
    descr: "",
    volume: "0,25 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Džús Toma",
    descr: "podľa aktuálnej ponuky",
    volume: "0,25 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mattoni",
    descr: "perlivá / jemne perlivá / neperlivá",
    volume: "0,33 l",
    price: "1,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mattoni Cedrata",
    descr: "príchuť citrusov",
    volume: "0,33 l",
    price: "2,30 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Romerquelle",
    descr: "lemongrass",
    volume: "0,25 l",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Vinea",
    descr: "biela, červená",
    volume: "0,25 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Red Bull",
    descr: "",
    volume: "0,25 l",
    price: "2,70 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]