
import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.scss';
import App from './App';

import { I18nextProvider } from 'react-i18next';
import i18n from './services/i18n';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <I18nextProvider i18n={i18n}>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </I18nextProvider>

);
