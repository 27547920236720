import { ConfirmationModalProps } from "../../PropTypes/AdminDashboardProps"
import { IoClose } from "react-icons/io5";
import HtmlParser from "../../services/htmlParser";
import { useEffect } from "react";

const ConfirmationModal = (props: ConfirmationModalProps) => {

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      props.onConfirm();
    }
    if (event.key === "Escape") {
      event.stopPropagation();
      props.onClose();
    }
  };
  useEffect(() => {
    // Add event listener to the whole document
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      // Remove the event listener when the component is unmounted
      document.removeEventListener("keydown", handleKeyPress);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return(
    <div
      className="confirmation_modal card_element"
      onClick={() => props.onClose()}
    >
      <div className="modal" onClick={(event) => {event.stopPropagation()}}>
        <IoClose
          className="close_icon"
          onClick={(event) => {
            event.stopPropagation()
            props.onClose()
          }}
          size={"25px"}
          color="#2C2C2C"
        />
        <div className="text">
          <HtmlParser>
            { props.modalText }
          </HtmlParser>
        </div>
        <button
          className="button"
          onClick={(event) => {
            event.stopPropagation()
            props.onConfirm()
          }}
        >
          Potvrdiť
        </button>
      </div>
    </div>
  )
}

export default ConfirmationModal