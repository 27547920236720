import PrednaHoraItemElement from "./PrednaHoraItemElement"
import { prednaHoraConfig } from "../../../config/clients/prednaHora"
import { useState } from "react"
import HtmlParser from "../../../services/htmlParser"

interface localProps {
  id: string
  icon: string,
  iconWidth: string,
  page_uuid: string,
  title: string,
  list: Array<any>,
  isOpen: boolean,
  rowIndex: number,
  blockBackground: boolean,
  blockIndex: number,
  closePage: (current_page_uuid: string, rowIndex: number, blockIndex: number) => void,
  style: object,
  localConfig: any

}

const PrednaHoraPageElement = (props: localProps) => {

  const [blocks] = useState(props.list.filter(element => element.block))
  const handlePageOpen = (page_uuid: string) => {
    if (prednaHoraConfig.vibrateOnClick && 'vibrate' in navigator) {
      navigator.vibrate([0, 0, 100])
    }
    props.closePage(page_uuid, props.rowIndex, props.blockIndex)
  }

  const removeBreak = (title: string) => {
    return title.replace(/<.*>/, ' / ')
  }

  const handleBlockClick = (id: string) => {
    setTimeout(() => {
      const element = document.getElementById(id)!
      var headerOffset = 10
      var elementPosition = element.getBoundingClientRect().top
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      })

    }, 200)
  }

  return (
    <div
      className={`prednaHora_page_element ${props.isOpen ? "prednaHora_page_element_open" : "prednaHora_page_element_close"} ${props.blockBackground && "prednaHora_page_element_custom_bg"}`}
      id={props.id}
      style={props.style}
    >
      <div
        className="title_wrap"
        onClick={() => handlePageOpen(props.page_uuid)}
      >
        {
          <div className="img_wrap">
            <img
              src={props.icon}
              alt=""
              style={{
                width: props.iconWidth,
                marginBottom: (props.title.includes("<br") && !props.isOpen) ? "-5px" : "0px"
              }}
            />
          </div>
        }
        <h1
          style={{
            marginBottom: (props.title.includes("<br") && !props.isOpen) ? "5px" : "15px"
          }}
        >
          <HtmlParser>
            {
              (props.title.includes("<br") && props.isOpen)
                ? removeBreak(props.title)
                : props.title
            }
          </HtmlParser>
        </h1>
      </div>
      <div
        className="items_wrap"
        style={{
          maxHeight: (props.list && props.isOpen) ? "500000px" : "0px",
          maxWidth: (props.list && props.isOpen) ? "500000px" : "0px"
        }}
      >

        <div className="blocks_wrap" style={{marginBottom: "15px"}}>
          {
            blocks && blocks.map((element, index) =>
              <div
                className="block_item"
                key={index}
                onClick={() => {
                  handleBlockClick(element.blockId)
                }}
              >
                <element.icon
                  className="icon"
                  size={element.iconSize || "30px"}
                />
                { element.blockTitle }
              </div>
            )

          }
        </div>
        {
          props.list && props.list.map((element, index) =>
            element.type === "image"
              ? <img className="imgOffer" src={element.offerImg} alt="" />
              : <PrednaHoraItemElement
                  key={index}
                  element={element}
                  localConfig={props.localConfig}
                />
          )
        }
      </div>
    </div>
  )
}

export default PrednaHoraPageElement