import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
import new_flag from "../../../assets/images/clients/icons/mercy_coffee/new_flag.png"

export const miesane_napoje = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Alkoholické",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "MAI - Mercy - TAI",
    descr: "(biely a tmavý rum, pom. likér, mandľový sirup, lime šťava, ananás. džús, ľad)",
    volume: "0,25 l",
    price: "5,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "GIN & TONIC Grapefruit MERCY",
    descr: "(Beefeater Gin, tonik, home made grapefruit sirup, grep, ľad)",
    volume: "0,25 l",
    price: "5,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mercy Mojito",
    descr: "(Becherovka, limetka, trstinový cukor, mäta, sóda, ľad)",
    volume: "0,30 l",
    price: "5,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Bubble Candy",
    descr: "(vodka, bubble gum sirup, limet. šťava, višňový džús, cukrová vata)",
    volume: "0,20 l",
    price: "5,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mercy Peach",
    descr: "(Peach vodka, grenadina monin, brusnicový džús, limet. šťava)",
    volume: "0,20 l",
    price: "4,20 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Gin & Tonic",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "G & T",
    descr: "(Beefeater gin, tonik, citrón, ľad)",
    volume: "0,25 l",
    price: "4,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "G & T Pink",
    descr: "(Beefeater gin pink, tonic, jahodový monin, limet. šťava, tonic rose)",
    volume: "0,25 l",
    price: "4,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "G & T Indian Summer",
    descr: "(Beefeater gin, tonik, home made marakuja a škorica sirup, pomaranč, škorica, ľad)",
    volume: "0,25 l",
    price: "5,20 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Spritze",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Aperol Spritz",
    descr: "(Aperol, prosecco, sóda, pomaranč, ľad)",
    volume: "0,25 l",
    price: "5,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hugo Spritz",
    descr: "(Prosecco, mäta, limetka, monin báza, sóda, ľad) - (sezónne)",
    volume: "0,25 l",
    price: "5,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Prosecco Raspberry Spritz",
    descr: "(Prosecco, malinový sirup, tonik, ľad)",
    volume: "0,25 l",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mimosa coctail",
    descr: "(Prosecco, pomarančový džús)",
    volume: "0,20 l",
    price: "2,80 €",
    flagBehindTitle: true,
    flagHeight: "25px",
    flag: new_flag,
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Ostatné",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Cuba Libre",
    descr: "(Bacardi Superior, limet. šťava, cola, limetka, ľad)",
    volume: "0,30 l",
    price: "4,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mojito",
    descr: "(Bacardi Superior, limet. šťava, limetka, trstinový cukor, mäta, sóda, ľad) - (sezónne)",
    volume: "0,25 l",
    price: "5,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Piňa Colada",
    descr: "(Bacardi Superior, coco pyré, ananásový džús, ľad)",
    volume: "0,30 l",
    price: "5,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Long Island Iced Tea",
    descr: "(Tequila silver, Absolut vodka, Bacardi, Beefeater gin, cointreau, limet. šťava, agoré simp, cola)",
    volume: "0,30 l",
    price: "5,80 €",
    flagBehindTitle: true,
    flagHeight: "22px",
    flag: new_flag,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Margharita",
    descr: "(Tequila, pomaranč. likér, limet. šťava, agáve sirup, ľad)",
    volume: "0,20 l",
    price: "5,30 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Nealkoholické",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Virgin Colada",
    descr: "(coco pyré, ananásový džús, smotana, ľad)",
    volume: "0,30 l",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Virgin Mojito",
    descr: "(limetka, trstinový cukor, mäta, sóda, ľad) - (sezónne)",
    volume: "0,30 l",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jahodové Mojito",
    descr: "(limetka, trstinový cukor, mäta, jahody, sóda, ľad) - (sezónne)",
    volume: "0,30 l",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Aperol Spritz",
    descr: "(aperol sirup, sóda, pomaranč, ľad)",
    volume: "0,25 l",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hugo Spritz",
    descr: "(sóda, mäta, limetka, monin báza, ľad) - (sezónne)",
    volume: "0,25 l",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Rasp & Berry",
    descr: "(malinový sirup, bazový sirup, limet. šťava, grapefruitový džús, ľad)",
    volume: "0,30 l",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Monin Ice Tea",
    descr: "(monin lime, monin lemon tea, citrón, mäta, voda, ľad)",
    volume: "0,30 l",
    price: "3,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
]