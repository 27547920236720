import uuid from "react-uuid"
import { FaInfoCircle } from "react-icons/fa";
export const vino = [
  {
    item_uuid: uuid(),
    type: "extra",
    title: "Biele víno",
    descr: "Vinárstva z malokarpatského kraja",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Veltínske zelené",
    descr: "JAGNET, 2022 - suché",
    volume: "0,1 l",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Muškát moravský",
    descr: "JAGNET, 2022 - suché",
    volume: "0,1 l",
    price: "2,60 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    type: "extra",
    title: "Ružové víno",
    descr: "Vinárstva z malokarpatského kraja",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cabernet Sauvignon",
    descr: "Chowaniec a Krajčírovič, 2022 polosuché",
    volume: "0,1 l",
    price: "2,60 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    type: "extra",
    title: "Červené víno",
    descr: "Vinárstva z malokarpatského kraja",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Frankovka modrá",
    descr: "JAGNET, 2019 - suché",
    volume: "0,1 l",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Alibernet",
    descr: "Suchý vrch, 2018 výber z hrozna - suché",
    volume: "0,1 l",
    price: "3,40 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Prosecco",
    descr: "",
    volume: "0,1 l / 1 fľ.",
    price: "3,20 € / 16,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaInfoCircle,
    iconColor: "#201F1D",
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Pri objednávke so sebou",
    descr: `
    Účtujeme si poplatok za obaly v sume<br/>
    - 0,10 € - pohár<br/>
    - 0,60 € - malá krabička<br/>
    - 1,10 € - veĺká krabica<br/>`,
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false,
    lastElement: false
  }
]