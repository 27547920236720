import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
import { FaInfo  } from "react-icons/fa";
import { FaRegLemon } from "react-icons/fa";
import { GiOrange } from "react-icons/gi";


export const domace_limonady = [
  {
    type: "extra",
    icon: FaRegLemon,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Citronáda",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Citronáda",
    descr: "",
    volume: "0,3 l / 0,5 l",
    price: "0,80 € / 1,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Citronáda + mäta",
    descr: "(sezónne)",
    volume: "0,3 l / 0,5 l",
    price: "1,00 € / 1,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Citronáda + mäta + limetka",
    descr: "(sezónne)",
    volume: "0,3 l / 0,5 l",
    price: "1,50 € / 1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Citronáda + mäta + uhorka",
    descr: "(sezónne)",
    volume: "0,3 l / 0,5 l",
    price: "1,50 € / 1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Citronáda + lesné ovocie",
    descr: "(sezónne)",
    volume: "0,3 l / 0,5 l",
    price: "1,70 € / 2,00 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: GiOrange,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Domáca Limonáda",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Pomarančová Limonáda",
    descr: "(pomaranč, trstinový cukor, sirup, sóda, ľad)",
    volume: "0,5 l",
    price: "4,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Grapefruitová Limonáda",
    descr: "(grep, trstinový cukor, bázový sirup, sóda, ľad)",
    volume: "0,5 l",
    price: "4,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jahodová Limonáda",
    descr: "(jahody, trstinový cukor, jahodový sirup, sóda, ľad)",
    volume: "0,5 l",
    price: "4,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Melónová Limonáda",
    descr: "(melón, mäta, melónový sirup, tonik, ľad) - (sezónne)",
    volume: "0,5 l",
    price: "4,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Uhorková Limonáda",
    descr: "(uhorka, trstinový cukor, mäta, limetková šťava, voda, ľad) - (sezónne)",
    volume: "0,5 l",
    price: "4,80 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaInfo ,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Tieto limonády sú pripravované výhradne z čerstvého ovocia",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]