import LoginCard from "../adminComponents/LoginCard";

const Login = () => {


  return (
    <div className="login_page">
      <LoginCard />
    </div>
  )
}

export default Login;

