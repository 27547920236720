import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const cerstve_stavy = [
  {
    item_uuid: uuid(),
    title: "Pomarančová šťava",
    descr: "",
    volume: "0,1 l",
    price: "1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Grapefruitová šťava",
    descr: "",
    volume: "0,1 l",
    price: "1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pomarančovo-grapefruitová šťava",
    descr: "",
    volume: "0,1 l",
    price: "1,70 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]

