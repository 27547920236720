import PageElement from "./PageElement"
/* import { panoramaConfig } from "./../../config/clients/panorama"
import { panoramaMenu } from "./../../content/Panorama/menu" */
import { useEffect, useState } from "react"
import ClientContact from "./global/ClientContact"
import {
  BiHide
} from "react-icons/bi"
import HtmlParser from "../../services/htmlParser"
import SeasonalAnimationWrap from "../seasonalAnimations/SeasonalAnimationWrap"
import LoadingScreen from "../cards/LoadingScreen"

interface localProps {
  localConfig: object,
  localMenu: object,
  localClientLogo: string,
}

//@ts-nocheck

const NewClient = (props: localProps) => {


  const localConfig = props.localConfig
  const localMenu = props.localMenu
  //@ts-ignore
  const CloseAllIcon = localConfig.hideAllIcon || BiHide

  const [menu, updateMenu] = useState(localMenu)
  const [showLoadingScreen, setShowLoadingScreen] = useState(true)
  const [hideAllButtonVisible, setHideAllButtonVisible] = useState(false)
  const [previousPageUUID, setPreviousPageUUID] = useState("")
  //@ts-ignore
  const [rows, setRows] = useState(menu.map((row, index) => {
    return {
      isOpen: false,
      rowIndex: index
    }
  }))


  const handleOpening = (isOpen: boolean, rowIndex: number) => {
    const updatedRows = rows
  //@ts-ignore
    rows.forEach((row, index) => {
      if (index === rowIndex) {
        row.isOpen = !isOpen
      }
    })
    setRows(updatedRows)
    return !isOpen
  }

  const updateIsOpen = (array: any[], page_uuid: string, rowIndex: number) => {
    array.forEach(item => {
      if (Array.isArray(item)) {
        updateIsOpen(item, page_uuid, rowIndex);
      } else if (item.page_uuid === page_uuid) {
        item.isOpen = handleOpening(item.isOpen, rowIndex);
      //@ts-ignore
      } else if (item.page_uuid !== page_uuid && localConfig.closePreviousSectionOn) {
        item.isOpen = false
      }
    });

    return array;
  };

  const closeAll = (array: any[]) => {
    array.forEach(item => {
      if (Array.isArray(item)) {
        closeAll(item);
      } else {
        item.isOpen = false
      }
    });

    return array;
  };

  const isAnyOpen = () => {
  //@ts-ignore
    return rows.find(row => row.isOpen)
  }

  const handleClosePage = (current_page_uuid: string, rowIndex: number, blockIndex: number) => {
    //@ts-ignore
    const updatedArray = updateIsOpen([...menu], current_page_uuid, rowIndex)
    updateMenu(updatedArray)
    if (previousPageUUID === current_page_uuid && !rows[rowIndex].isOpen) {
      setHideAllButtonVisible(false)
      setTimeout(() => {
        window.scrollTo({
          behavior: 'smooth',
          top: 0
        })
      }, 300);
    } else {
      setHideAllButtonVisible(true)
      //@ts-ignore
      if (localConfig.scrollToTopAferOpen) {
        setTimeout(() => {
          window.scrollTo({
            behavior: 'smooth',
            top: 0
          })
        }, 100);
      }
      //@ts-ignore
      if (localConfig.autoscrollAfterClick) {

        setTimeout(() => {
          //@ts-ignore
          const element = document.getElementById(menu[rowIndex].find(page => page.page_uuid === current_page_uuid)!.id)!
          element.scrollIntoView({
            behavior: 'smooth'
          })
          setTimeout(() => {
            //@ts-ignore
            if (localConfig.scrollToTopAfterClose) {
              if (!isAnyOpen()) {
                window.scrollTo({
                  behavior: 'smooth',
                  top: 0
                })
              }
            }
          }, 300);
          //@ts-ignore
        }, localConfig.autoscrollTimeInMs);


      }
    }
    setPreviousPageUUID(current_page_uuid)

  }
  const isAtLeastOneBlockOpen = (row: any) => {
    //@ts-ignore
    return row.some(el => el.isOpen)
  }

  const handleCloseAll = () => {
    //@ts-ignore
    const updatedArray = closeAll([...menu])
    updateMenu(updatedArray)
    setHideAllButtonVisible(false)
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: 0
      })
    }, 300)
  }


  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  useEffect(() => {
    document.body.style.background = "white"
    document.body.style.overflow = "hidden"
    setTimeout(() => {
      document.body.style.overflow = "visible"
    }, 1200)
    setTimeout(() => {
      setShowLoadingScreen(false)
    }, 1500)
  },[])

  return (
    <div
      //@ts-ignore
      className={`global_client_class ${localConfig.mainWrapClass}`}
      //@ts-ignore
      id={`${localConfig.mainWrapClass}_ID`}
    >
      {
        showLoadingScreen &&
        <LoadingScreen />
      }
      <SeasonalAnimationWrap />

      <div
        className="backgroundImageTop"
      />
      <div className="logo_wrap">
        <img
          className="logo"
          src={props.localClientLogo}
          alt="MojeQr"
        />
      </div>
      <div
        //@ts-ignore
        className={`main_content_wrap ${(!hideAllButtonVisible && localConfig.displayAllOnPhone) && "marginTopLocalAdjustment"}`}
        id="DRAG_SCROLL"
      >
        {
          //@ts-ignore
          menu.map((row: any, rowIndex: number) =>
            <div
              className="row"
              key={rowIndex}
              style={{
                flexDirection: isAtLeastOneBlockOpen(row) ? "column" : "row"
              }}
            >
              {/* {
              row.map((page: any, blockIndex: number) => <div>
                { JSON.stringify(page) }
              </div>)

              } */}
              {
                row.map((page: any, blockIndex: number) =>
                  <PageElement
                    id={page.id}
                    icon={page.icon}
                    iconWidth={page.iconWidth}
                    iconMarginRight={page.isOpen ? "0px" : page.iconMarginRight || "0px"}
                    key={blockIndex}
                    page_uuid={page.page_uuid}
                    title={isMobileDevice() ? page.titleShort : page.title}
                    rowIndex={rowIndex}
                    blockIndex={blockIndex}
                    list={page.list}
                    isOpen={page.isOpen}
                    //@ts-ignore
                    closePage={handleClosePage}
                    style={{
                      //@ts-ignore
                      display: (!page.isOpen && hideAllButtonVisible && localConfig.hideAllWhenOneOpen) ? "none" : "flex",
                    }}
                    localConfig={localConfig}

                  />
                )
              }
            </div>
          )
        }
      </div>
        <div
          className="panorama_open_close_all_button"
          style={{
            transform: hideAllButtonVisible ? "translateX(0)" : "translateX(100px)"
          }}
        >
          <CloseAllIcon
            size={"40px"}
            onClick={() => handleCloseAll()}
          />
        </div>

      {
        //@ts-ignore
        localConfig.bottomNote &&
        <h3 style={
            {
              fontWeight: "500",
              textAlign: "center"
            }
          }
        >
          <HtmlParser>
            {
              //@ts-ignore
              localConfig.bottomNote
            }
          </HtmlParser>
        </h3>
      }

      <ClientContact
        config={localConfig}
      />
    </div>
  )
}

export default NewClient

