import uuid from "react-uuid"
import { FaInfoCircle } from "react-icons/fa";
export const kava = [

  {
    item_uuid: uuid(),
    title: "Ristretto",
    descr: "krátke espresso - 8 g",
    expandableDescr: [
      "Pripravuje sa z 8 g kvalitnej kávy.",
      "Doba extrakcie je len 15 sekúnd.",
      "Objem nápoja je 20 ml."
    ],
    volume: "15 ml",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso",
    descr: "8 g",
    expandableDescr: [
      "Pripravuje sa z 8 g kávy a 25 ml vody.",
      "Doba extrakcie je okolo 30 sekúnd.",
      "Ide vlastne o malú šálku silnej kávy."
    ],
    volume: "25 ml",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso Lungo",
    descr: "espresso + horúca voda - 8 g",
    expandableDescr: [
      "Označované aj ako espresso predĺžené teplou vodou.",
      "Pripravuje sa z 8 g mletých kávových zŕn s dvojnásobným objemom vody.",
    ],
    volume: "25 ml + 50 ml",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Double Espresso",
    descr: "dvojité espresso - 16 g",
    volume: "50 ml",
    price: "3,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso Macchiato",
    descr: "espresso + mliečna pena - 8 g",
    expandableDescr: [
      "Nechajte sa uniesť lahodnou klasikou.",
      "Spojenie espressa a mliečnej peny  v samom srdci kávy je neobyčajný zážitok."
    ],
    volume: "80 ml",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cappuccino",
    descr: "espresso + napenené mlieko - 8 g",
    expandableDescr: [
      "Kávový nápoj z espressa a našľahaného mlieka",
    ],
    volume: "180 ml",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cafe Latte",
    descr: "espresso + napenené mlieko - 8 g",
    expandableDescr: [
      "Mliečna káva, ktorá sa pripravuje z espressa a našľahaného mlieka vo väčšom objeme.",
    ],
    volume: "300 ml",
    price: "3,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Flat White",
    descr: "dvojité espresso + napenené mlieko - 16 g",
    expandableDescr: [
      "To je výrazná chuť kávy v našľahanom mlieku.",
      "Táto kombinácia vytvára silnú a bohatú chuť.",
      "Základom je 16 gramov čerstvo namletej kávy.",
      "Nápoj sa podáva v 200 ml šálke."
    ],
    volume: "125 ml",
    price: "3,80 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Viedenská káva",
    descr: "espresso lungo + šľahačka - 8 g",
    volume: "150 ml",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Írska káva",
    descr: "espresso + whiskey + šľahačka - 8 g",
    volume: "170 ml",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Filtrovaná káva pre 1",
    descr: "20 g",
    volume: "220 ml",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Filtrovaná káva pre 2",
    descr: "30 g",
    volume: "330 ml",
    price: "7,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Batch brew",
    descr: "filtrovaná káva",
    volume: "150 ml",
    price: "3,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Babyccino",
    descr: "napenené mlieko",
    volume: "150 ml",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mlieko ku káve",
    descr: "",
    volume: "20 ml",
    price: "0,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Alternatívne mlieko ku káve",
    descr: "podľa ponuky",
    volume: "20 ml",
    price: "0,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Šľahačka",
    descr: "",
    volume: "20 ml",
    price: "0,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Príchuť do kávy",
    descr: "vanilka / karamel / sezónne príchute",
    volume: "",
    price: "0,40 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaInfoCircle,
    iconColor: "#201F1D",
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Pri objednávke so sebou",
    descr: `
    Účtujeme si poplatok za obaly v sume<br/>
    - 0,10 € - pohár<br/>
    - 0,60 € - malá krabička<br/>
    - 1,10 € - veĺká krabica<br/>`,
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false,
    lastElement: false
  },
]