import uuid from "react-uuid"
import {
  AiOutlineInfoCircle
} from "react-icons/ai"

export const hernySvet = [
  {
    item_uuid: uuid(),
    title: "Využitie spoločenskej hry",
    descr: "",
    volume: "",
    price: "1,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Prenájom herných konzol",
    descr: "1,5 hod.",
    volume: "",
    price: "15,00 €",
    divideAfter: false
  },
  {
    type: "extra",
    icon: AiOutlineInfoCircle,
    iconColor: "#FFBF19",
    item_uuid: uuid(),
    title: "Upozornenie",
    descr: "Každé 1 € konzumného Vám bude odrátané z prenájmu herných konzol",
    volume: "",
    price: "",
    divideAfter: false,
    lastElement: false
  },
]