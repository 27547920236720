import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
import { FaInfoCircle } from "react-icons/fa";

/* import {
  GiIceCubes
} from "react-icons/gi" */
export const kava = [
  {
    item_uuid: uuid(),
    title: "Espresso",
    descr: "8 g káva",
    volume: "",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso Lungo",
    descr: "8 g káva",
    volume: "",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso Doppio",
    descr: "8 g káva",
    volume: "",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso Macchiato",
    descr: "8 g káva, našľahané mlieko",
    volume: "",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso bez kofeínu",
    descr: "8 g káva bez kofeínu",
    volume: "",
    price: "1,90 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Cappuccino",
    descr: "8 g káva, našľahané mlieko",
    volume: "",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cappuccino Mercy",
    descr: "8 g káva, našľahané mlieko, karamelový toping",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cappuccino Mercy Pistacchio",
    descr: "8 g káva, našľahané mlieko, pistáciový toping",
    volume: "",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Caffe Latte",
    descr: "8 g káva, našľahané mlieko",
    volume: "",
    price: "2,40 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Latte Macchiato",
    descr: "8 g káva, našľahané mlieko",
    volume: "",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Latte Macchiato Pistacchio",
    descr: "8 g káva, našľahané mlieko, pistáciový toping",
    volume: "",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Latte Macchiaton Ochutené",
    descr: "Karamel, Čokoláda, Kokos, Pražený orech - 8 g káva, našľahané mlieko, toping podľa výberu",
    volume: "",
    price: "2,80 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Zalievaná káva",
    descr: "8 g káva",
    volume: "",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Viedenská káva",
    descr: "8 g káva, šľahačka",
    volume: "",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Viedenská káva s kokosom",
    descr: "8 g káva, šľahačka, sirup kokos",
    volume: "",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Viedenská káva s karamelom",
    descr: "8 g káva, šľahačka, toping",
    volume: "",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Káva La'Nutella",
    descr: "8 g káva, šľahačka, nutella, toping",
    volume: "",
    price: "3,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Detská káva",
    descr: "8 g káva bez kofeínu, šľahačka, lentilky",
    volume: "",
    price: "2,70 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaInfoCircle,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Všetky kávy Vám pripravíme aj z",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Bezkofeínovej kávy",
    descr: "",
    volume: "",
    price: "0,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Sójového mlieka",
    descr: "",
    volume: "",
    price: "0,70 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Maresi",
    descr: "",
    volume: "0,02 l",
    price: "0,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Sójové mlieko",
    descr: "",
    volume: "0,02 l",
    price: "0,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Šľahačka",
    descr: "",
    volume: "15 g",
    price: "0,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Med porciovaný",
    descr: "",
    volume: "20 g",
    price: "0,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pohár so sebou",
    descr: "papierový / plastový",
    volume: "",
    price: "0,20 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]