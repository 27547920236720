import uuid from "react-uuid"
import { IoHome } from "react-icons/io5";


export const adresa_wifi = [
  {
    item_uuid: uuid(),
    title: "MercyCoffeeGuest",
    descr: "Heslo - MercyCoffee",
    volume: "",
    price: "",
    divideAfter: true
  },
  {
    type: "extra",
    icon: IoHome ,
    iconSize: "35px",
    item_uuid: uuid(),
    title: "Adresa",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Ul. poštová 25 / 233 Svidník",
    descr: "",
    volume: "",
    price: "",
    divideAfter: false
  },
]