import { useAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import axios from 'axios';
import { useCookies } from "react-cookie";
import MobileViewCard from "../adminComponents/MobileViewCard";
import PriceEditHeaderCard from "../adminComponents/PriceEditHeaderCard";
import { ConfirmationModalType, OfferElement, OfferSectionElement, OpenedSettingsTabIndexType } from "../../PropTypes/AdminDashboardProps";
import NavigationCard from "../adminComponents/NavigationCard";
import LogoutCard from "../adminComponents/LogoutCard";
import StatsCard from "../adminComponents/StatsCard";
import ItemElementRow from "../adminComponents/ItemElementRow";
import ItemElementHeader from "../adminComponents/ItemElementHeader";
import { dynamicListHeightSetup, elementValidation, invokeAlertComponent } from "../../services/userInteractionService";
import ConfirmationModal from "../adminComponents/ConfirmationModal";
import AlertComponent from "../adminComponents/AlertComponent";
import { TbAlertTriangle } from "react-icons/tb";
import CreateElementButton from "../adminComponents/CreateElementButton";
import CreateElementModal from "../adminComponents/CreateElementModal";
import { IoMdAdd } from "react-icons/io";
import NotAvailableComponent from "../adminComponents/NotAvailableComponent";



const URL = process.env.REACT_APP_MOJEQR_API_URL

const AdminDashboardCard = () => {

  const [draggedItemIndex, setDraggedItemIndex] = useState(-1)
  const [draggingOverItemIndex, setDraggingOverItemIndex] = useState(-1)
  const [clients, setClients] = useState([])
  const [cookie] = useCookies(['mojeqr_access_token', 'mojeqr_refresh_token']);
  const [openedTabIndex, setOpenedTabIndex] = useState<OpenedSettingsTabIndexType>(0)
  const [latestOrderNumber, setLatestOrderNumber] = useState(0)
  const [openedTabIndexAnimationInvoker, setOpenedTabIndexAnimationInvoker] = useState<OpenedSettingsTabIndexType>(0)
  const [sectionList, setSectionList] = useState([])
  const [elementList, setElementList] = useState<OfferElement[]>([])
  const [filteredElementList, setFilteredElementList] = useState<OfferElement[]>([])
  const [temporaryReorderedElementList, setTemporaryReorderedElementList] = useState<OfferElement[]>([])
  const [selectedSection, setSelectedSection] = useState<OfferSectionElement | null>()

  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModalType>({isOpen: false, modalText: ""})
  const [createElementModal, setCreateElementModal] = useState(false)
  const [updateElementModal, setUpdateElementModal] = useState(false)
  const [selectedItemElement, setSelectedItemElement] = useState<OfferElement | null>(null)
  //@ts-ignore
  const { logout, setUser } = useAuth()
  const handleLogout = () => {
    logout()
  }
  const handleRequestDeleteItemConfirmation = (selectedElement: OfferElement) => {
    setConfirmationModal({
      isOpen: true,
      modalText: `Naozaj chcete zmazať položku </br> <b>${selectedElement.item_name}</b> ?`
    })
    setSelectedItemElement(selectedElement)

  }

  const animationTimer = 100
  const handlePriceEditClick = () => {
    const index = 0
    setOpenedTabIndexAnimationInvoker(index)
    setTimeout(() => {
      setOpenedTabIndex(index)
    }, animationTimer);
  }
  const handleLayoutEditClick = () => {
    const index = 1
    setOpenedTabIndexAnimationInvoker(index)
    setTimeout(() => {
      setOpenedTabIndex(index)
    }, animationTimer);
  }
  const handleImageGalleryClick = () => {
    const index = 2
    setOpenedTabIndexAnimationInvoker(index)
    setTimeout(() => {
      setOpenedTabIndex(index)
    }, animationTimer);
  }
  const handleContactEditClick = () => {
    const index = 3
    setOpenedTabIndexAnimationInvoker(index)
    setTimeout(() => {
      setOpenedTabIndex(index)
    }, animationTimer);
  }
  const handleInvoicesSubscriptionClick = () => {
    const index = 4
    setOpenedTabIndexAnimationInvoker(index)
    setTimeout(() => {
      setOpenedTabIndex(index)
    }, animationTimer);
  }
  const handleHowToClick = () => {
    const index = 5
    setOpenedTabIndexAnimationInvoker(index)
    setTimeout(() => {
      setOpenedTabIndex(index)
    }, animationTimer);
  }

  const handleSearch = (element_name: string) => {
    setFilteredElementList(elementList
      .filter(element => element.item_name?.toLowerCase().includes(element_name.toLowerCase()))
      .sort((a, b) => a.item_order - b.item_order))
  }


  const handleSwitchElements = async (original_element_uuid: string, new_element_uuid:string) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }

    axios.patch(`${URL}/items/${original_element_uuid}/?switch_with_new_uuid=${new_element_uuid}`, {}, { headers })
    .then((response) => {
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT", "data.alert_text")
      getSectionList(selectedSection?.uuid)
    }).catch((error) => {
      console.log(error)
    })
  }

  const handleUpdateElement = async (data: any) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    console.log("ahoj")
    console.log(data)
    axios.patch(`${URL}/items/${data.uuid}/`, data.data, { headers }).then((response) => {
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT", data.alert_text)
      getSectionList(selectedSection?.uuid)
    }).catch((error) => {
      console.log(error)
    })
  }

  const handleCreateElement = async (element: OfferElement) => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }

    const newItemData = {
      belongs_to: selectedSection?.belongs_to,
      item_order: selectedSection?.offer_list.length, // Provide the item order
      item_type: element.item_type, // Choose the item type from the choices
      item_name: element.item_name, // Provide the item name
      item_description: element.item_description, // Provide the item description
      item_price: element.item_price, // Provide the item price
      item_volume: element.item_volume, // Provide the item volume
      item_without_description: element.item_without_description, // Specify if the item has a description
      flagBehindTitle: false, // Specify if the flag is behind the title
      item_is_visible: element.item_is_visible, // Specify if the item is visible
      item_expanded_image: "", // Provide the expanded image URL if applicable
      item_expanded_description: "", // Provide the expanded description if applicable
      divide_after: element.divide_after, // Specify if the item should be divided after
  };
  // Make the POST request to create a new item
  axios.post(`${URL}/items/?offerblock_uuid=${selectedSection?.uuid}`, newItemData, { headers })
    .then(response => {
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`Vytvorili ste položku ${element?.item_name}!`)
      // getSectionList(setSectionList)
      // get one list element
      getSectionById(selectedSection?.uuid)

    })
    .catch(error => {
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`Pri vytváraní položky sa niečo pokazilo. Skúste to znovu !`, "ALERT")
    })

  }


  const getSectionById = (section_uuid: any) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    if (section_uuid) {
      axios.get(`${URL}/offer-blocks/?uuid=${section_uuid}`, { headers }).then((response: any) => {
        setElementList(response.data.offer_list)
        setSelectedSection((prevState: any) => ({
          ...prevState,
          offer_list: response.data.offer_list
        }))
      }).catch((error) => {
        console.log(error)
      })
    }
  }


  const handleDeleteItem = async () => {
    setConfirmationModal({...confirmationModal, isOpen: false})
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    const item_uuid = selectedItemElement?.uuid

    axios.delete(`${URL}/items/?uuid=${item_uuid}`, { headers }).then((resp) => {
      invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`Zmazali ste položku ${selectedItemElement?.item_name}!`)
      setSelectedItemElement(null)
      getSectionById(selectedSection?.uuid)
      /* getSectionList(setSectionList) */
      // update list
    }).catch((error) => {
      console.log(error);
      setSelectedItemElement(null)
    })



  }



  const getBusinessEstablishments = async (setClients: any) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    try {
      const response = await axios.get(`${URL}/business-establishment/`, { headers })
      setClients(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getUserInfo = async (setUser: any) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    try {
      const response = await axios.get(`${URL}/user-info/`, { headers })
      setUser(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getSectionList = async (setSectionListx: any) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }

    axios.get(`${URL}/offer-blocks/`, { headers }).then((response) => {
      setSectionList(response.data)
    }).catch((error) => {
      console.log(error)
      setSectionList([])
    })
  }

  const handleItemDragStart = (item: OfferElement | null) => {
    if (item) {
      setTemporaryReorderedElementList([...filteredElementList])
      console.log(`dragging item: ${item.item_name}`)
    }
  }

  const handleItemDragEnd = (original_element: any) => {
    if(filteredElementList[draggingOverItemIndex].uuid) {
      //@ts-ignore
      handleSwitchElements(original_element.uuid, filteredElementList[draggingOverItemIndex].uuid)
      /* filteredElementList[draggingOverItemIndex].uuid */
    }
  }

  const handleSwitchItems = (item_original_index: number, item_new_index: number) => {

    if (item_original_index !== -1 && item_new_index !== -1) {
      // Ensure indexes are within the bounds of the array
      if (item_original_index < 0 || item_original_index >= filteredElementList.length || item_new_index < 0 || item_new_index >= filteredElementList.length) {
        throw new Error("One or both indexes are out of bounds.");
      }

      // Create a new array with the switched elements
      const newList = [...temporaryReorderedElementList];
      [newList[item_original_index], newList[item_new_index]] = [newList[item_new_index], newList[item_original_index]];

      // Update the state with the new list
      setFilteredElementList(newList);
    }
  }


  useEffect(() => {
    /* console.log(`dragging item with index ${draggedItemIndex} over item with index ${draggingOverItemIndex}`) */
    /* console.log(filteredElementList) */
    handleSwitchItems(draggedItemIndex, draggingOverItemIndex)
  }, [draggingOverItemIndex])

  /* const getElementsByListId = async (list_uuid: string | undefined,setSectionList: any) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.mojeqr_access_token}`
    }
    try {
      const response = await axios.get(`${URL}/offer-section-list?list_uuid=${list_uuid}`, { headers })
      setElementList(response.data[0].elements.sort((a: any, b: any) => a.order - b.order))
    } catch (error) {
      console.log(error);
    }
  } */




  useEffect(() => {
    // get user info
    // if is_super user get businessEstablishments
    getBusinessEstablishments(setClients)
    getUserInfo(setUser)
    getSectionList(setSectionList)
  // eslint-disable-next-line react-hooks/exhaustive-deps
    document.body.addEventListener("dragover", (event) => {
      event.preventDefault();
    })


  }, [])

  useEffect(() => {
    if (selectedSection) {
      /* getElementsByListId(selectedSection?.list, setElementList) */
      setElementList(selectedSection.offer_list.sort((a: any, b: any) => a.item_order - b.item_order))

    }
      //@ts-ignore
      /* setElementList(sectionList[0].offer_list.sort((a: any, b: any) => a.item_order - b.item_order)) */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedSection, sectionList])



  useEffect(() => {
    setFilteredElementList(elementList.sort((a, b) => a.item_order - b.item_order))
  }, [elementList])

  useEffect(() => {
    dynamicListHeightSetup()
  }, [openedTabIndex])
  useEffect(() => {
    const create_button_element = document.getElementById("CREATE_ELEMENT_BUTTON_ID")
    if (create_button_element && elementList.length === 0) {
      /* create_button_element.style.right = "15px"
      create_button_element.style.bottom = "15px" */
      /* create_button_element.style.right = "50vw"
      create_button_element.style.bottom = "50vh" */
      create_button_element.style.right = "25px"
      create_button_element.style.bottom = "25px"
      /* create_button_element.style.width = "500px" */



    } else if (create_button_element && elementList.length !== 0) {
      /* create_button_element.style.right = "100px"
      create_button_element.style.bottom = "100px" */

      /* create_button_element.style.right = "420px"
      create_button_element.style.bottom = "25px" */
      create_button_element.style.right = "25px"
      create_button_element.style.bottom = "25px"
    }
    let elementWithMaxOrder = elementList.reduce((maxElement, currentElement) => {
      return currentElement.item_order > maxElement.item_order ? currentElement : maxElement;
    }, { item_order: 0 })
    if (elementList.length === 0) {
      setLatestOrderNumber(elementWithMaxOrder.item_order)
    } else {
      setLatestOrderNumber(elementWithMaxOrder.item_order + 1)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementList.length])

  return(
    <>
      {
        confirmationModal.isOpen &&
        <ConfirmationModal
          modalText={confirmationModal.modalText}
          onClose={() => setConfirmationModal({...confirmationModal, isOpen: false})}
          onConfirm={() => {
            handleDeleteItem()
          }}
        />
      }
      {
        createElementModal &&
        <CreateElementModal
          businessEstablishment={clients[0]}
          latestOrderNumber={latestOrderNumber}
          sectionElements={elementList}
          onClose={() => setCreateElementModal(false)}
          onConfirm={handleCreateElement}
        />
      }

      {
        updateElementModal &&
        <CreateElementModal
          businessEstablishment={clients[0]}
          latestOrderNumber={latestOrderNumber}
          sectionElements={elementList}
          onClose={() => setUpdateElementModal(false)}
          onConfirm={(data) => handleUpdateElement({
            uuid: data?.uuid,
            name: data?.item_name,
            alert_text: `Upravili ste položku ${data.item_name}`,
            data: data
          })}
          selectedElement={selectedItemElement || null}
        />
      }
      <AlertComponent
        id="INFORMATION_ALERT_COMPONENT"
      />
      {
        openedTabIndex === 0 &&
        <CreateElementButton
          isEditView={openedTabIndexAnimationInvoker === 0}
          onClick={() => {
            setCreateElementModal(true)
            setSelectedItemElement(null)
          }}
        />
      }
      <div className="col left_col" id="LEFT_COL_ADMIN">
        <div className="card nav_card">
          <NavigationCard
            onPriceEditClick={handlePriceEditClick}
            onLayoutEditClick={handleLayoutEditClick}
            onImageGalleryClick={handleImageGalleryClick}
            onContactEditClick={handleContactEditClick}
            onInvoiceSubscriptionClick={handleInvoicesSubscriptionClick}
            onHowToClick={handleHowToClick}
            activeTabNumber={openedTabIndexAnimationInvoker}
          />
        </div>
        <div className="card logout_card">
          <LogoutCard
            onLogout={handleLogout}
          />
        </div>
      </div>
      <div className={`col mid_col`} id="MID_COL_ADMIN">
        {
          openedTabIndex === 0 &&
          <div
            className={`card content_card`}
            style={{
              transform: `${openedTabIndexAnimationInvoker !== 0 ? "translateY(100vh)" : "translateY(0vh)"}`
            }}
          >
            <PriceEditHeaderCard
              sectionList={sectionList}
              setSelectedSection={(section) => {
                setSelectedSection(section)
                getSectionById(section?.uuid)
              }}
              onSearch={handleSearch}
              clearSearch={() => handleSearch("")}
            />
            <ItemElementHeader />
            <div className="list_row" id="LIST_ROW_ID">
              {
                filteredElementList.map((element, elementIndex) =>
                  <ItemElementRow
                    index={elementIndex}
                    key={elementIndex}
                    offerElement={element}
                    businessEstablishment={clients[0]}
                    onDragStart={(item) => {
                      handleItemDragStart(item)
                      setDraggedItemIndex(elementIndex)
                     }}
                    onDragEnd={(item) => {
                      handleItemDragEnd(item)
                      setDraggingOverItemIndex(-1)
                      setDraggedItemIndex(-1)
                    }}
                    onDragEnter={(item) => {  }}
                    onDragLeave={(item) => {  }}
                    onDragOver={(item) => { setDraggingOverItemIndex(elementIndex) }}
                    onDelete={(selectedElement) => {
                      handleRequestDeleteItemConfirmation(selectedElement)
                    }}
                    onOpen={(selectedElement) => {
                      /* invokeAlertComponent("INFORMATION_ALERT_COMPONENT",`${selectedElement.item_name} element click event`) */
                      setUpdateElementModal(true)
                      setSelectedItemElement(selectedElement)

                    }}
                    /* onUpdateElement={(data) => { handleUpdateElement(data) }} */
                    onUpdateElement={(data) => {
                      handleUpdateElement(data)
                    }}
                  />
                )
              }
              {
                filteredElementList.length === 0 &&
                <div className="no_elements_info" id="NO_ELEMENTS_INFO" key={selectedSection?.block_title_long}>
                  <TbAlertTriangle
                    id="ALERT_ICON_ID"
                    size={"55px"}
                    color="#2C2C2C"
                  />
                  {
                    elementList.length === 0 ?
                    `V sekcii ${selectedSection?.block_title_long} je prázdno.` :
                    `Vyhľadávaná položka sa nenachádza v zozname.`
                  }

                </div>
              }
            </div>
          </div>
        }
        {
          openedTabIndex === 1 &&
          <div
            /* className="card" */
            style={{
              transform: `${openedTabIndexAnimationInvoker !== 1 ? "translateY(100vh)" : "translateY(0vh)"}`
            }}
          >
            <div className="card">
              <div className="title">
                Zmena rozloženia
              </div>
            </div>
            <div className="card">
              <NotAvailableComponent
                text="Zmena rozloženia momentálne nie je k dispozícií."
              />
            </div>

          </div>
        }
        {
          openedTabIndex === 2 &&
          <div
            style={{
              transform: `${openedTabIndexAnimationInvoker !== 2 ? "translateY(100vh)" : "translateY(0vh)"}`
            }}
          >
            <div className="card">
              <div className="title">
                Galéria
              </div>
            </div>
            <div className="card">
              <NotAvailableComponent
                text="Galéria momentálne nie je k dispozícií."
              />
            </div>
          </div>
        }
        {
          openedTabIndex === 3 &&
          <div
            style={{
              transform: `${openedTabIndexAnimationInvoker !== 3 ? "translateY(100vh)" : "translateY(0vh)"}`
            }}
          >
            <div className="card">
              <div className="title">
                Úprava kontaktu
              </div>
            </div>
            <div className="card">
              <NotAvailableComponent
                text="Úprava kontaktu momentálne nie je k dispozícií."
              />
            </div>
          </div>
        }
        {
          openedTabIndex === 4 &&
          <div
            className="card"
            style={{
              transform: `${openedTabIndexAnimationInvoker !== 4 ? "translateY(100vh)" : "translateY(0vh)"}`
            }}
          >
            <div className="card">
              <div className="title">
              Faktúry a predplatné
              </div>
            </div>
            <div className="card">
              <NotAvailableComponent
                text="Faktúry a predplatné momentálne nie su k dispozícií."
              />
            </div>

          </div>
        }
        {
          openedTabIndex === 5 &&
          <div
            className="card"
            style={{
              transform: `${openedTabIndexAnimationInvoker !== 5 ? "translateY(100vh)" : "translateY(0vh)"}`
            }}
          >
            <div className="card">
              <div className="title">
                Ako na to
              </div>
            </div>
            <div className="card">
              {/* <NotAvailableComponent
                text="Faktúry a predplatné momentálne nie su k dispozícií."
              /> */}
            </div>
          </div>
        }
      </div>
      {/* <div className="col right_col" id="RIGHT_COL_ADMIN">
        <div className="card mobile_card">
          <MobileViewCard />
        </div>
        <div className="card stats_card">
          <StatsCard />
        </div>
      </div> */}
    </>
  )
}

export default AdminDashboardCard