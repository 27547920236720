import HtmlParser from "../../services/htmlParser"
import { GiChiliPepper } from "react-icons/gi"
import { BiShowAlt } from "react-icons/bi"
import { FaQuestionCircle } from "react-icons/fa";

import FlagElement from "../global/FlagElement"
import { useEffect, useState } from "react";

interface localProps {
  element: {
    title: string,
    descr: string,
    volume: string,
    price: string,
    flagBehindTitle?: boolean,
    flag?: string,
    flagHeight?: string,
    blockId?: string,
    divideAfter: boolean,
    lastElement?: boolean,
    type?: "extra" | "description" | "title"
    typeAfter?: "extra" | "description",
    icon?: any,
    iconSize?: string,
    iconColor?: string,
    spicyLevel?: 0 | 1 | 2 | 3,
    noDescr?: boolean,
    expandableDescr?: any
  },
  localConfig: any
}





const ItemElement = (props: localProps) => {

  /* const handleLeftClickImage = () => {
    alert("left")
  }
  const handleRightClickImage = () => {
    alert("right")
  } */
  const [hasImg, setHasImg] = useState(false)

  const manageParagraphs = (arrayOfParagraphs: Array<string>, expandableDescrContent:HTMLElement, closing: true | false) => {
    if (arrayOfParagraphs) {
      if (closing) {
        for (let index = 0; index < arrayOfParagraphs.length; index++) {
          document.getElementById(`EXPANDABLE_DESCRIPTION_PARAGRAPH_${index}`)?.remove()
        }
        document.getElementById("IMAGE_MODAL_EXPANDABLE_CONTENT")!.style.display = "none"
      } else {
        document.getElementById("IMAGE_MODAL_EXPANDABLE_CONTENT")!.style.display = "block"
        for (let index = 0; index < arrayOfParagraphs.length; index++) {
          const element =arrayOfParagraphs[index]
          const paragraph = document.createElement("p")
          paragraph.setAttribute("id", `EXPANDABLE_DESCRIPTION_PARAGRAPH_${index}`)
          paragraph.textContent = element
          expandableDescrContent?.appendChild(paragraph)
        }
      }
    }
  }


  const handleElementClick = () => {
    //@ts-ignore
    if (props.element.img || props.element.expandableDescr) {

      const close = () => {
        // add closing animation

        modal!.style.opacity = "0"

        title!.style.opacity = "0"
        title!.style.transform = "translateY(-100vh)"

        expandableDescrContent!.style.opacity = "0"
        expandableDescrContent!.style.transform = "translateY(-100vh)"

        image!.style.transform = "translateY(-100vh)"
        setTimeout(() => {
          modal!.style.display = "none"
          image!.style.transform = "translateY(100vh)"
          title!.style.transform = "translateY(80px)"
          expandableDescrContent!.style.transform = "translateY(80px)"
        }, 500);
        manageParagraphs(props.element.expandableDescr, expandableDescrContent, true)
      }

      /* document.body.style.overflow = "hidden" */
      const closeButton = document.getElementById("IMAGE_MODAL_CLOSE_BUTTON_WRAP")
      const modal = document.getElementById("IMAGE_MODAL")
      const image = document.getElementById("IMAGE_MODAL_IMAGE")
      image!.style.display = "block"
      const title = document.getElementById("IMAGE_MODAL_TITLE")
      const expandableDescrContent = document.getElementById("IMAGE_MODAL_EXPANDABLE_CONTENT")!
      /* document.getElementById("IMAGE_MODAL_LEFT")?.addEventListener("click", handleLeftClickImage)
      document.getElementById("IMAGE_MODAL_RIGHT")?.addEventListener("click", handleRightClickImage) */
      document.getElementById("IMAGE_MODAL_LEFT")?.addEventListener("click", close)
      document.getElementById("IMAGE_MODAL_RIGHT")?.addEventListener("click", close)
      /* document.getElementById("IMAGE_MODAL_CLOSE_BUTTON_WRAP")?.addEventListener("click", close) */

      expandableDescrContent!.style.display = "none"

      if (props.element.expandableDescr) {
        image!.style.display = "none"
        manageParagraphs(props.element.expandableDescr, expandableDescrContent, false)


        /* expandableDescrContent!.style.display = "block" */
      }

      modal!.style.display = "flex"
      // add entering animation
      setTimeout(() => {
        modal!.style.opacity = "1"
        image!.style.transform = "translateY(0vh)"
      }, 100)

      setTimeout(() => {

        title!.style.opacity = "1"
        expandableDescrContent!.style.opacity = "1"

      }, 500);
      setTimeout(() => {

        title!.style.transform = "translateY(0px)"
        expandableDescrContent!.style.transform = "translateY(0px)"
      }, 500);

      //@ts-ignore
      closeButton!.style.color = "white"
      //@ts-ignore
      image!.src = props.element.img
      title!.innerText = props.element.title

      closeButton?.addEventListener("click", close)
    }


  }

  useEffect(() => {
    //@ts-ignore
    if (props.element.img || props.element.expandableDescr) {
      setHasImg(true)
    }
  }, [])

  return (
    <div
      id={props.element.blockId}
      className={`menu_item_element ${hasImg ? `${props.localConfig.mainWrapClass}_image_included` : ""}`}
      onClick={handleElementClick}
    >
      <div className="title_row">
        <div
          className="title"
          style={{
            display: "flex",
            alignItems: "center",

          }}
        >
          {
            ((props.element.type === "extra" || props.element.type === "description") && props.element.icon)  &&
            <div className="title_descr_icon_wrap">
              <props.element.icon
                className="icon"
                color={props.element.iconColor || "white"}
                size={props.element.iconSize || "30px"}
              />
            </div>
          }
          <span>
            { props.element.title }
            {
              props.element.flag && props.element.flagBehindTitle &&
              <FlagElement
                flag={props.element.flag}
                flagHeight={props.element.flagHeight}
                descrLength={props.element.descr.length}
                flagBehindTitle={props.element.flagBehindTitle}
                //@ts-ignore
                hasImage={props.element.img}
              />
            }
            {
              //@ts-ignore
              props.element.img &&
                <BiShowAlt
                  id="INCLUDING_IMAGE"
                  size={"15px"}
                />
                /* : props.element.expandableDescr &&
                <FaQuestionCircle
                  id="INCLUDING_IMAGE"
                  size={"15px"}
                  style={{
                    marginTop: "5px"
                  }}
                /> */
            }
          </span>
          {
            (props.element.spicyLevel !== 0 && props.element.spicyLevel) && Array.from({ length: props.element.spicyLevel }, (_, index) => (
              <GiChiliPepper
                key={index}
                size={"20px"}
                style={{
                  marginLeft: index > 0 ? "-10px" : "15px"
                }}
                color="#F7C808"
              />
            ))
          }
        </div>
        <div className="price">
          { props.element.price }
        </div>
      </div>
        <div className="description_row">
          {

            !props.element.noDescr && <div className="descr">
              <HtmlParser>
                { props.element.descr }
              </HtmlParser>
              {
                props.element.flag && !props.element.flagBehindTitle &&
                <FlagElement
                  flag={props.element.flag}
                  flagHeight={props.element.flagHeight}
                  descrLength={props.element.descr.length}
                />
              }
              {
                props.element.expandableDescr &&
                <FaQuestionCircle
                  size={"15px"}
                  style={{
                    marginTop: "5px",
                    paddingLeft: "5px"
                  }}
                />
              }
            </div>
          }
          {
            props.element.volume && <div className="volume">
              <HtmlParser>
                { props.element.volume }
              </HtmlParser>
            </div>
          }
        </div>
      {
      props.element.divideAfter &&
        (
          props.element.type === "title"
            ?
            <div
              style={{
                marginTop: "0px",
                width: "100%",
                backgroundColor: props.localConfig.dividerColor
              }}
              className="divider"
            />
            :
            <div
              style={{
                width:
                  (
                    props.element.type === "extra"
                      || props.element.type === "description"
                      || props.element.typeAfter === "extra"
                      || props.element.typeAfter === "description"
                      || props.element.type === "title"
                  )
                      ? "100%" : "90%",
                backgroundColor: props.localConfig.dividerColor
              }}
              className="divider"
          />
        )
      }

    </div>
  )

}

export default ItemElement