import BlogCard from "../cards/BlogCard";

const Blog = () => {


  return (
    <div className="new_home_page">
      <BlogCard
        id="BLOG_CARD_ID"
      />

    </div>
  )
}

export default Blog;

