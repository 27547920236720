import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const alkoholicke_napoje = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Aperitív",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Martini bianco",
    descr: "15 %",
    volume: "0,1 cl",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Martini extra dry",
    descr: "18 %",
    volume: "0,1 cl",
    price: "2,30 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Likér",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Griotka",
    descr: "27 %",
    volume: "4 cl",
    price: "1,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fernet stock",
    descr: "38 %",
    volume: "4 cl",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fernet citrus",
    descr: "27 %",
    volume: "4 cl",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Becherovka",
    descr: "38 %",
    volume: "4 cl",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jägermeister",
    descr: "35 %",
    volume: "4 cl",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Bailey´s",
    descr: "17 %",
    volume: "4 cl",
    price: "2,60 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Destilát",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Jelinek borovička",
    descr: "45 %",
    volume: "4 cl",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Slivovica bošacka",
    descr: "52 %",
    volume: "4 cl",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hruška Wilmos originál",
    descr: "37,5 %",
    volume: "4 cl",
    price: "2,30 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Vodka",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Finlandia",
    descr: "40 %",
    volume: "4 cl",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Absolut",
    descr: "40 %",
    volume: "4 cl",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Finlandia cranberry",
    descr: "40 %",
    volume: "4 cl",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Russian standard",
    descr: "40 %",
    volume: "4 cl",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Greygoose",
    descr: "40 %",
    volume: "4 cl",
    price: "4,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Gin",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Beefeater",
    descr: "40 %",
    volume: "4 cl",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Gin Bombay",
    descr: "40 %",
    volume: "4 cl",
    price: "2,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Tequila",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Olmeca bianco",
    descr: "38 %",
    volume: "4 cl",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Olmeca gold",
    descr: "38 %",
    volume: "4 cl",
    price: "2,70 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Rum",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Um tuzemský",
    descr: "40 %",
    volume: "4 cl",
    price: "1,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Captain Morgan spiced",
    descr: "35 %",
    volume: "4 cl",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Božkov Republica Reserva",
    descr: "40 %",
    volume: "4 cl",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Diplomático reserva",
    descr: "40 %",
    volume: "4 cl",
    price: "4,40 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Brandy, Cognac",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Pliska",
    descr: "36 %",
    volume: "4 cl",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Metaxa 5*",
    descr: "38 %",
    volume: "4 cl",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Metaxa 7*",
    descr: "40 %",
    volume: "4 cl",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "KBŠ",
    descr: "40 %",
    volume: "4 cl",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hennessy",
    descr: "40 %",
    volume: "4 cl",
    price: "4,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Scotch Whisky",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Ballantines",
    descr: "40 %",
    volume: "4 cl",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Johnnie Walker red",
    descr: "40 %",
    volume: "4 cl",
    price: "2,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Irish Whisky",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Jameson",
    descr: "40 %",
    volume: "4 cl",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tullamore",
    descr: "40 %",
    volume: "4 cl",
    price: "2,60 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Burbon, American Whisky",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Jack Daniel´s",
    descr: "40 %",
    volume: "4 cl",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jack Daniel´s honey",
    descr: "35 %",
    volume: "4 cl",
    price: "3,70 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]