
import { useEffect, useState } from "react"
import NewClient from "../../components/clients/NewClient"

interface localProps {
  path: string,
  localConfig: any,
  localMenu?: object,
  localClientLogo: string,
  localBackgroundColor: string,
  clientName: string
}

const NewClientPage = (props: localProps) => {

  const [localBackgroundColor] = useState(props.localBackgroundColor)
  const [clientName] = useState(props.clientName)
  const [localConfig] = useState(props.localConfig)
  const [localMenu] = useState(props.localMenu)
  const [localClientLogo] = useState(props.localConfig.logo)

  useEffect(()=> {
    document.body.style.backgroundColor = localBackgroundColor
    window.scrollTo(0,0)
    document.title = `MojeQr - ${clientName}`
    document.getElementsByTagName('meta')[3].content = `${clientName} využíva službu MojeQR. Mrkni sa sem a v prípade záujmu nás neváhaj kontaktovať na +421 940 850 530 alebo emailom na info@mojeqr.sk. Čakáme na teba!`

    document.getElementsByTagName('link')[2].href = `https://www.mojeqr.sk/${props.path}`
  }, [clientName, localBackgroundColor, props.path])

  return (
    <>
      {
        localMenu &&
        <NewClient
          localConfig={localConfig}
          localMenu={localMenu}
          localClientLogo={localClientLogo}
        />
      }

    </>
  )
}

export default NewClientPage

