import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
import { FaWineGlass, FaWineBottle } from "react-icons/fa";


export const vino = [
  {
    type: "extra",
    icon: FaWineGlass,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Biele víno - rozlievané",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Pivnica Orechová Chardonnay",
    descr: "(suché)",
    volume: "0,10 l",
    price: "1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pivnica Orechová Trayvel",
    descr: "(polosuché)",
    volume: "0,10 l",
    price: "1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Château Topoľčianky Rulandské biele",
    descr: "(polosladké)",
    volume: "0,10 l",
    price: "1,70 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaWineGlass,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Ružové víno - rozlievané",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Pivnica Orechová Frankovka modrá Rosé",
    descr: "(polosuché)",
    volume: "0,10 l",
    price: "1,70 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaWineGlass,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Červené víno - rozlievané",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Pivnica Orechová Frankovka modrá",
    descr: "(suché)",
    volume: "0,10 l",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pivnica Orechová Svätovavrinecké",
    descr: "(polosuché)",
    volume: "0,10 l",
    price: "1,80 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaWineGlass,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Šumivé víno",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Prosecco",
    descr: "(suché)",
    volume: "0,10 l",
    price: "1,70 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Prosecco nealko",
    descr: "(suché)",
    volume: "0,20 l",
    price: "3,20 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaWineBottle,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Biele víno - fľaškové",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Pelvins Rulandské šedé",
    descr: "(neskorý zber, polosuché)",
    volume: "0,75 l",
    price: "18,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pelvins Muškát Moravský",
    descr: "(kabinetské víno, polosladké)",
    volume: "0,75 l",
    price: "18,00 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaWineBottle,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Červené víno - fľaškové",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Pelvins Neronet",
    descr: "(suché)",
    volume: "0,75 l",
    price: "19,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pereg",
    descr: "(ríbezľové víno, sladké)",
    volume: "0,75 l",
    price: "13,00 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaWineBottle,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Šumivé víno",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Hubert Club",
    descr: "(sekt, polosladké)",
    volume: "0,75 l",
    price: "10,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hubert De Luxe",
    descr: "(sekt, sladké)",
    volume: "0,75 l",
    price: "12,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Prosecco Giovanni DOC Treviso",
    descr: "(extra suché, extra dry)",
    volume: "0,75 l",
    price: "15,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Veuve Clicquot Ponsardin",
    descr: "(šampanské, suché)",
    volume: "0,75 l",
    price: "75,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Moët & Chandon Ice Imperial",
    descr: "(šampanské, demi sec)",
    volume: "0,75 l",
    price: "100,00 €",
    divideAfter: true
  },




  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]