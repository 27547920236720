import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const horuca_cokolada = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Horúca talianská čokoláda",
    descr: "30 g čokoláda, 10 cl mlieko",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Horká",
    descr:  "",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mandľová",
    descr:  "",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cherry",
    descr:  "",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Klasická",
    descr:  "",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Nugátová",
    descr:  "",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Biela",
    descr:  "",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kokosová",
    descr:  "",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  },/*
  {
    item_uuid: uuid(),
    title: "Chilli",
    descr:  "",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  }, */
  {
    item_uuid: uuid(),
    title: "Oriešková",
    descr:  "",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  },/*
  {
    item_uuid: uuid(),
    title: "Mliečna",
    descr:  "",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  }, */
  {
    item_uuid: uuid(),
    title: "Mätová",
    descr:  "",
    volume: "",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Biela s pistáciami",
    descr:  "",
    volume: "",
    price: "2,70 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },

]

