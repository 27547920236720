const UpdatingMenuCard = () => {
  return (
    <div
      style={{
        height: "65vh",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
    >

      <span
        style={{
          fontSize: "24px",
          fontWeight: 500
        }}
      >
        Momentálne aktualizujeme našu ponuku.
      </span>

    </div>
  )
}

export default UpdatingMenuCard