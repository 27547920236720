import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const doplnkovy_sortiment = [
  {
    item_uuid: uuid(),
    title: "Heets - Terea",
    descr: "(podľa aktuálnej ponuky)",
    volume: "",
    price: "5,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zapaľovač",
    descr: "",
    volume: "1 ks",
    price: "1,00 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]