import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const teple_napoje = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Čierne čaje",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Čierny čaj Earl Grey",
    descr: "",
    volume: "",
    price: "1,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Zelené a bylinné čaje",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Zelený čaj",
    descr: "",
    volume: "",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zelený čaj s jazmínom",
    descr: "",
    volume: "",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zelený čaj s mätou",
    descr: "",
    volume: "",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zelený čaj s granátovým jablkom",
    descr: "",
    volume: "",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kamilka a levanduľa",
    descr: "",
    volume: "",
    price: "1,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Prírodné ovocné čaje",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Ovocný čaj lesné plody",
    descr: "",
    volume: "",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ovocný čaj citrónová tráva, zázvor a citrusy",
    descr: "",
    volume: "",
    price: "1,90 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Med",
    descr: "porciovaný",
    volume: "",
    price: "0,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Citrón",
    descr: "mesiačik",
    volume: "",
    price: "0,10 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Čerstvo pripravovaný čaj",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Čaj z čerstvého zázvoru",
    descr: "",
    volume: "",
    price: "2,10 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Horúca čokoláda",
    descr: "(čokoláda, 0,10 l mlieka",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Horká",
    descr: "",
    volume: "",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Biela",
    descr: "",
    volume: "",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mliečna",
    descr: "",
    volume: "",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Oriešková",
    descr: "",
    volume: "",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kokosová",
    descr: "",
    volume: "",
    price: "2,50 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Horúci džús",
    descr: "(jahoda, čierna ríbezľa, pomaranč, jablko)",
    volume: "",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Chai Latte",
    descr: "(chai latte sirup, našľahané mlieko)",
    volume: "",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Varené víno - Várka",
    descr: "(škorica, klinčeky, cukor)",
    volume: "",
    price: "0,30 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]