import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
import new_flag from "../../../assets/images/clients/icons/mercy_coffee/new_flag.png"

export const alkoholicke_napoje = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Aperitívy",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Martini bianco",
    descr: "15 %",
    volume: "0,10 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Martini dry",
    descr: "18 %",
    volume: "0,10 l",
    price: "2,00 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Likéry - ovocné",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Becherovka",
    descr: "38 %",
    volume: "0,04 l",
    price: "1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jägermeister",
    descr: "35 %",
    volume: "0,04 l",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jägermeister ORANGE",
    descr: "33 %",
    volume: "0,04 l",
    price: "2,50 €",
    flagBehindTitle: false,
    flagHeight: "25px",
    flag: new_flag,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tatranský Čaj",
    descr: "52 %",
    volume: "0,04 l",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fernet Stock",
    descr: "38 %",
    volume: "0,04 l",
    price: "1,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Likéry - bylinné",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Fernet Stock Citrus",
    descr: "27 %",
    volume: "0,04 l",
    price: "1,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Likéry - ostatné",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Bailey´s",
    descr: "17 %",
    volume: "0,04 l",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Malibu",
    descr: "21 %",
    volume: "0,04 l",
    price: "1,80 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Vodka",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Greygoose",
    descr: "40 %",
    volume: "0,04 l",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Absolut",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Finlandia",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Koskenkorva",
    descr: "40 %",
    volume: "0,04 l",
    price: "1,80 €",
    flagBehindTitle: false,
    flagHeight: "25px",
    flag: new_flag,
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Gin",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Beefeater",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Beefeater Pink",
    descr: "37,5 %",
    volume: "0,04 l",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tanqueray",
    descr: "43,1 %",
    volume: "0,04 l",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Gin Bombay Sapphire",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,70 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Ovocné destiláty",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Golden Tri Stromy Marhuľovica",
    descr: "42 %",
    volume: "0,04 l",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Golden Tri stromy Hruškovica",
    descr: "42 %",
    volume: "0,04 l",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Golden Tri stromy Jablkovica",
    descr: "42 %",
    volume: "0,04 l",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Bošácka Slivovica",
    descr: "52 %",
    volume: "0,04 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Borovička Koniferum / Lime / Pink",
    descr: "37,5 %",
    volume: "0,04 l",
    price: "1,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Rum",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Diplomático reserva Excl. 12y",
    descr: "40 %",
    volume: "0,04 l",
    price: "4,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zacapa Centenario 23y",
    descr: "40 %",
    volume: "0,04 l",
    price: "5,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Plantation Sealander",
    descr: "40 %",
    volume: "0,04 l",
    price: "5,50 €",
    flagBehindTitle: false,
    flagHeight: "25px",
    flag: new_flag,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Blue Mauritius Gold",
    descr: "40 %",
    volume: "0,04 l",
    price: "5,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Don Papa",
    descr: "40 %",
    volume: "0,04 l",
    price: "4,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Canerock",
    descr: "40 %",
    volume: "0,04 l",
    price: "4,80 €",
    flagBehindTitle: false,
    flagHeight: "25px",
    flag: new_flag,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Piquero",
    descr: "40 %",
    volume: "0,04 l",
    price: "4,80 €",
    flagBehindTitle: false,
    flagHeight: "25px",
    flag: new_flag,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Bumbu Rum",
    descr: "35 %",
    volume: "0,04 l",
    price: "4,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "The Demon's Share",
    descr: "40 %",
    volume: "0,04 l",
    price: "4,30 €",
    flagBehindTitle: false,
    flagHeight: "25px",
    flag: new_flag,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Bacardi Superior",
    descr: "37,5 %",
    volume: "0,04 l",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Captain Morgan",
    descr: "35 %",
    volume: "0,04 l",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Božkov Republika Exclusive",
    descr: "38 %",
    volume: "0,04 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Božkov Republika Honey",
    descr: "35 %",
    volume: "0,04 l",
    price: "2,20 €",
    flagBehindTitle: false,
    flagHeight: "25px",
    flag: new_flag,
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Tequila",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Olmeca Silver",
    descr: "38 %",
    volume: "0,04 l",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Olmeca Gold",
    descr: "38 %",
    volume: "0,04 l",
    price: "2,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Brandy",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Metaxa 5*",
    descr: "38 %",
    volume: "0,04 l",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Karpatské Brandy Špeciál",
    descr: "36 %",
    volume: "0,04 l",
    price: "4,30 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Koňak",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Martel VS",
    descr: "40 %",
    volume: "0,04 l",
    price: "4,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Martel VSOP",
    descr: "40 %",
    volume: "0,04 l",
    price: "5,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Rémy Martin VSOP",
    descr: "40 %",
    volume: "0,04 l",
    price: "5,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Whiskey & Bourbon",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Jameson",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tullamore Dew",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tullamore Dew Honey",
    descr: "35 %",
    volume: "0,04 l",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jack Daniels",
    descr: "40 %",
    volume: "0,04 l",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jack Daniel's Honey",
    descr: "35 %",
    volume: "0,04 l",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jack Daniel's Apple",
    descr: "35 %",
    volume: "0,04 l",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jack Daniel's Fire",
    descr: "35 %",
    volume: "0,04 l",
    price: "3,30 €",
    flagBehindTitle: false,
    flagHeight: "25px",
    flag: new_flag,
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]