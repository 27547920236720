import uuid from "react-uuid"

export const miesaneNealkoDrinky = [
  {
    item_uuid: uuid(),
    title: "Nochino tonic",
    descr: "nochino, tonic, limetka",
    volume: "300 ml",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Grapefruit leaf",
    descr: "sirup grep-bazalka, sóda, limetka",
    volume: "300 ml",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pink floyd",
    descr: "sirup levanduľa-arónia, sóda, limetka",
    img: require("../../../assets/images/clients/items/ObyvackaBar/miesane_nealko_drinky/pink-floyd.jpeg"),
    volume: "300 ml",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kiwi rain",
    descr: "sirup rebarbora, kiwi, sóda, limetka",
    volume: "300 ml",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Paint apple",
    descr: "sirup ananás-mäta, sóda, limetka",
    volume: "300 ml",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Passion fruit",
    descr: "sirup passion fruit-mandarínka, sóda, limetka",
    img: require("../../../assets/images/clients/items/ObyvackaBar/miesane_nealko_drinky/passion_fruit.jpeg"),
    volume: "300 ml",
    price: "4,50 €",
    divideAfter: false
  },
]