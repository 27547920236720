import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

interface LocalProps {
  id: string
}

const ClientsCard = (props: LocalProps) => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClientClick = (link: string) => {
    navigate(link)
  }

  const clients = {
    prednaHora: {
      link: "/predna_hora",
      img: require("../../assets/images/clients/logos/predna_hora.jpg")
    },
    panorama: {
      link: "/panorama",
      img: require("../../assets/images/clients/logos/panorama.jpg")
    },
    jazzCafe: {
      link: "/jazz_cafe",
      img: require("../../assets/images/clients/logos/jazz_cafe.jpg")
    },
    obyvackaBar: {
      link: "/obyvacka_bar",
      img: require("../../assets/images/clients/logos/obyvacka_bar.png")
    },
    mercyCoffe: {
      link: "/mercy_coffee",
      img: require("../../assets/images/clients/logos/mercy_coffee.png")
    }
  }

  return (
    <div
      id={ props.id }
      className="clientsCard"
    >
      <h1
        id="CLIENTS_TITLE_ID"
      >
        { t("clientsCard.title") }
      </h1>
      <h3>
        <b>{ t("clientsCard.description") }</b>
      </h3>
      <div className="clientsCard__wrap">
        <img
          src={clients.prednaHora.img}
          alt="MojeQr - Piváreň Predná Hora Humenné"
          onClick={() => { handleClientClick(clients.prednaHora.link) }}
        />
        <img
          src={clients.panorama.img}
          alt="MojeQr - Panorama Caffe & Drink bar"
          onClick={() => { handleClientClick(clients.panorama.link) }}
        />

      </div>
      <div className="clientsCard__wrap">
        <img
          src={clients.jazzCafe.img}
          className="clientCard_jazzCafe"
          alt="MojeQr - Jazz Café Humenné"
          onClick={() => { handleClientClick(clients.jazzCafe.link) }}
        />

      </div>
      <div className="clientsCard__wrap">
        <img
          src={clients.obyvackaBar.img}
          className="clientCard_obyvackaBar"
          alt="MojeQr - Obývačka Bar Humenné"
          onClick={() => { handleClientClick(clients.obyvackaBar.link) }}
        />

      </div>
      <div className="clientsCard__wrap">
        <img
          src={clients.mercyCoffe.img}
          className="clientCard_mercyCoffe"
          alt="MojeQr - Mercy Coffee"
          onClick={() => { handleClientClick(clients.mercyCoffe.link) }}
        />

      </div>

    </div>
  )
}

export default ClientsCard