//@ts-nocheck
import uuid from "react-uuid"

import coffeeIcon from "../../assets/images/clients/icons/panorama/coffee-cup.svg"
import teaIcon from "../../assets/images/clients/icons/panorama/tea.svg"
import nonalcoIcon from "../../assets/images/clients/icons/panorama/drink.svg"
import partyIcon from "../../assets/images/clients/icons/mercy_coffee/party.svg"
import wifiIcon from "../../assets/images/clients/icons/mercy_coffee/wifi.svg"
import snacksIcon from "../../assets/images/clients/icons/panorama/snack.svg"
import wineIcon from "../../assets/images/clients/icons/panorama/glass-with-wine.svg"
import drinkIcon from "../../assets/images/clients/icons/panorama/cocktail.svg"
import tabaqueIcon from "../../assets/images/clients/icons/panorama/tabaque.svg"
import alcoIcon from "../../assets/images/clients/icons/panorama/shots.svg"
import icedCoffeeIcon from "../../assets/images/clients/icons/jazz_cafe/iced_coffee.svg"
import frappeIcon from "../../assets/images/clients/icons/jazz_cafe/frappe.svg"
import freshJuiceIcon  from "../../assets/images/clients/icons/jazz_cafe/fresh_juice.svg"
import beerIcon from "../../assets/images/clients/icons/panorama/beer.svg"

import { party } from "./elements/party"

import { kava } from "./elements/kava"

import { studene_kavove_napoje } from "./elements/studene_kavove_napoje"
import { nealko } from "./elements/nealko"

import { domace_limonady } from "./elements/domace_limonady"
import { studene_napoje } from "./elements/studene_napoje"

import { vino } from "./elements/vino"
import { teple_napoje } from "./elements/teple_napoje"

import { alkoholicke_napoje } from "./elements/alkoholicke_napoje"
import { pivo } from "./elements/pivo"

import { miesane_napoje } from "./elements/miesane_napoje"
import { pochutiny } from "./elements/pochutiny"

import { doplnkovy_sortiment } from "./elements/doplnkovy_sortiment"
import { adresa_wifi } from "./elements/adresa_wifi"

export const mercyCoffeeMenu =  [
  [
    {
      id: "PARTY_ID",
      icon: partyIcon,
      iconWidth: "65px",
      page_uuid: uuid(),
      titleShort: "Akcie",
      title: "Akcie",
      list: party,
      isOpen: false,
    },
  ],
  [
    {
      id: "KAVA_ID",
      icon: coffeeIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Káva",
      title: "Káva",
      list: kava,
      isOpen: false,
    },
    {
      id: "STUDENE_KAVOVE_NAPOJE_ID",
      icon: icedCoffeeIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: "Studené kávové</br>nápoje",
      title: "Studené kávové nápoje",
      list: studene_kavove_napoje,
      isOpen: false,
    },
  ],
  [
    {
      id: "NEALKO_NAPOJE_ID",
      icon: nonalcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Nealkoholické</br>Nápoje",
      title: "Nealkoholické Nápoje",
      list: nealko,
      isOpen: false,
    },
    {
      id: "DOMACE_LIMONADY_ID",
      icon: freshJuiceIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: "Limonády",
      title: "Limonády",
      list: domace_limonady,
      isOpen: false,
    },
    /*  */
  ],
  [
    {
      id: "STUDENE_NAPOJE_ID",
      icon: frappeIcon,
      iconWidth: "70px",
      page_uuid: uuid(),
      titleShort: "Studené</br>Nápoje",
      title: "Studené Nápoje",
      list: studene_napoje,
      isOpen: false,
    },
    {
      id: "TEPLE_NAPOJE_ID",
      icon: teaIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Teplé</br>Nápoje",
      title: "Teplé Nápoje",
      list: teple_napoje,
      isOpen: false,
    },
  ],
  [
    {
      id: "VINO_ID",
      icon: wineIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Víno",
      title: "Víno",
      list: vino,
      isOpen: false,
    },
    {
      id: "ALKO_NAPOJE_ID",
      icon: alcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Alkoholické</br>Nápoje",
      title: "Alkoholické Nápoje",
      list: alkoholicke_napoje,
      isOpen: false,
    },
  ],
  [
    {
      id: "MIESANE_NAPOJE_ID",
      icon: drinkIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Miešané Nápoje",
      title: "Miešané Nápoje",
      list: miesane_napoje,
      isOpen: false,
    },
  ],
  [
    {
      id: "PIVO_ID",
      icon: beerIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Pivo",
      title: "Pivo",
      list: pivo,
      isOpen: false,
    },
    {
      id: "POCHUTINY_ID",
      icon: snacksIcon,
      iconWidth: "40px",
      page_uuid: uuid(),
      titleShort: "Pochutiny",
      title: "Pochutiny",
      list: pochutiny,
      isOpen: false,
    },
  ],
  [
    {
      id: "DOPLNKY_ID",
      icon: tabaqueIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: "Doplnkový sortiment",
      title: "Doplnkový sortiment",
      list: doplnkovy_sortiment,
      isOpen: false,
    },

  ],
  [
    {
      id: "WIFI_ID",
      icon: wifiIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: "Wi-Fi a Adresa",
      title: "Wi-Fi a Adresa",
      list: adresa_wifi,
      isOpen: false,
    },

  ]
]

