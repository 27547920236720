import uuid from "react-uuid"
/* import {
  GoAlert
} from "react-icons/go" */
/* import {
  CiPizza
} from "react-icons/ci" */
import {
  BsPlusCircleFill
} from "react-icons/bs"


export const pizza = [
  {
    item_uuid: uuid(),
    title: "Margherita",
    descr: "paradajkový základ, mozzarella, bazalka, olivový olej (alergény: 1, 7)",
    volume: "",
    price: "8,20 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Šunková",
    descr: "paradajkový základ, syr, šunka, kukurica (alergény: 1, 7)",
    volume: "",
    price: "8,20 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Hawai",
    descr: "paradajkový základ, syr, šunka, ananás (alergény: 1, 7)",
    volume: "",
    price: "8,20 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Salamová",
    descr: "paradajkový základ, syr, saláma, cibuľa,  šampiňóny (alergény: 1, 7)",
    volume: "",
    price: "8,20 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Funghi",
    descr: "paradajkový základ, syr, šunka, kukurica, šampiňóny (alergény: 1, 7)",
    volume: "",
    price: "8,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Hermelínová",
    descr: "paradajkový základ, syr, saláma, hermelín, červená cibuľa, (alergény: 1, 7)",
    volume: "",
    price: "8,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Olivero",
    descr: "paradajkový základ, syr, saláma, olivy, šampiňóny (alergény: 1, 3, 7)",
    volume: "",
    price: "8,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Quatro formaggi",
    descr: "paradajkový alebo smotanový základ, eidam, mozzarella, niva, balkánsky syr (alergény: 1, 7)",
    volume: "",
    price: "9,20 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Liptovská",
    descr: "smotanový základ, syr, bryndza, slanina (alergény: 1, 7)",
    volume: "",
    price: "9,20 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Gazda",
    descr: "paradajkový základ, syr, slanina, klobása, saláma, cibuľa, baranie rohy (alergény: 1, 7)",
    volume: "",
    price: "9,20 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Predná hora",
    descr: "paradajkový základ, syr, slanina, saláma, baranie rohy, feferóny, chilli (alergény: 1, 7)",
    volume: "",
    spicyLevel: 1,
    price: "9,20 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Rukola",
    descr: "paradajkový základ, mozzarella, schwarzwaldská šunka, rukola (alergény: 1, 7)",
    volume: "",
    price: "9,20 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Tuniaková",
    descr: "paradajkový základ, syr, tuniak, cibuľa, olivy (alergény: 1, 3, 4, 7)",
    volume: "",
    price: "9,20 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Pizza chlieb / pizza štangle",
    descr: " ",
    volume: "250 g",
    price: "4,50 €",
    divideAfter: true,
    lastElement: false
  },
  /* {
    item_uuid: uuid(),
    title: "Chilli do hamburgeru",
    descr: "",
    volume: "",
    price: "0,30 €",
    divideAfter: false,
    lastElement: false
  }, */
  {
    type: "extra",
    icon: BsPlusCircleFill,
    iconColor: "#F7C808",
    item_uuid: uuid(),
    title: "Možnosť objednať navyše",
    descr: "kukurica / šampiňóny / cibuľa / ananás / olivy / feferóny / baranie rohy / kyslé uhorky",
    volume: "30 g",
    price: "0,60 €",
    divideAfter: false,
    lastElement: false
  },
  {
    type: "extra",
    icon: BsPlusCircleFill,
    iconColor: "#F7C808",
    item_uuid: uuid(),
    title: "Možnosť objednať navyše",
    descr: "šunka / slanina / saláma / klobása / tuniak / syr / niva / mozzarella / balkánsky syr",
    volume: "30 g",
    price: "1,20 €",
    divideAfter: false,
    lastElement: false
  },
  {
    type: "extra",
    icon: BsPlusCircleFill,
    iconColor: "#F7C808",
    item_uuid: uuid(),
    title: "Chilli naviac",
    descr: "pizza",
    volume: "",
    price: "0,50 €",
    divideAfter: true,
    lastElement: false
  },

  {
    type: "description",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Rozmery a gramáž",
    descr: "• ⌀ 32 cm • 550 g • Chilli - 0,50 € (hamburger,&nbsp;pizza,&nbsp;tortilla)",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Obal na pizzu",
    descr: "",
    volume: "1 ks",
    price: "0,50 €",
    divideAfter: false,
    lastElement: true
  },
]