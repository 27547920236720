const LoadingScreen = () => {
  return (
    <div className="loading_screen">
      <img
        src={require("../../assets/images/logo.jpg")}
        alt="MojeQr | QR kód pre tvoj podnik"
      />
    </div>
  )
}

export default LoadingScreen