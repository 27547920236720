import ContactCard from "../components/cards/ContactCard";
import IntroCard from "../components/cards/IntroCard";
import PricingCard from "../components/cards/PricingCard";
import ClientsCard from "../components/cards/ClientsCard";
import ServiceCard from "../components/cards/ServiceCard";

import { useEffect } from "react"

const HomePage = () => {



  const bgImage = require("../assets/images/new_background.png")
  const bgImageMobile = require("../assets/images/new_background_mobile.png")

  const scrollToTargetAdjusted = (id: string) => {
    setTimeout(() => {
      var element = document.getElementById(id);
      var headerOffset = 50;
      var elementPosition = element!.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });
    }, 1000);
  }

  useEffect(()=> {

    document.body.style.backgroundImage = `url(${bgImage})`;
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";
    /* document.body.style.background = "rgb(56,125,192)";
    document.body.style.background = "linear-gradient(149deg, rgba(56,125,192,1) 0%, rgba(20,175,167,1) 53%, rgba(118,39,235,1) 100%)";
    */
    // document.body.style.backgroundImage = "../../assets/images/layouts.png"

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        document.body.style.backgroundImage = `url(${bgImageMobile})`;
      } else {
        document.body.style.backgroundImage = `url(${bgImage})`;
      }
    };
    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);


    const path = window.location.pathname
    switch (path) {
      //@ts-ignore
      case "/sluzby":
        document.title = `MojeQr - Služby`
        document.getElementsByTagName('meta')[3].content = "So službou MojeQr nezískaš len lepší zážitok pre svojich klientov ale mnoho ďalšieho. Minimálne teda ušetríš finančné a časové výdaje pri tlači."
        scrollToTargetAdjusted("SERVICE_CARD_ID")
        break;
      //@ts-ignore
      case "/klienti":
        document.title = `MojeQr - Klienti`
        document.getElementsByTagName('meta')[3].content = "Podniky ktoré využívajú službu MojeQr si môžeš prezrieť práve tu!"
        scrollToTargetAdjusted("CLIENTS_CARD_ID")
        break;
      //@ts-ignore
      case "/cennik":
        document.title = `MojeQr - Cenník`
        document.getElementsByTagName('meta')[3].content = "Za vyskúšanie nič nedáš, tvojim klientom sa ale táto inovácia celkom iste zapáči."
        scrollToTargetAdjusted("PRICING_CARD_ID")
        break;

      default:
        document.getElementById("INTRO_CARD_ID")?.scrollIntoView({ behavior: "smooth" })
        break;
    }
    // Clean up
    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, [])



  useEffect(() => {
    document.title = `MojeQr | QR kód pre tvoj podnik`
    document.getElementsByTagName('meta')[3].content = `Na ten nápojový lístok čakám už večnosť! Chcel by som najskôr vidieť ako tie drinky so špeciálnym názvom vyzerajú! So službou MojeQR tvojmu zákazníkovi takéto niečo ani nenapadne. Mrkni na náš web a zisti viac. Tiež sa nás neboj kontaktovať na +421 940 850 530 alebo na info@mojeqr.sk`
    document.getElementsByTagName('link')[2].href = "https://www.mojeqr.sk/"
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })

  },[])
  return (
    <div className="new_home_page">
      <IntroCard
        id="INTRO_CARD_ID"
      />
      <ServiceCard
        id="SERVICE_CARD_ID"
      />
      <ClientsCard
        id="CLIENTS_CARD_ID"
      />
      <PricingCard
        id="PRICING_CARD_ID"
      />
      <ContactCard
        id="CONTACT_CARD_ID"
      />
    </div>
  )
}

export default HomePage

