import uuid from "react-uuid"

export const letneMenu = [
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Limonády / Alkonády",
    noDescr: false,
    descr: "",
    volume: "Nealko / Alko",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Grepovička",
    descr: "(citronová šťava, sóda, sirup grep bazalka, grep, gin)",
    /* img: require("path"), */
    volume: "350 ml",
    price: "4,90 € / 6,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Bazovička",
    descr: "(citronová šťava, sóda, sirup baza citrón, citrón, vodka)",
    /* img: require("path"), */
    volume: "350 ml",
    price: "4,90 € / 6,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Rebarborum",
    descr: "(citronová šťava, sóda, sirup rebarbora, jahoda, rum)",
    /* img: require("path"), */
    volume: "350 ml",
    price: "4,90 € / 6,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ananasovica",
    descr: "(citronová šťava, sóda, sirup ananas/mäta, pomaranč, rum)",
    /* img: require("path"), */
    volume: "350 ml",
    price: "4,90 € / 6,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jahodovica",
    descr: "(citronová šťava, sóda, sirup jahoda/jazmín, jahody, vodka)",
    /* img: require("path"), */
    volume: "350 ml",
    price: "4,90 € / 6,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pomarančovica",
    descr: "(citronová šťava, sóda, sirup pomaranč, pomaranč, gin)",
    /* img: require("path"), */
    volume: "350 ml",
    price: "4,90 € / 6,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Berry sunset",
    descr: "(berry cordial, vodka, sóda, malina)",
    /* img: require("path"), */
    volume: "230 ml",
    price: "4,90 € / 6,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Ľadový čaj / Alko Ľadový čaj",
    noDescr: true,
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Mango čierny čaj",
    descr: "(mango čierny čaj, sóda, citronová šťava,rum, pomaranč)",
    /* img: require("path"), */
    volume: "350 ml",
    price: "4,90 € / 6,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Egreš kamilka",
    descr: "(egreš kamilka, citronová štava, sóda,  vodka, citrón)",
    /* img: require("path"), */
    volume: "350 ml",
    price: "4,90 € / 6,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Materina dúška-medovka",
    descr: "(materina duška/medovka, sóda, citronová štava,gin)",
    /* img: require("path"), */
    volume: "350 ml",
    price: "4,90 € / 6,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "GIN TONIC",
    noDescr: true,
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Grepový gin tonic",
    descr: "(sirup grep bazalka, tonic, gin, grep)",
    /* img: require("path"), */
    volume: "175 ml",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Bazový gin tonic",
    descr: "(sirup baza citrón, tonic, gin, citrón)",
    /* img: require("path"), */
    volume: "175 ml",
    price: "4,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Nealko aperitívne drinky",
    noDescr: true,
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Nochino tonic",
    descr: "(nochino ruby, tonic, malina)",
    /* img: require("path"), */
    volume: "140 ml",
    price: "4,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Nochino sóda",
    descr: "(nochino ruby, sóda, malina)",
    /* img: require("path"), */
    volume: "140 ml",
    price: "4,00 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Wolf Pro Cocktails",
    noDescr: true,
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Fajn-Šmeker",
    descr: "(jameson, sirup ananás/mäta, citronová šťava, prosseco, mäta)",
    /* img: require("path"), */
    volume: "140 ml",
    price: "4,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Irish sour",
    descr: "(jameson, sirup levanduľa-arónia, červené víno, citronová šťava, pomaranč)",
    /* img: require("path"), */
    volume: "100 ml",
    price: "4,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Daiquri for you",
    descr: "(rum, sirup citrusgang, citronová šťava, limetka)",
    /* img: require("path"), */
    volume: "100 ml",
    price: "4,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kubanská noc",
    descr: "(rum, cola, limetka)",
    /* img: require("path"), */
    volume: "140 ml",
    price: "4,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Spritz",
    noDescr: true,
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Grosecco",
    descr: "(sirup grep bazalka, gin, prosecco, citronová šťava, grep)",
    /* img: require("path"), */
    volume: "140 ml",
    price: "4,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Vanlove",
    descr: "(sirup levanduľa-arónia, gin, presecco, jahoda)",
    /* img: require("path"), */
    volume: "140 ml",
    price: "4,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pink lady",
    descr: "(sirup rebarbora, gin, prosecco, citronová štava, jahoda)",
    /* img: require("path"), */
    volume: "140 ml",
    price: "4,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Obývačka menu",
    noDescr: true,
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Citronáda",
    descr: "(sóda, citronová šťava, citrón)",
    /* img: require("path"), */
    volume: "1,2 l",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ovocináda",
    descr: "(sóda, citronová šťava, pomaranč, grep)",
    /* img: require("path"), */
    volume: "1,2 l",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Proteinoný nápoj",
    descr: "(mlieko, proteín)",
    /* img: require("path"), */
    volume: "250 ml",
    price: "2,50 €",
    divideAfter: false
  }
  /*
  {
    item_uuid: uuid(),
    title: "Perníkové cappucino",
    descr: "",
    img: require("../../../assets/images/clients/items/ObyvackaBar/zimne_menu/pernikove-cappucino.jpeg"),
    volume: "250 ml",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Perníkové  latte machiato",
    descr: "",
    img: require("../../../assets/images/clients/items/ObyvackaBar/zimne_menu/pernikove-latte-machiato.jpeg"),
    volume: "250 ml",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Čaj z čerstvej mäty",
    descr: "",
    volume: "300 ml",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Horúca čokoláda so šľahačkou",
    descr: "mliečna, horká, biela",
    volume: "150 ml",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Granko ako od babičky",
    img: require("../../../assets/images/clients/items/ObyvackaBar/zimne_menu/granko-od-babicky.jpeg"),
    descr: "",
    volume: "250 ml",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Čaj z čerstvej mäty zázvoru, citrónu a medu",
    descr: "",
    volume: "300 ml",
    price: "2,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Teplé drinky",
    noDescr: false,
    descr: "Nealko / Alko",
    volume: "",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Grep bazalka",
    descr: "sirup-grep bazalka, citrónová šťava, voda, grep, gin",
    img: require("../../../assets/images/clients/items/ObyvackaBar/zimne_menu/grep-bazalka.jpeg"),
    volume: "300 ml",
    price: "4,50 € / 6,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Baza spicy",
    descr: "sirup-baza citrón a spicy, voda, citrónová šťava, vodka, citrón, škorica",
    volume: "300 ml",
    price: "4,50 € / 6,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Korenistý pomaranč",
    descr: "sirup-pomaranč a 5 druhov korenia, voda, citrónová šťava, gin, pomaranč/škorica",
    volume: "300 ml",
    price: "4,50 € / 6,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Marhuľa-zázvor",
    descr: "sirup-marhuľa zázvor, voda, citrónová šťava, gin, pomaranč, zázvor",
    img: require("../../../assets/images/clients/items/ObyvackaBar/zimne_menu/marhula-zazvor.jpeg"),
    volume: "300 ml",
    price: "4,50 € / 6,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Wolf Shots",
    noDescr: true,
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Grep shot",
    descr: "sirup grep bazalka, gin, citrónová šťava",
    volume: "50 ml",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "A-prison shot",
    descr: "sirup marhuľa-zázvor, Jameson, citrónová šťava",
    volume: "50 ml",
    price: "2,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Punče a Grogy",
    noDescr: true,
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Tekutý sveter",
    descr: "sirup- citrusgang, spicy, červené víno, rum, citrónová šťava, horúca voda, pomaranč, grep, škorica",
    volume: "350 ml",
    price: "6,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Vianočný punč",
    descr: "sirup-baza citrón, becherovka, červené víno, citrónová šťava, voda, grep, škorica",
    volume: "300 ml",
    price: "6,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hot ginger bread punch",
    descr: "ginger shot med + citrón, citrón sirup, horúca voda, rum, zázvor, škorica",
    volume: "235 ml",
    price: "6,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Nealko detský punč",
    descr: "jablkový džús, sirup baza-citrón a spicy, citrónová šťava, voda, škorica",
    volume: "225 ml",
    price: "4,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Varené Vína",
    noDescr: true,
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Varené červené víno",
    descr: "sirup spicy, červené víno, škorica, pomaranč",
    volume: "175 ml",
    price: "3,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Varené biele víno",
    descr: "sirup spicy, biele víno, škorica, pomaranč",
    volume: "175 ml",
    price: "3,00 €",
    divideAfter: false
  }, */
]