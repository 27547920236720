import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
import recommended_flag from "../../../assets/images/clients/icons/panorama/recommended_flag.png"
import new_flag from "../../../assets/images/clients/icons/panorama/new_flag.png"

export const miesane_napoje = [
  /* {
    item_uuid: uuid(),
    title: "VIRGIN MOJITO",
    descr: "/&nbspčerstvé limetky a mäta, mint sirup, trstinový cukor, sóda&nbsp/",
    volume: "",
    price: "4,10 €",
    divideAfter: false
  }, */
  /* {
    item_uuid: uuid(),
    title: "MOJITO",
    descr: "/&nbsp0,04 l Havana Club Añejo 3 Años, limetka, mäta, trstinový cukor, sóda&nbsp/",
    volume: "",
    price: "4,50 €",
    divideAfter: false
  }, */
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Spritz",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Hugitto",
    descr: "/&nbspbaza-citrón sirup, Lúčky Gin, prosecco&nbsp/",
    volume: "",
    price: "4,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Grosecco",
    descr: "/&nbspgrep-bazalka sirup, Lúčky Gin, prosecco&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flag: recommended_flag,
    price: "4,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Aperol spritz",
    descr: "/&nbspAperol, prosecco, sóda&nbsp/",
    volume: "",
    price: "4,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Lavender",
    descr: "/&nbsplevanduľa-arónia sirup, Beefeater Pink Strawberry Gin, prosecco&nbsp/",
    volume: "",
    price: "4,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Sexi RUBY",
    descr: "/&nbspNochino Ruby (nealko aperitív), prosecco&nbsp/",
    volume: "",
    price: "4,90 €",
    flagBehindTitle: true,
    flag: new_flag,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "French 75",
    descr: "/&nbspTanqueray Blackcurrant Royale, simple sirup, citrónová šťava, prossecco&nbsp/",
    volume: "",
    price: "5,10 €",
    flagBehindTitle: true,
    flag: new_flag,
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "COCTAILS SELECTION",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "JASMINE",
    descr: "/&nbspLúčky Gin, jazmínový cordial, sóda&nbsp/",
    volume: "",
    price: "4,80 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/jasmine.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "NEGRONI",
    descr: "/&nbspLúčky Gin, Cinzano Rosso, Campari&nbsp/",
    volume: "",
    price: "4,80 €",
    flagBehindTitle: true,
    flag: new_flag,
    divideAfter: false
  },
  /* {
    item_uuid: uuid(),
    title: "BASIL SMASH",
    descr: "/&nbspLúčky Gin, bazalka, simple sirup&nbsp/",
    volume: "",
    price: "4,50 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/basil-smash.jpg"),
    divideAfter: false
  }, */
  {
    item_uuid: uuid(),
    title: "SUGAR DADDY",
    descr: "/&nbspLúčky Gin, ríbezľový sirup, vaječný bielok&nbsp/",
    volume: "",
    price: "6,50 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/sugar-daddy.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "ROSSA",
    descr: "/&nbspMalfy Gin Rosa, San Pellegrino Aranciata Rossa, grep&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flag: recommended_flag,
    price: "5,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "MOJITO",
    descr: "/&nbspHavana Club Añejo  3 Años, čerstvé limetky a mäta, trstinový cukor, sóda&nbsp/",
    volume: "",
    price: "5,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "APEROL DAIQUIRI",
    descr: "/&nbspHavana Club Añejo 3 Años, ananásová šťava, simple sirup&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flag: recommended_flag,
    price: "5,60 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/aperol-daiquiri.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "SUNSHINE",
    descr: "/&nbspAbsolut vodka, mango pyré, vanilla sirup, vinea biela, sóda&nbsp/",
    volume: "",
    price: "5,90 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/sunshine.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "PORNSTAR MARTINI",
    descr: "/&nbspAbsolut Vanilla vodka, vanilla sirup, passionfruit pyré&nbsp/",
    volume: "",
    price: "6,10 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/pornstar-martini.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "VERY BERRY",
    descr: "/&nbspAbsolut vodka, berry cordial, sóda&nbsp/",
    volume: "",
    price: "4,80 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/very-berry.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "ESPRESSO MARTINI",
    descr: "/&nbspAbsolut vodka, Kahlúa, espresso, simple sirup&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flag: recommended_flag,
    price: "5,90 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/espresso-martini.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "JAMESIE",
    descr: "/&nbspJameson, levanduľa-arónia sirup, červené víno, citrónová šťava&nbsp/",
    volume: "",
    price: "4,80 €",
    flagBehindTitle: true,
    flag: new_flag,
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: ",Drivers‘ Drinks",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
  },

  {
    item_uuid: uuid(),
    title: "VIRGIN MOJITO",
    descr: "/&nbspčerstvé limetky a mäta, Mint sirup, trstinový cukor, sóda&nbsp/",
    volume: "",
    price: "4,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "VIRGIN SUNSHINE",
    descr: "/&nbspmango pyré, vanilla sirup, vinea biela, sóda&nbsp/",
    volume: "",
    price: "4,40 €",
    flagBehindTitle: true,
    flag: recommended_flag,
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/sunshine.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "OCEAN",
    descr: "/&nbspCuracao Blue sirup, vinea biela, motýlí čaj&nbsp/",
    volume: "",
    price: "4,40 €",
    img: require("../../../assets/images/clients/items/Panorama/miesane_napoje/ocean.jpg"),
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "PASSIONSTAR MARTINI",
    descr: "/&nbspvanilla sirup, passionfruit pyré, ananásová šťava, sóda&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flagHeight: "16px",
    flag: new_flag,
    price: "4,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "RUBY",
    descr: "/&nbspNochino Ruby (nealko aperitív), tonic&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flag: new_flag,
    flagHeight: "16px",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "BOTANICAL",
    descr: "/&nbspNochino Botanic (nealko aperitív), tonic&nbsp/",
    volume: "",
    flagBehindTitle: true,
    flagHeight: "16px",
    flag: new_flag,
    price: "4,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
]