import uuid from "react-uuid"
import premium_flag from "../../../assets/images/clients/icons/obyvacka_bar/premium_flag.png"
import recommended_flag from "../../../assets/images/clients/icons/obyvacka_bar/recommended_flag.png"

export const vodka = [
  {
    item_uuid: uuid(),
    title: "Vodka Ruský štandard",
    descr: "38 %",
    volume: "0,04 l",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Vodka Koskenkorva",
    descr: "40 %",
    volume: "0,04 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Finlandia",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Vodka Absolut 100",
    descr: "50 %",
    flagBehindTitle: true,
    flag: premium_flag,
    flagHeight: "25px",
    volume: "0,04 l",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Vodka Absolut",
    descr: "40 %",
    flagBehindTitle: true,
    flag: recommended_flag,
    flagHeight: "25px",
    volume: "0,04 l",
    price: "1,90 €",
    divideAfter: false
  },
]