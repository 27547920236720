import MoreContactCard from "../cards/MoreContactCard";

const Contact = () => {


  return (
    <div className="new_home_page">
      <MoreContactCard
        id="MORE_CONTACT_CARD_ID"
      />
    </div>
  )
}

export default Contact;

