import uuid from "react-uuid"
import premium_flag from "../../../assets/images/clients/icons/obyvacka_bar/premium_flag.png"
import recommended_flag from "../../../assets/images/clients/icons/obyvacka_bar/recommended_flag.png"
import nealko_flag from "../../../assets/images/clients/icons/obyvacka_bar/nealko_flag.png"
import vegan_flag from "../../../assets/images/clients/icons/obyvacka_bar/vegan_flag.png"


export const tequilaGin = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Tequila",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Tequila Olmeca Gold",
    descr: "35 %",
    volume: "0,04 l",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tequila Olmeca Silver",
    descr: "35 %",
    volume: "0,04 l",
    price: "2,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Gin",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Gin Kensington pink",
    descr: "37,5 %",
    volume: "0,04 l",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Gin Kensington",
    descr: "35 %",
    volume: "0,04 l",
    price: "2,10 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Gin Beefeater",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Gin Beefeater Pink",
    descr: "37,5 %",
    volume: "0,04 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Gin Beefeater Peach",
    descr: "37,5 %",
    volume: "0,04 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Gin Beefeater Orange",
    descr: "37,5 %",
    volume: "0,04 l",
    price: "2,20 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Gin Berkshire",
    descr: "40,3 %",
    flagBehindTitle: false,
    flag: [premium_flag, vegan_flag],
    flagHeight: ["25px", "20px"],
    volume: "0,04 l",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Gin Berkshire čerešňa",
    descr: "40,3 %",
    flagBehindTitle: false,
    flag: [premium_flag, vegan_flag],
    flagHeight: ["25px", "20px"],
    volume: "0,04 l",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Gin Berkshire malina",
    descr: "40,3 %",
    flagBehindTitle: false,
    flag: [premium_flag, vegan_flag],
    flagHeight: ["25px", "20px"],
    volume: "0,04 l",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Gin Berkshire med a pomarančový kvet",
    descr: "40,3 %",
    flagBehindTitle: false,
    flag: [premium_flag, vegan_flag],
    flagHeight: ["25px", "20px"],
    volume: "0,04 l",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Gin Berkshire púpava a lopúch",
    descr: "40,3 %",
    flagBehindTitle: false,
    flag: [premium_flag, vegan_flag],
    flagHeight: ["25px", "20px"],
    volume: "0,04 l",
    price: "3,90 €",
    divideAfter: true
  },
  /* {
    item_uuid: uuid(),
    title: "Gin Warner's pink berry ružový",
    descr: "0 %",
    flagBehindTitle: true,
    flag: nealko_flag,
    flagHeight: "22px",
    volume: "0,04 l",
    price: "2,90 €",
    divideAfter: false
  }, */
  /* {
    item_uuid: uuid(),
    title: "Gin Warner's juniperdouble dry",
    descr: "0 %",
    flagBehindTitle: true,
    flag: nealko_flag,
    flagHeight: "22px",
    volume: "0,04 l",
    price: "2,90 €",
    divideAfter: true
  }, */
  {
    item_uuid: uuid(),
    title: "Malfy Gin Rosa",
    descr: "41 %",
    flagBehindTitle: true,
    flag: recommended_flag,
    flagHeight: "25px",
    volume: "0,04 l",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Malfy Gin Originale",
    descr: "41 %",
    volume: "0,04 l",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Malfy Gin Con Arancia",
    descr: "41 %",
    flagBehindTitle: true,
    flag: recommended_flag,
    flagHeight: "25px",
    volume: "0,04 l",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Malfy Gin Limone",
    descr: "41 %",
    volume: "0,04 l",
    price: "3,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "",
    descr: "Pri kúpe 2x prémiového ginu Berkshire dostanete Tonic 200ml ZADARMO",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: true,
    lastElement: false
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "",
    descr: "V kombinácií Beefeater Gin + Tonic dostanete JEDLÚ SLAMKU",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: true,
    lastElement: false
  },
]