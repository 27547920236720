//@ts-nocheck
import uuid from "react-uuid"

import coffeeIcon from "../../assets/images/clients/icons/panorama/coffee-cup.svg"
import teaIcon from "../../assets/images/clients/icons/panorama/tea.svg"
import lemonadeIcon from "../../assets/images/clients/icons/panorama/lemonade.svg"
import nonalcoIcon from "../../assets/images/clients/icons/panorama/drink.svg"
import beerIcon from "../../assets/images/clients/icons/panorama/beer.svg"
import snacksIcon from "../../assets/images/clients/icons/panorama/snack.svg"
import wineIcon from "../../assets/images/clients/icons/panorama/glass-with-wine.svg"
import drinkIcon from "../../assets/images/clients/icons/panorama/cocktail.svg"
import tabaqueIcon from "../../assets/images/clients/icons/panorama/tabaque.svg"
import alcoIcon from "../../assets/images/clients/icons/panorama/shots.svg"
import icedCoffeeIcon from "../../assets/images/clients/icons/jazz_cafe/iced_coffee.svg"
import hotChocolateIcon from "../../assets/images/clients/icons/jazz_cafe/hot_chocolate.svg"
import frappeIcon from "../../assets/images/clients/icons/jazz_cafe/frappe.svg"
import freshJuiceIcon  from "../../assets/images/clients/icons/jazz_cafe/fresh_juice.svg"

import { pivo } from "./elements/pivo"
import { vino } from "./elements/vino"

import { kava } from "./elements/kava"
import { caj } from "./elements/caj"

import { alkoholicke_napoje } from "./elements/alkoholicke_napoje"
import { horuce_napoje } from "./elements/horuce_napoje"

import { ladova_kava } from "./elements/ladova_kava"
import { horuca_cokolada } from "./elements/horuca_cokolada"

import { ladove_kremy_a_frappe } from "./elements/ladove_kremy_a_frappe"

import { domace_limonady } from "./elements/domace_limonady"
import { cerstve_stavy } from "./elements/cerstve_stavy"

import { miesane_napoje } from "./elements/miesane_napoje"
import { nealko } from "./elements/nealko"

import { tabak } from "./elements/tabak"
import { pochutiny } from "./elements/pochutiny"

export const jazzCafeMenu =  [
  [
    {
      id: "PIVO_ID",
      icon: beerIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Pivo",
      title: "Pivo",
      list: pivo,
      isOpen: false,
    },
    {
      id: "VINO_ID",
      icon: wineIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Víno",
      title: "Víno",
      list: vino,
      isOpen: false,
    },
  ],
  [
    {
      id: "KAVA_ID",
      icon: coffeeIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Káva",
      title: "Káva",
      list: kava,
      isOpen: false,
    },
    {
      id: "CAJ_ID",
      icon: teaIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Čaj",
      title: "Čaj",
      list: caj,
      isOpen: false,
    },
  ],
  [
    {
      id: "ALKO_NAPOJE_ID",
      icon: alcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Alkoholické</br>Nápoje",
      title: "Alkoholické Nápoje",
      list: alkoholicke_napoje,
      isOpen: false,
    },
    {
      id: "HORUCE_NAPOJE_ID",
      icon: coffeeIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Horúce</br>Nápoje",
      title: "Horúce Nápoje",
      list: horuce_napoje,
      isOpen: false,
    },
  ],
  [
    {
      id: "KAVA_ID",
      icon: icedCoffeeIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: "Ľadová káva",
      title: "Ľadová káva",
      list: ladova_kava,
      isOpen: false,
    },
    {
      id: "HORUCA_COKOLADA_ID",
      icon: hotChocolateIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: "Horúca Čokoláda",
      title: "Horúca Čokoláda",
      list: horuca_cokolada,
      isOpen: false,
    },
  ],
  [
    {
      id: "LADOVE_KREMY_A_FRAPE_ID",
      icon: frappeIcon,
      iconWidth: "70px",
      page_uuid: uuid(),
      titleShort: "Ľadové Krémy a Frappé",
      title: "Ľadové Krémy a Frappé",
      list: ladove_kremy_a_frappe,
      isOpen: false,
    },
  ],
  [
    {
      id: "DOMACE_LIMONADY_ID",
      icon: lemonadeIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: "Domáce Limonády",
      title: "Domáce Limonády",
      list: domace_limonady,
      isOpen: false,
    },
    {
      id: "CERSTVE_STAVY_ID",
      icon: freshJuiceIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "100% Čerstvé</br>Šťavy",
      title: "100% Čerstvé Šťavy",
      list: cerstve_stavy,
      isOpen: false,
    },
  ],
  [
    {
      id: "MIESANE_NAPOJE_ID",
      icon: drinkIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Miešané</br>Nápoje",
      title: "Miešané Nápoje",
      list: miesane_napoje,
      isOpen: false,
    },
    {
      id: "NEALKO_NAPOJE_ID",
      icon: nonalcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Nealkoholické</br>Nápoje",
      title: "Nealkoholické Nápoje",
      list: nealko,
      isOpen: false,
    },
  ],
  [
    {
      id: "CIGARETY_ID",
      icon: tabaqueIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: "Cigarety",
      title: "Cigarety",
      list: tabak,
      isOpen: false,
    },
    {
      id: "POCHUTINY_ID",
      icon: snacksIcon,
      iconWidth: "40px",
      page_uuid: uuid(),
      titleShort: "Pochutiny",
      title: "Pochutiny",
      list: pochutiny,
      isOpen: false,
    },
  ]
]

