// @ts-nocheck

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translations for different languages
import translationEN from '../locales/en';
import translationSK from '../locales/sk';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      sk: {
        translation: translationSK,
      },
    },
    lng: 'sk',
    fallbackLng: 'sk',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator'],
      lookupNavigator: true,
    },
  });

export default i18n;