//@ts-nocheck
import { createContext, useContext, useEffect, useState } from 'react';
import authService from '../services/authService';
import { useCookies } from 'react-cookie';
import { LoggedUserType } from '../PropTypes/AdminDashboardProps';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);

  const [cookie, setCookie] = useCookies(['mojeqr_access_token', 'mojeqr_refresh_token'])

  const [user, setUser] = useState<LoggedUserType>(null)

  const login = (email, password, callback) => {
    authService.login(email, password, async (access,refresh, setPending) => {
      setCookie("mojeqr_access_token", access, {path:'/'})
      setCookie("mojeqr_refresh_token", refresh, {path:'/'})
      callback(setPending)
    })
  };

  const logout = () => {
    authService.logout(() =>{
      setCookie("mojeqr_access_token", "",  {path:"/"})
      setCookie("mojeqr_refresh_token", "",  {path:"/"})
    })

  };

  useEffect(() => {

    if (cookie.mojeqr_access_token) {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }

  },[cookie.mojeqr_access_token])


  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, setLoggedIn, user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
