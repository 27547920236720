import uuid from "react-uuid"
import premium_flag from "../../../assets/images/clients/icons/obyvacka_bar/premium_flag.png"
import recommended_flag from "../../../assets/images/clients/icons/obyvacka_bar/recommended_flag.png"

export const brandyWhiskyKonak = [
  {
    item_uuid: uuid(),
    title: "Tullamore Dew",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jameson",
    descr: "40 %",
    volume: "0,04 l",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jameson Orange",
    descr: "30 %",
    volume: "0,04 l",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jack Daniels",
    descr: "40 %",
    volume: "0,04 l",
    price: "3,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jack Daniels Honey",
    descr: "35 %",
    volume: "0,04 l",
    price: "3,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jack Daniels Fire",
    descr: "35 %",
    volume: "0,04 l",
    price: "3,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jack Daniels Apple",
    descr: "35 %",
    volume: "0,04 l",
    price: "3,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jack Daniels gentleman Jack",
    descr: "40 %",
    volume: "0,04 l",
    price: "4,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "The Deacon",
    descr: "40 %",
    flagBehindTitle: false,
    flag: [premium_flag, recommended_flag],
    flagHeight: ["25px", "22px"],
    volume: "0,04 l",
    price: "3,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Chivas Regal 12y",
    descr: "40 %",
    volume: "0,04 l",
    price: "3,30 €",
    divideAfter: false
  },
]