import uuid from "react-uuid"
import {
  TbSoup
} from "react-icons/tb"
import {
  GiMeal
} from "react-icons/gi"

export const jedla = [
  {
    type: "title",
    icon: TbSoup,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Polievky",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Domáci vývar",
    descr: "(alergény: 1, 3, 7, 9)",
    volume: "0,33 l",
    price: "2,00 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Cesnaková so syrom a krutónmi",
    descr: "(alergény: 1, 3, 7, 9)",
    volume: "0,33 l",
    price: "2,50 €",
    divideAfter: true,
    lastElement: false
  },
  {
    type: "title",
    icon: GiMeal,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Hlavné jedlá",
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Bravčové rebrá",
    descr: "baranie rohy, feferónka, horčica, chlieb 3 ks (alergény: 1, 7, 10)",
    volume: "450 g",
    price: "10,50 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Bravčový rezeň so zemiakovou kašou a kapustovým šalátom",
    descr: "(alergény: 1, 3, 7)",
    volume: "130 g",
    price: "8,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Kuracie krídelká s hranolkami, dresing - BBQ",
    descr: "(alergény: 1, 3, 7, 10)",
    volume: "250 g",
    price: "8,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Pivársky hovädzí guláš s knedľou a červenou cibuľkou",
    descr: "(alergény: 1, 3, 7, 10)",
    volume: "150 g",
    price: "9,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Udené bravčové koleno",
    descr: "baraní roh, feferónka, horčica, kyslá uhorka, chlieb (alergény: 1, 7, 10)",
    volume: "700 g",
    price: "11,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Ovarové bravčové koleno",
    descr: "baraní roh, feferónka, horčica, kyslá uhorka, chlieb (alergény: 1, 7, 10) <br />nad 1 kg účtujeme doblok - +100g / 1,00 €",
    volume: "1 kg",
    price: "13,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Trhané bravčové mäso s hranolkami",
    descr: "cheddar, dressing BBQ, dressing cesnak",
    volume: "150 g",
    price: "8,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Pirohy - zemiakovo-bryndzové",
    descr: "cibuľka, slanina a smotana (alergény: 1, 3, 7)",
    volume: "300 g",
    price: "8,50 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Bryndzové halušky so slaninou",
    descr: "(alergény: 1, 3, 7)",
    volume: "250 g",
    price: "8,50 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Vyprážaný syr, hranolky, tatárska omáčka",
    descr: "(alergény: 1, 3, 7)",
    volume: "120 g",
    price: "8,50 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Vyprážaný syr so šunkou, hranolky, tatárska omáčka",
    descr: "(alergény: 1, 3, 7)",
    volume: "130 g",
    price: "8,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Vyprážaný hermelín, hranolky, tatárska omáčka",
    descr: "(alergény: 1, 3, 7)",
    volume: "110 g",
    price: "8,50 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Zemiakové placky s kyslou smotanou",
    descr: "(alergény: 1, 3, 7)",
    volume: "4 ks",
    price: "5,90 €",
    divideAfter: false,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Palacinky s nutellou a šlahačkou",
    descr: "(alergény: 1, 3, 7)",
    volume: "3 ks",
    price: "6,50 €",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Obal na jedlo",
    descr: "",
    volume: "1 ks",
    price: "0,60 €",
    divideAfter: false,
    lastElement: true
  },
]