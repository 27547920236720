import { useTranslation } from "react-i18next"
import {
  phone,
  email,
  address,
  owner,
  ico,
  dic,
  instagramLink,
  facebookLink
} from "../../config/contact"
import {
  BsInstagram
} from "react-icons/bs"
import {
  ImFacebook2
} from "react-icons/im"

interface LocalProps {
  id: string
}

const MoreContactCard = (props: LocalProps) => {
  const { t } = useTranslation()

  /* const phone = "+421 940 850 530"
  const email = "info@mojeqr.sk" */

 /*  const address = "Slovenské Krivé 50, 06713 Rokytov pri Humennom"
  const owner = "Tomáš Meždej"
  const ico = "55 354 246"
  const dic = "1128094198" */

  return (
    <div
      id={ props.id }
      className="moreContactCard"
    >
      <div>
        <div>
          <h1>
            { t("moreContactCard.contact") }
          </h1>
          <h2>
            <a href={`mailto:${email}`} className="email_me_identifier">
              { email }
            </a>
          </h2>
          <h2>
            <a href={`tel:${phone}`} className="call_me_identifier">
              { phone }
            </a>
          </h2>
        </div>
        <div className="socials">
          <BsInstagram
            className="icon icon_instagram"
            size={"40px"}
            onClick={() => {window.open(instagramLink, '_blank')}}
          />
          <ImFacebook2
            className="icon icon_facebook"
            size={"40px"}
            onClick={() => {window.open(facebookLink, '_blank')}}
          />
        </div>
      </div>
      <div>
        <div>
          <h1>
            { t("moreContactCard.owner") }
          </h1>
          <h2>
            { owner }
          </h2>
          <h2>
            IČO: { ico }
          </h2>
          <h2>
            DIČ: { dic }
          </h2>
          <h2>
            { address }
          </h2>
        </div>
      </div>
      {/* <div className="contactCard__contact_wrap">
        <h2>
          { t("contactCard.callMe") }
          <br />
          <a href={`tel:${phone}`}>
            { phone }
          </a>
        </h2>

        <h2>
          { t("contactCard.or") }
        </h2>

        <h2>
          { t("contactCard.textMe") }
          <br />
          <a href={`mailto:${email}`}>
            { email }
          </a>
        </h2>

        <h2
          className="contactCard__more_button"
          onClick={handleMoreClick}
        >
          <Link to="/kontakt">{ t("contactCard.more") }</Link>

        </h2>
      </div> */}
    </div>
  )
}

export default MoreContactCard