import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const horuce_napoje = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Nealkoholické",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Horúci džús Toma - Jablko",
    descr: "",
    volume: "25 cl",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Horúci džús Toma - Jahoda",
    descr: "",
    volume: "25 cl",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Horúci džús Toma - Pomaranč",
    descr: "",
    volume: "25 cl",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Horúci džús Toma - Hruška",
    descr: "",
    volume: "25 cl",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Horúci džús Toma - Ríbezľa",
    descr: "",
    volume: "25 cl",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Horúci džús Toma - Broskyňa",
    descr: "",
    volume: "25 cl",
    price: "2,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Alkoholické",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Varené víno - červené",
    descr: "",
    volume: "20 cl",
    price: "3,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Varené víno - biele",
    descr: "",
    volume: "20 cl",
    price: "3,40 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]