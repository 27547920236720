import { IoMdArrowRoundBack } from "react-icons/io"
import { panoramaMenu } from "../../content/Panorama/menu"

//@ts-ignore
/* import recommendedIcon from "../../assets/images/clients/icons/panorama/cocktail.svg"*/
export const panoramaConfig = {

  isRestaurant: false,
  menuUpdating: false,
  menu: panoramaMenu,
  logo: require("../../assets/images/clients/logos/panorama.jpg"),
  /* logo: require("../../assets/images/clients/logos/christmas/panorama_christmas.jpg"), */


  // recommended modal setup
  recommendedBlockBGColor: "#1F2A2F",
  recommendedBlockTextColor: "white",
  recommendedObject: null,
  /* recommendedObject: {
    icon: recommendedIcon,
    item: "APEROL DAIQUIRI",
    price: "5,50 €",
    sectionId: "MIESANE_NAPOJE_ID"
  }, */
  // path to client logo
  /* logo: "panorama.jpg", */
  // after click on section screen scrolls to that section
  autoscrollAfterClick: false,
  scrollToTopAferOpen: true,
  // left alignment if displayed in rows
  alignTitleToLeft: false,
  //scroll time of scroll to section
  autoscrollTimeInMs: 100,
  //scroll to top of page after closing section
  scrollToTopAfterClose: true,
  //icon that appears in corner when section is opened
  hideAllIcon: IoMdArrowRoundBack,
  //decides if other sections should dissapear when section is opened
  hideAllWhenOneOpen: true,
  //vibrate on click on section
  vibrateOnClick: false,
  // close previous section when new section is opened
  closePreviousSectionOn: true,
  //margin top to display all sections
  displayAllOnPhone: false,
  //contact icon color
  iconColor: "white", // default - #1F2A2F
  //links to social sites
  facebook: "https://www.facebook.com/panoramahe",
  instagram: "https://www.instagram.com/panoramahe/",
  email: "panoramatriada@gmail.com",
  /* phone: "+421 911 299 111" */

  //styles
  mainWrapClass: "panorama",/*
  logoBorderRadius: "8px",
  background: "#CFCAC4",
  blockBackgroundColor: "#878787",
  dividerColor: "#F7C808", */


}
