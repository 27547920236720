import { useTranslation } from "react-i18next"

interface LocalProps {
  id: string
}

const BlogCard = (props: LocalProps) => {

  return (
    <div
      id={ props.id }
      className="blogCard"
    >
      <h1 className="news_room_title">Newsroom</h1>
      <div className="latest_news_wrap">

        <div className="latest_news_card">
          <div className="row">
            <div className="col_1">
              <div className="top_content">
                <span className="topic">topic</span>
                <span className="title">
                  Behind our industry leading acceptance rates
                </span>
                <span className="meta_desr">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum atque cumque libero alias laborum dolor incidunt eligendi voluptate molestiae accusantium! Ad dolorem odio nobis, fugiat non quas alias ex voluptatem!
                </span>
              </div>
              <div className="bottom_content">
                <span className="date">
                  March 17, 2023
                </span>
              </div>
            </div>
            <div className="col_2">
              image here
            </div>
          </div>
        </div>
      </div>

      <div className="action_bar_wrap">
        action_bar
      </div>

      <div className="blog_list_wrap_wrap">
        blog_list_wrap
      </div>

    </div>
  )
}

export default BlogCard