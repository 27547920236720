

const NotAvailableComponent = (props: { text: string}) => {
  return (
    <div className="not_available">
      <img
        src={require("../../assets/images/under_construction.png")}
        alt={props.text}
      />
      <div className="title">
        { props.text}
      </div>
    </div>
  )
}

export default NotAvailableComponent