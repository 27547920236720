import uuid from "react-uuid"

export const nealko = [
  {
    item_uuid: uuid(),
    title: "Coca-cola",
    descr: "",
    volume: "200 ml",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Coca-cola ZERO",
    descr: "",
    volume: "200 ml",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fanta pomaranč",
    descr: "",
    volume: "200 ml",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Sprite",
    descr: "",
    volume: "200 ml",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Džús Cappy",
    descr: "podľa ponuky",
    volume: "250 ml",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kinley Tonic",
    descr: "podľa ponuky",
    volume: "250 ml",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fuze tea",
    descr: "podľa ponuky",
    volume: "250 ml",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Romerquelle",
    descr: "Lemongrass",
    volume: "330 ml",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Natura",
    descr: "sýtená / jemne sýtená / nesýtená",
    volume: "300 ml",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Red bull",
    descr: "plechovka",
    volume: "250 ml",
    price: "3,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Džbán s vodou a ovocím",
    descr: "",
    volume: "1 l",
    price: "3,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Vratná plechovka",
    descr: "",
    volume: "",
    price: "0,15 €",
    divideAfter: false
  },
]