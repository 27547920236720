import uuid from "react-uuid"

export const destilaty = [
  {
    item_uuid: uuid(),
    title: "Slivovica destilát",
    descr: "52 %",
    volume: "0,04 l",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Spišská borovička",
    descr: "40 %",
    volume: "0,04 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jablkovica destilát",
    descr: "42 %",
    volume: "0,04 l",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Marhuľovica destilát",
    descr: "42 %",
    volume: "0,04 l",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hruškovica destilát",
    descr: "52 %",
    volume: "0,04 l",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Višňovica destilát",
    descr: "42 %",
    volume: "0,04 l",
    price: "2,80 €",
    divideAfter: false
  },
]