import uuid from "react-uuid"

export const pivo = [
  {
    item_uuid: uuid(),
    title: "Pivo - Predná Hora",
    descr: "Privátny pivný špeciál",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Ipa 13°",
    descr: "nepasterizované, nefiltrované",
    volume: "0,45 l / 0,25 l",
    price: "2,70 € / 1,80 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "10°",
    descr: "svetlé",
    volume: "0,45 l / 0,25 l",
    price: "2,10 € / 1,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "11°",
    descr: "tmavé",
    volume: "0,45 l / 0,25 l",
    price: "2,30 € / 1,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "12°",
    descr: "svetlé",
    volume: "0,45 l / 0,25 l",
    price: "2,30 € / 1,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Rezané",
    descr: "12° a 11°",
    volume: "0,45 l / 0,25 l",
    price: "2,50 € / 1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Radler nealko",
    descr: "svetlý citrón",
    volume: "0,45 l / 0,25 l",
    price: "2,30 € / 1,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Nealko",
    descr: "",
    volume: "0,45 l / 0,25 l",
    price: "2,30 € / 1,60 €",
    divideAfter: true
  },


  {
    item_uuid: uuid(),
    title: "10°",
    descr: "fľašové",
    volume: "0,33 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "11°",
    descr: "fľašové",
    volume: "0,33 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "12°",
    descr: "fľašové",
    volume: "0,33 l",
    price: "1,90 €"
  },
  {
    item_uuid: uuid(),
    title: "Nealko",
    descr: "fľašové",
    volume: "0,33 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Radler nealko",
    descr: "tmavé citrónové, fľašové",
    volume: "0,33 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Darčekové balenie",
    descr: "4ks fľaš. piva",
    volume: "",
    price: "7,90 €",
    divideAfter: true,
    lastElement: false
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Pilsner Urquell",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Pilsner Urquell 12°",
    descr: "Hladinka",
    volume: "0,45 l",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pilsner Urquell 12°",
    descr: "Hladinka",
    volume: "0,25 l",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pilsner Urquell 12°",
    descr: "Šnyt",
    volume: "0,25 l",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pilsner Urquell 12°",
    descr: "Mlieko",
    volume: "0,25 l",
    price: "1,80 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Plastový pohár",
    descr: "",
    volume: "",
    price: "0,20 €",
    divideAfter: false,
    lastElement: true
  },
]