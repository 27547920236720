import uuid from "react-uuid"

export const vino = [
  {
    item_uuid: uuid(),
    title: "Reya sauvignon blanc",
    descr: "biele",
    volume: "0,1 l",
    price: "1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Reya vicomté d'aumelas",
    descr: "červené",
    volume: "0,1 l",
    price: "1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Reya Elementa",
    descr: "ružové",
    volume: "0,1 l",
    price: "1,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Šumivé víno De Luxe Hubert",
    descr: "",
    volume: "750 ml",
    price: "15 €",
    divideAfter: false
  },

]