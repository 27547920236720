import axios from 'axios';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

// replace with production url
const API_URL = 'http://localhost:8000/api';

const userPool = new CognitoUserPool({
  //@ts-ignore
  UserPoolId: process.env.REACT_APP_MOJEQR_USER_POOL_ID,
  //@ts-ignore
  ClientId: process.env.REACT_APP_MOJEQR_CLIENT_ID
})


const authService = {

  /* ACCESS_TOKEN: localStorage.getItem('access_token'),
  REFRESH_TOKEN: localStorage.getItem('refresh_token'), */



  login: async (username: string, password: string, callback: any) => {
    callback("", "", true)

    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    /* cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        console.log('Login successful:', result);
        console.log(result.getAccessToken().payload.username)
        callback(result.getAccessToken().getJwtToken(), result.getRefreshToken().getToken(), false)
      },
      onFailure: (err) => {
        console.error('Login failed:', err.message || JSON.stringify(err));
        callback("", "", false)
      },
    }); */



    /* axios.post(`${API_URL}/token/`, {
      username: username,
      password: password,
    }).then((response) => {

      callback(response.data.access, response.data.refresh, false)
    }).catch ((error: any) => {
      callback("", "", false)
    }) */
  },


  refresh: (callback: any) => {
    const refreshToken = localStorage.getItem('refresh_token')
    axios.post(`${API_URL}/token/refresh/`, {refresh: refreshToken})
    .then((response) => {
      // set access token
      if (callback) { callback() }
    })
    .catch((error) => {
      console.error('Refresh error:', error.response ? error.response.data : error.message);
    })

  },

  logout: (callback: any) => {
    // remove access and refresh token
    if (callback) { callback() }
  },


}

export default authService;