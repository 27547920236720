import uuid from "react-uuid"

export const partyPack = [
  {
    item_uuid: uuid(),
    title: "Jameson + coca cola",
    descr: "1 l Jameson 40 % + 6x coca cola 200 ml",
    volume: "",
    price: "69 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Absolut vodka + coca cola",
    descr: "1 l Absolut Vodka 40 % + 6x coca cola 200 ml",
    volume: "",
    price: "47,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Absolut vodka + red bull",
    descr: "1 l Absoň vodka 40 % + 6x red bull 250 ml",
    volume: "",
    price: "52 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Gin Beefeater + tonic",
    descr: "1 l Gin Beefeater 40 % + 6x tonic 200 ml (ochutené - 37,5%)",
    volume: "",
    price: "55 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tequila Olmeca + natura",
    descr: "1 l Tequila Olmeca Gold/Silver 35 % + 6x natura 300 ml",
    volume: "",
    price: "59 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jack Daniels + coca cola",
    descr: "1 l Jack Daniels 40 % + 6x coca cola 200 ml",
    volume: "",
    price: "79 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Captain Morgan + coca cola",
    descr: "1 L Captain Morgan 35 % + 6x coca cola 200 ml",
    volume: "",
    price: "45 €",
    divideAfter: false
  },
]