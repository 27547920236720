import uuid from "react-uuid"

import { FaInfoCircle } from "react-icons/fa";
export const nealko = [
  {
    item_uuid: uuid(),
    title: "Rajec",
    descr: "perlivý / jemne perlivý / neperlivý",
    volume: "0,33 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Vinea",
    descr: "biela / červená",
    volume: "0,33 l",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kofola",
    descr: "",
    volume: "0,25 l",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Royal crown",
    descr: "",
    volume: "0,25 l",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tonic",
    descr: "Fentimans Premium Indian Tonic Water",
    volume: "0,20 l",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Džús Rauch",
    descr: "jablko / Jahoda / pomaranč / multivitamín",
    volume: "0,20 l",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Filtrovaná voda",
    descr: "",
    volume: "0,10 l",
    price: "0,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Džbán filtrovanej vody",
    descr: "",
    volume: "1 l",
    price: "2,80 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: FaInfoCircle,
    iconColor: "#201F1D",
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Pri objednávke so sebou",
    descr: `
    Účtujeme si poplatok za obaly v sume<br/>
    - 0,10 € - pohár<br/>
    - 0,60 € - malá krabička<br/>
    - 1,10 € - veĺká krabica<br/>`,
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false,
    lastElement: false
  },

]