import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const tabak = [
  {
    item_uuid: uuid(),
    title: "IQOS tabakové náplne",
    descr: "/&nbsppodľa ponuky&nbsp/",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Marlboro",
    descr: "/&nbspRED, GOLD, SLIM&nbsp/",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Elektronické cigarety",
    descr: "/&nbsppodľa ponuky&nbsp/",
    volume: "",
    price: "",
    divideAfter: false
  },
  /* {
    item_uuid: uuid(),
    title: "Žuvacie tabaky",
    descr: "/&nbsppodľa ponuky&nbsp/",
    volume: "",
    price: "",
    divideAfter: false
  }, */
  {
    item_uuid: uuid(),
    title: "Zapaľovač",
    descr: "",
    volume: "",
    price: "0,70 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
]