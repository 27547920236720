import { useTranslation } from 'react-i18next';
import HtmlParser from '../../services/htmlParser';

interface LocalProps {
  id: string
}

const IntroCard = (props: LocalProps) => {

  const { t, i18n } = useTranslation()

  const handleButtonClick = () => {
    document.getElementById("CONTACT_CARD_ID")?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div
      id={ props.id }
      className="introCard"
    >
      <h1 id='INTRO_CARD_TITLE'>
        <HtmlParser
          key={i18n.language}
        >
          { t("introCard.title") }
        </HtmlParser>
      </h1>
      <h2 id='INTRO_CARD_SUBTITLE'>
        <HtmlParser
          key={i18n.language}
        >
          { t("introCard.subtitle") }
        </HtmlParser>
      </h2>
      <button
        id='INTRO_CARD_BUTTON'
        className="intro_card_button"
        onClick={handleButtonClick}
      >
        <h3 className="intro_card_button">
          { t("introCard.button") }
        </h3>
      </button>

      {/* <div id='INTRO_CARD_DIVIDER' className="introCard_divider"></div> */}
      <div className='trial_wrap' id='INTRO_CARD_TRIAL_TEXT_WRAP_ID'>
        <h4 id='INTRO_CARD_TRIAL_TEXT'>
          { t("introCard.trial") }
        </h4>
      </div>
    </div>
  )
}

export default IntroCard