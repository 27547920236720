import { AlertComponentProps } from "../../PropTypes/AdminDashboardProps"
import { TbAlertTriangle } from "react-icons/tb";
import { FaInfoCircle } from "react-icons/fa";


const AlertComponent = (props:AlertComponentProps) => {
  return(
    <div className="alert_component card_element" id={props.id}>
      <div className="icon">
        <TbAlertTriangle
          id="ALERT_ICON_ID"
          size={"25px"}
          color="#FF7D08"
        />

        <FaInfoCircle
          id="INFO_ICON_ID"
          size={"25px"}
          color="#6A39E2"
        />

      </div>
      <div className="text" id="ALERT_COMPONENT_TEXT">
        alert text here
      </div>
    </div>
  )
}

export default AlertComponent