
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { AiOutlineLoading } from "react-icons/ai";
import { useCookies } from "react-cookie";


const LoginCard = () => {
  const logo = require("../../assets/images/logo.jpg")
  const navigate = useNavigate()
  //@ts-ignore
  const { login } = useAuth()
  const [cookie, setCookie] = useCookies(['mojeqr_access_token', 'mojeqr_refresh_token'])


  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [pendingRequest, setPendingRequest] = useState(false)

  const handleLogin = () => {
    if (userName && password && !pendingRequest) {
      setPendingRequest(true)
      login(userName, password, (e: boolean) => {
        setPendingRequest(e)

      })
    }
  }



  return (
    <div className="login_card_wrap" id="LOGIN_CARD_WRAP">
      <div className="login_card_left_side">
        <div className="mobile_img_wrap">
          <div className="img_bg">
            <img src={logo} alt="MojeQR" />
          </div>
        </div>
        <div className="title_wrap">
          <span className="title">
            Vitajte
          </span>
        </div>
        <div className="global_input_wrap">
          <div className="input_wrap">
            <label htmlFor="">Email</label>
            <input
              value={userName}
              type="text"
              placeholder="Vaša emailová adresa"
              onChange={(e) => {setUserName(e.target.value)}}
            />
          </div>
          <div className="input_wrap">
            <label htmlFor="">Heslo</label>
            <input
              value={password}
              type="password"
              placeholder="Vaše heslo"
              onChange={(e) => {setPassword(e.target.value)}}
            />
          </div>
          <div className="input_wrap login_button_wrap">
            <button
              disabled={pendingRequest}
              onClick={handleLogin}
            >
              Prihlásiť sa
            </button>
            <button
              onClick={() => { navigate("/")}}
            >
              Späť domov
            </button>
            {
              pendingRequest &&
              <AiOutlineLoading
                size={"45px"}
                /* color="#8E62EA" */
                color="white"
                className="loading_icon"
              />
            }
          </div>
        </div>
      </div>
      <div className="login_card_right_side">
        <div className="image_wrap">
          <img src={logo} alt="MojeQR" />
        </div>
        <div className="tips_wrap">
          <div className="tips">
            Pripravujeme pre vás úplne nový admin panel
            <br />
            Už čoskoro !
          </div>
          <div className="dots">
            • • •
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginCard