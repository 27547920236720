import { FiCopy } from "react-icons/fi";
import { FaRegEdit, FaFileInvoice  } from "react-icons/fa";
import { AiFillLayout } from "react-icons/ai";
import { IoShareSocial } from "react-icons/io5";
import { FaQuestionCircle } from "react-icons/fa";
import { FaRegImage } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { SiGmail } from "react-icons/si";



import { copyToClipboard } from "../../services/userInteractionService";

import { ClientSettingsCardProps, OpenedSettingsTabIndexType } from "../../PropTypes/AdminDashboardProps";
import { useAuth } from "../../context/AuthContext";


const NavigationCard = (props: ClientSettingsCardProps) => {

  //@ts-ignore
  const { user } = useAuth()

  const mojeqr_logo = require('../../assets/images/logo.jpg')
  const logo_badge = require('../../assets/images/clients/logos/panorama.jpg')

  const getTopMargin = (selectedTab: OpenedSettingsTabIndexType) => {
    switch (selectedTab) {
      case 0:
        return 8
      case 1:
        return 41
      case 2:
        return 75
      case 3:
        return 109
      case 4:
        return 143
      case 5:
        return 177

      default:
        break;
    }
  }

  const sendMail = () => {
    var link = "mailto:info@mojeqr.sk"
             + "&subject=" + encodeURIComponent("MojeQR - Admin panel požiadavka")
    ;

    window.location.href = link;
}

  const settings_elements = [
    {
      title: "Editácia ponuky",
      onClick: () => { props.onPriceEditClick() },
      icon: <FaRegEdit size={"20px"}/>
    },
    {
      title: "Zmena rozloženia",
      onClick: () => { props.onLayoutEditClick() },
      icon: <AiFillLayout size={"20px"}/>
    },
    {
      title: "Galéria",
      onClick: () => { props.onImageGalleryClick() },
      icon: <FaRegImage  size={"20px"}/>
    },
    {
      title: "Úprava kontaktu",
      onClick: () => { props.onContactEditClick() },
      icon: <IoShareSocial  size={"20px"}/>
    },
    {
      title: "Faktúry a predplatné",
      onClick: () => { props.onInvoiceSubscriptionClick() },
      icon: <FaFileInvoice  size={"20px"}/>
    },
    {
      title: "Ako na to",
      onClick: () => { props.onHowToClick() },
      icon: <FaQuestionCircle  size={"20px"}/>
    },
  ]

  return (
    <>
      <div className="logo_wrap">
        <div className="logo_bg">
          <img src={logo_badge} alt="Klient logo" className="client_logo" />
          <img src={mojeqr_logo} alt="MojeQr logo" className="mojeqr_logo"/>
        </div>
      </div>
      <div className="name_wrap">
        <div className="name">
          { `${user?.first_name} ${user?.last_name}` }
          { `${user}` }
        </div>
        <div className="email">
          {/* { user?.email } */}
          info@mojeqr.sk
          <FiCopy
            className="icon"
            /* onClick={() => { copyToClipboard(user?.email) }} */
            onClick={() => { copyToClipboard("info@mojeqr.sk") }}
          />
          <SiGmail
            className="icon"
            /* onClick={() => { copyToClipboard(user?.email) }} */
            onClick={() => { sendMail() }}
          />
          <FaFacebookSquare
            className="icon"
            /* onClick={() => { copyToClipboard(user?.email) }} */
            onClick={() => { window.open("https://www.facebook.com/profile.php?id=100089698576173") }}
          />
          <RiInstagramFill
            className="icon"
            /* onClick={() => { copyToClipboard(user?.email) }} */
            onClick={() => { window.open("https://www.instagram.com/mojeqr/") }}
          />
        </div>
      </div>
      <div className="settings_wrap">
        <div className="title_wrap">
          Nastavenia
        </div>
        <div className="settings_elements_wrap">
          <div
            className="settings_element_active_indicator"
            style={{
              top: `${getTopMargin(props.activeTabNumber)}px`
            }}
          />
          {
            settings_elements.map((option, index) =>
              <div
                className={`settings_element ${index === props.activeTabNumber ? "settings_element_active" : ""}`}
                key={index}
                onClick={option.onClick}
              >
                {option.icon}
                {option.title}
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

export default NavigationCard