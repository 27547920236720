import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
export const pivo = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Svetlé pivo",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Šariš",
    descr: "10°",
    volume: "0,50 l",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Bakalář",
    descr: "10°",
    volume: "0,50 l",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Krušovice",
    descr: "12°",
    volume: "0,50 l",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pilsner Urquell",
    descr: "12°",
    volume: "0,50 l",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Corona Extra",
    descr: "4,5°",
    volume: "0,35 l",
    price: "3,00 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Nealkoholické pivo",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Birell Nealko",
    descr: "",
    volume: "0,50 l",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Birell Pomelo-grep",
    descr: "2°",
    volume: "0,50 l",
    price: "2,10 €",
    divideAfter: true
  },

  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]