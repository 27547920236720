import { OfferElement } from "../PropTypes/AdminDashboardProps";

export const copyToClipboard = (contentToCopy: string) => {
  navigator.clipboard.writeText(contentToCopy)
}

export const dynamicListHeightSetup = () => {
  const header = document.getElementById("EDIT_PRICE_HEADER_ROW");
  const listHeader = document.getElementById("LIST_HEADER_ID");
  const list = document.getElementById("LIST_ROW_ID");

  const setListHeight = () => {
    if (header && listHeader) {
      const headerHeight = header.offsetHeight;
      const listHeaderHeight = listHeader.offsetHeight;
      const remainingHeightInPixels = window.innerHeight - headerHeight - listHeaderHeight - 85;

      list!.style.height = `${remainingHeightInPixels}px`;
    }
  };
  setListHeight();

  // Add event listener for window resize
  window.addEventListener('resize', setListHeight);

  // Cleanup: remove event listener when the component is unmounted
  return () => {
    window.removeEventListener('resize', setListHeight);
    // Additional cleanup code if needed
  }
}

let alertTimeoutId: any

export const invokeAlertComponent = ( alert_element_id: string, text: string, type: "INFO" | "ALERT" = "INFO", appearence_time: number = 2500) => {
  const element = document.getElementById(alert_element_id)
  const textElement = document.getElementById("ALERT_COMPONENT_TEXT")

  /* const iconAlert = document.getElementById("ALERT_ICON_ID")
  const iconInfo = document.getElementById("INFO_ICON_ID")
  iconAlert!.style.display = "block"
  iconInfo!.style.display = "block" */
  const icon = document.getElementById(type === "INFO" ? "ALERT_ICON_ID" : "INFO_ICON_ID")
  const anotherIcon = document.getElementById(type !== "INFO" ? "ALERT_ICON_ID" : "INFO_ICON_ID")


  if (element && textElement && icon) {
    element.style.top = "20px"
    textElement.innerText = text
    icon.style.display = "none"
    anotherIcon!.style.display = "block"

    // Clear the previous timeout (if any)
    if (alertTimeoutId) {
      clearTimeout(alertTimeoutId);
    }

    // Set a new timeout
    alertTimeoutId = setTimeout(() => {
      element.style.top = "-100px";
    }, appearence_time)
  }
}


export const elementValidation = (element: OfferElement) => {

  return element.item_name

}