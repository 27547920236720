import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const studene_kavove_napoje = [
  {
    item_uuid: uuid(),
    title: "Espresso Tonic",
    descr: "8 g káva, tonic, ružový grep sirup, citrón, ľad",
    volume: "",
    price: "3,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso Mojito",
    descr: "8 g káva, sóda, mojito sirup, limetková šťava, ľad",
    volume: "",
    price: "3,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Caffe Frapé",
    descr: "Karamel / Čokoláda - 8 g káva, mlieko, cukor, toping podľa výberu, ľad",
    volume: "",
    price: "3,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ľadové Mocchaccino",
    descr: "Karamel / Čokoláda - 8 g káva, mlieko, cukor, šľahačka, toping podľa výberu, ľad",
    volume: "",
    price: "3,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ice Caffé Latte",
    descr: "Pražený orech / Brownies / Slaný karamel - 8 g káva, mlieko, sirup podľa výberu, ľad",
    volume: "",
    price: "3,60 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]