//@ts-nocheck
import uuid from "react-uuid"

import coffeeIcon from "../../assets/images/clients/icons/panorama/coffee-cup.svg"
import consoleIcon from "../../assets/images/clients/icons/obyvacka_bar/console.svg"
/* import winterDrinkIcon from "../../assets/images/clients/icons/obyvacka_bar/winterDrink.svg" */
import infoIcon from "../../assets/images/clients/icons/obyvacka_bar/info.svg"
import nonalcoIcon from "../../assets/images/clients/icons/panorama/drink.svg"
import beerIcon from "../../assets/images/clients/icons/panorama/beer.svg"
import snacksIcon from "../../assets/images/clients/icons/panorama/snack.svg"
import wineIcon from "../../assets/images/clients/icons/panorama/glass-with-wine.svg"
import drinkIcon from "../../assets/images/clients/icons/panorama/cocktail.svg"
import specialginIcon from "../../assets/images/clients/icons/panorama/ginspecial.svg"
import alcoIcon from "../../assets/images/clients/icons/panorama/shots.svg"
import bottleIcon from "../../assets/images/clients/icons/panorama/bottle.svg"
import summerIcon from "../../assets/images/clients/icons/obyvacka_bar/summerOfferIcon.svg"

import { letneMenu } from "./elements/letneMenu"

import { kavaCaj } from "./elements/kavaCaj"
import { nealko } from "./elements/nealko"

import { pivo } from "./elements/pivo"
import { vino } from "./elements/vino"

import { liker } from "./elements/liker"
import { vodka } from "./elements/vodka"

import { destilaty } from "./elements/destilaty"
import { brandyWhiskyKonak } from "./elements/brandyWhiskyKonak"

import { tequilaGin } from "./elements/tequilaGin"
import { rum } from "./elements/rum"

import { extraPochutinyTabak } from "./elements/extraPochutinyTabak"
import { hernySvet } from "./elements/hernySvet"

import { alkoholickeShoty } from "./elements/alkoholickeShoty"
import { miesaneNealkoDrinky } from "./elements/miesaneNealkoDrinky"

import { miesaneAlkoDrinky } from "./elements/miesaneAlkoDrinky"
import { partyPack } from "./elements/partyPack"

import { info } from "./elements/info"


export const obyvackaBarMenu =  [
  [
    {
      id: "LETNE_MENU_ID",
      icon: summerIcon,
      iconWidth: "60px",
      iconMarginRight: "0px",
      page_uuid: uuid(),
      titleShort: "Letné menu",
      title: "Letné menu",
      list: letneMenu,
      isOpen: false,
    },
  ],
  [
    {
      id: "PIVO_ID",
      icon: beerIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Pivo",
      title: "Pivo",
      list: pivo,
      isOpen: false,
    },
    {
      id: "VINO_ID",
      icon: wineIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Víno",
      title: "Víno",
      list: vino,
      isOpen: false,
    },

  ],
  [
    {
      id: "KAVA_CAJ_ID",
      icon: coffeeIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Káva/Čaj",
      title: "Káva/Čaj",
      list: kavaCaj,
      isOpen: false,
    },
  ],
  [
    {
      id: "LIKER_ID",
      icon: alcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Likér",
      title: "Likér",
      list: liker,
      isOpen: false,
    },
    {
      id: "VODKA_ID",
      icon: alcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Vodka",
      title: "Vodka",
      list: vodka,
      isOpen: false,
    },

  ],
  [
    {
      id: "NEALKO_NAPOJE_ID",
      icon: nonalcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Nealko Nápoje",
      title: "Nealkoholické Nápoje",
      list: nealko,
      isOpen: false,
    },
  ],
  [
    {
      id: "TEQUILA_GIN_ID",
      icon: drinkIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Tequila<br/>Gin",
      title: "Tequila/Gin",
      list: tequilaGin,
      isOpen: false,
    },
    {
      id: "HERNY_SVET",
      icon: consoleIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Herný<br/>svet",
      title: "Herný svet",
      list: hernySvet,
      isOpen: false,
    },
  ],
  [
    {
      id: "DESTILATY_ID",
      icon: alcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Destiláty",
      title: "Destiláty",
      list: destilaty,
      isOpen: false,
    },
    {
      id: "RUM_ID",
      icon: alcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Rum",
      title: "Rum",
      list: rum,
      isOpen: false,
    },
  ],
  [
    {
      id: "EXTRA_POCHUTINY_TABAK_ID",
      icon: snacksIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Extra<br/>Pochutiny<br/>Tabak",
      title: "Extra/Pochutiny/Tabak",
      list: extraPochutinyTabak,
      isOpen: false,
    },
    {
      id: "BRANDY_WHISKY_KONAK_ID",
      icon: alcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Brandy<br/>Whisky<br/>Koňak",
      title: "Brandy/Whisky/Koňak",
      list: brandyWhiskyKonak,
      isOpen: false,
    },
  ],
  [
    {
      id: "MIESANE_ALKO_ID",
      icon: drinkIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Miešané alkoholické drinky",
      title: "Miešané alkoholické drinky",
      list: miesaneAlkoDrinky,
      isOpen: false,
    },
    {
      id: "MIESANE_NEALKO_ID",
      icon: specialginIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Miešané nealkoholické drinky",
      title: "Miešané nealkoholické drinky",
      list: miesaneNealkoDrinky,
      isOpen: false,
    },
  ],
  [
    {
      id: "ALKO_SHOTY_ID",
      icon: alcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Alkoholické<br/>shoty",
      title: "Alkoholické shoty",
      list: alkoholickeShoty,
      isOpen: false,
    },
    {
      id: "PARTY_PACK_ID",
      icon: bottleIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Párty<br/>pack",
      title: "Párty pack",
      list: partyPack,
      isOpen: false,
    },
  ],
  [
    {
      id: "INFO_ID",
      icon: infoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Kupón na ďalšiu návštevu",
      title: "Kupón na ďalšiu návštevu",
      list: info,
      isOpen: false,
    },
  ]
]

