import { LogoutCardProps } from "../../PropTypes/AdminDashboardProps"

const LogoutCard = (props: LogoutCardProps) => {
  return (
    <>
      <button
        onClick={props.onLogout}
        className="logout_button"
      >
        Odhlásiť sa
      </button>
    </>
  )
}

export default LogoutCard