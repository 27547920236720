const ItemElementHeader = () => {
  return(
    <div className="item_element_header card_element" id="LIST_HEADER_ID">
      <div className="table_class_id">
        Id
      </div>
      <div className="table_class_name">
        Názov
      </div>
      <div className="table_class_description">
        Popis
      </div>
      <div className="table_class_price">
        Cena
      </div>
      <div className="table_class_volume">
        Objem
      </div>
      <div className="table_class_flag">
        Vlajky
      </div>
      <div className="table_class_type">
        Typ
      </div>
      <div className="table_class_status">
        Status
      </div>
      <div className="table_class_remove">

      </div>

    </div>
  )
}

export default ItemElementHeader