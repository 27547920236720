import uuid from "react-uuid"

export const info = [
  {
    item_uuid: uuid(),
    title: "Za každých 10,-eur nákupu v našom bare získate po platbe 1 eurový kupón na ďalšiu návštevu.",
    descr: "",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ak je váš účet napr. 31,-eur, získáte u nás v podobe kupónov 3 eurá na ďalší nákup.",
    descr: "",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Kupóny je možné kombinovať.",
    descr: "",
    volume: "",
    price: "",
    divideAfter: false
  },

]