import uuid from "react-uuid"

export const miesaneAlkoDrinky = [
  {
    item_uuid: uuid(),
    title: "Aperol Spritz",
    descr: "aperol, prosecco, sóda, limetka",
    volume: "300 ml",
    price: "5,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Obývačka gin",
    descr: "sirup grep, gin, prosecco, limetka, bazalka",
    volume: "300 ml",
    price: "5,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Anarchy",
    descr: "sirup ananás-mäta, rum, nochino, limetka",
    volume: "300 ml",
    price: "5,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mr. James",
    descr: "sirup levanduľa-arónia, jameson, sóda, limetka",
    volume: "300 ml",
    price: "5,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Sexi Nochino",
    descr: "nochino, prosecco, limetka",
    volume: "300 ml",
    price: "5,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Peach dream",
    descr: "nochino, vodka, broskyňový džús, limetka",
    volume: "300 ml",
    price: "5,50 €",
    divideAfter: false
  },
]