import uuid from "react-uuid"
import premium_flag from "../../../assets/images/clients/icons/obyvacka_bar/premium_flag.png"
import recommended_flag from "../../../assets/images/clients/icons/obyvacka_bar/recommended_flag.png"

export const rum = [
  {
    item_uuid: uuid(),
    title: "Captain Morgan spiced",
    descr: "35 %",
    volume: "0,04 l",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Havana Club 7 ročný",
    descr: "35 %",
    volume: "0,04 l",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Rum La Hechicera",
    descr: "40 %",
    flagBehindTitle: false,
    flag: recommended_flag,
    volume: "0,04 l",
    price: "4,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Rum La Hechicera Banana",
    descr: "41 %",
    flagBehindTitle: false,
    flag: [premium_flag, recommended_flag],
    flagHeight: ["25px", "22px"],
    volume: "0,04 l",
    price: "5,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Rum Bacardi Carta Negra tmavý",
    descr: "40 %",
    flagBehindTitle: false,
    flag: premium_flag,
    flagHeight: "25px",
    volume: "0,04 l",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Brugal 1888 ron gran reserva",
    descr: "40 %",
    volume: "0,04 l",
    price: "5,50 €",
    divideAfter: false
  },

  {
    item_uuid: uuid(),
    title: "El general 15 ročný",
    descr: "40 %",
    volume: "0,04 l",
    price: "4,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ron De Azur Panama 6 ročný",
    descr: "38 %",
    volume: "0,04 l",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Rum Božkov REPUBLICA",
    descr: "38 %",
    volume: "0,04 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Rum Bumbu the original",
    descr: "40 %",
    volume: "0,04 l",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Rum Bumbu cream",
    descr: "15 %",
    volume: "0,04 l",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Rum Bumbu XO",
    descr: "40 %",
    volume: "0,04 l",
    price: "3,90 €",
    divideAfter: false
  },
]