import HtmlParser from "../../../services/htmlParser"
import {
  GiChiliPepper
} from "react-icons/gi"

/* interface localProps {
  element: {
    title: string,
    descr: string,
    volume: string,
    price: string,
    blockId?: string,
    divideAfter: boolean,
    lastElement?: boolean,
    type?: "extra" | "description" | "title"
    typeAfter?: "extra" | "description",
    icon?: any,
    iconSize?: string,
    spicyLevel?: 1 | 2 | 3
  }
} */
interface localProps {
  element: {
    title: string,
    descr: string,
    volume: string,
    price: string,
    blockId?: string,
    divideAfter: boolean,
    lastElement?: boolean,
    type?: "extra" | "description" | "title"
    typeAfter?: "extra" | "description",
    icon?: any,
    iconSize?: string,
    iconColor?: string,
    spicyLevel?: 1 | 2 | 3
  },
  localConfig: any
}

const PrednaHoraItemElement = (props: localProps) => {


  return (
    <div
      id={props.element.blockId}
      className="menu_item_element"
    >
      <div className="title_row">
        <div
          className="title"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: props.element.type === "title" ? "10px" : "0px"
          }}
        >
          {
            ((props.element.type === "extra" || props.element.type === "description") && props.element.icon)  &&
            <div className="title_descr_icon_wrap">
              <props.element.icon
                className="icon"
                color={props.element.iconColor || "white"}
                size={props.element.iconSize || "30px"}
              />
            </div>
          }
          <span>{ props.element.title }</span>
          {
            props.element.spicyLevel && Array.from({ length: props.element.spicyLevel }, (_, index) => (
              <GiChiliPepper
                key={index}
                size={"20px"}
                style={{
                  marginLeft: index > 0 ? "-10px" : "15px"
                }}
                color="#F7C808"
              />
            ))
          }
        </div>
        <div className="price">
          { props.element.price }
        </div>
      </div>
        <div className="description_row">
          <div className="descr">
            <HtmlParser>
              { props.element.descr }
            </HtmlParser>
          </div><div className="volume">
            <HtmlParser>
              { props.element.volume }
            </HtmlParser>
          </div>
        </div>
      {
      props.element.divideAfter &&
        (
          props.element.type === "title"
            ?
            <div
              style={{
                marginTop: "0px",
                width: "100%",
                backgroundColor: props.localConfig.dividerColor
              }}
              className="divider"
            />
            :
            <div
              style={{
                width:
                  (
                    props.element.type === "extra"
                      || props.element.type === "description"
                      || props.element.typeAfter === "extra"
                      || props.element.typeAfter === "description"
                      || props.element.type === "title"
                  )
                      ? "100%" : "90%",
                backgroundColor: props.localConfig.dividerColor
              }}
              className="divider"
          />
        )
      }
    </div>
  )
  /*
  return (
    <>
      <div
        className="item_element"
        id={props.element.blockId}
        style={{
          paddingBottom: props.element.lastElement ? "15px" : "0px"
        }}
      >

        <div
          className="title_descr"
        >
          {
            ((props.element.type === "extra" || props.element.type === "description") && props.element.icon)  &&
            <div className="title_descr_icon_wrap">
              <props.element.icon
                className="icon"
                size={props.element.iconSize || "30px"}
              />
            </div>
          }
          <div>
            {
              props.element.type === "title"
                ?
                  <h3
                    style={{
                      marginTop: "27px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row-reverse"
                    }}
                  >
                    { props.element.title }
                    {
                      props.element.icon &&
                      <props.element.icon
                        size={props.element.iconSize || "20px"}
                        color="white"
                        style={{
                          marginRight: "10px"
                        }}
                      />
                    }

                  </h3>
                :
                <>
                  <h3
                    style={{
                      marginTop: (props.element.type === "description" && props.element.descr.length === 0) ? "27px" : "18px",
                      paddingRight: "15px"
                    }}
                  >
                    { props.element.title }
                    {
                      props.element.spicyLevel && Array.from({ length: props.element.spicyLevel }, (_, index) => (
                        <GiChiliPepper
                          key={index}
                          size={"20px"}
                          style={{
                            marginLeft: index > 0 ? "-10px" : "15px"
                          }}
                          color="#F7C808"
                        />
                      ))
                    }
                  </h3>
                  {
                    (props.element.descr.length > 0) &&

                    <h4>
                      <HtmlParser>
                        { props.element.descr }
                      </HtmlParser>
                    </h4>
                  }
                </>

            }
          </div>
        </div>
        {
          (props.element.volume.length > 0 || props.element.price.length > 0) &&
          <div className="volume_price">
            <h3>
              <HtmlParser>
                { props.element.volume }
              </HtmlParser>
            </h3>
            <h3>
              { props.element.price }
            </h3>
          </div>
        }
      </div>
      {
      props.element.divideAfter &&
        (
          props.element.type === "title"
            ?
            <div
              style={{
                marginTop: "0px",
                width: "100%"
              }}
              className="divider"
            />
            :
            <div
              style={{
                marginTop: props.element.descr.length > 0 ? "0px" : "15px",
                width:
                  (
                    props.element.type === "extra"
                      || props.element.type === "description"
                      || props.element.typeAfter === "extra"
                      || props.element.typeAfter === "description"
                      || props.element.type === "title"
                  )
                      ? "100%" : "90%"
              }}
              className="divider"
          />
        )
      }
    </>
  )
  */
}

export default PrednaHoraItemElement