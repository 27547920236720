import uuid from "react-uuid"

export const alkoholickeShoty = [
  {
    item_uuid: uuid(),
    title: "Rhubar's axe",
    descr: "sirup rebarbora, vodka, limetka",
    volume: "0,04 l",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Forerest gumb",
    descr: "sirup ananás-mäta, borovička, limetka",
    volume: "0,04 l",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mercy",
    descr: "sirup levanduľa-arónia, vodka, limetka",
    volume: "0,04 l",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Barbados",
    descr: "sirup grep-bazalka, vodka, limetka",
    volume: "0,04 l",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Brasil Tree",
    descr: "sirup passion fruit- mandarínka, vodka, limetka",
    volume: "0,04 l",
    price: "2,90 €",
    divideAfter: false
  },
]