import { IoMdArrowRoundBack } from "react-icons/io"
import { jazzCafeMenu } from "../../content/JazzCafeHumenne/menu"

export const jazzCafeConfig = {
  isRestaurant: false,
  menu: jazzCafeMenu,
  menuUpdating: false,
  logo: require("../../assets/images/clients/logos/jazz_cafe.jpg"),
  /* logo: require("../../assets/images/clients/logos/christmas/jazz_cafe_christmas.jpg"), */


  // after click on section screen scrolls to that section
  autoscrollAfterClick: false,
  scrollToTopAferOpen: true,
  // left alignment if displayed in rows
  alignTitleToLeft: false,
  //scroll time of scroll to section
  autoscrollTimeInMs: 100,
  //scroll to top of page after closing section
  scrollToTopAfterClose: true,
  //icon that appears in corner when section is opened
  hideAllIcon: IoMdArrowRoundBack,
  //decides if other sections should dissapear when section is opened
  hideAllWhenOneOpen: true,
  //vibrate on click on section
  vibrateOnClick: false,
  // close previous section when new section is opened
  closePreviousSectionOn: true,
  //margin top to display all sections
  displayAllOnPhone: false,
  //contact icon color
  iconColor: "#1a1a18",
  //links to social sites
  facebook: "https://www.facebook.com/profile.php?id=100063532212164",
  instagram: null,
  email: "jazzcafehumenne@gmail.com",
  phone: "+421 907 677 222",

  //styles
  mainWrapClass: "jazzCafe",
  bottomNote: ""
  /*
  logoBorderRadius: "8px",
  background: "#CFCAC4",
  blockBackgroundColor: "#878787",
  dividerColor: "#F7C808", */


}
