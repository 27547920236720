import uuid from "react-uuid"

export const ladova_kava = [
  {
    item_uuid: uuid(),
    title: "Ľadové frappé",
    descr: "7 g káva, 10 cl mlieko, ľad",
    volume: "",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ľadové frappé so šľahačkou",
    descr: "7 g káva, 10 cl mlieko, ľad, 20 g šľahačka",
    volume: "",
    price: "3,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ľadové frappé \"special\"",
    descr: "7 g káva, 10 cl mlieko, ľad, 2 cl sirup</br>- oriešok, karamel, vanilka, čokoláda, škorica, kokos, banán, gaštan",
    volume: "",
    price: "3,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "KAFFA \"ice coffe\" vanilla",
    descr: "7 g káva, 30 g vanilková zmrzlina, 20 g šľahačka, ľad",
    volume: "",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ľadová čokoláda",
    descr: "30 g ľadová čokoláda, 15 cl mlieko, ľad",
    volume: "",
    price: "3,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ľadové kapučíno",
    descr: "30 g ľadové kapučíno, 15 cl mlieko, ľad</br>- klasické",
    volume: "",
    price: "3,00 €",
    divideAfter: false
  },
]