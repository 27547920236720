import uuid from "react-uuid"

export const extraPochutinyTabak = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Pochutiny",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Tyčinky",
    descr: "",
    volume: "45 g",
    price: "1,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Chips",
    descr: "podľa ponuky",
    volume: "70 g",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Arašidy",
    descr: "",
    volume: "100 g",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Horalka",
    descr: "",
    volume: "50 g",
    price: "1,00 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Vodná Fajka",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Vodná fajka",
    descr: "",
    volume: "50 g",
    price: "10,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Vodná fajka s ovocím",
    descr: "",
    volume: "50 g",
    price: "11,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Vodná fajka s vodkou / rumom",
    descr: "",
    volume: "50 g",
    price: "12,00 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Tabak",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "IQOS cigarety",
    descr: "",
    volume: "",
    price: "",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Elektronická cigareta",
    descr: "",
    volume: "",
    price: "",
    divideAfter: false
  },

]