import uuid from "react-uuid"

import premium_flag from "../../../assets/images/clients/icons/obyvacka_bar/premium_flag.png"

export const liker = [
  {
    item_uuid: uuid(),
    title: "Becherovka",
    descr: "38 %",
    volume: "0,04 l",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jagermaister",
    descr: "35 %",
    volume: "0,04 l",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ararat Coffee",
    descr: "30 %",
    flagBehindTitle: true,
    flag: premium_flag,
    flagHeight: "25px",
    volume: "0,04 l",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tatranský čaj",
    descr: "52 %",
    volume: "0,04 l",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fernet stock",
    descr: "30 %",
    volume: "0,04 l",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fernet stock citrus",
    descr: "30 %",
    volume: "0,04 l",
    price: "2,10 €",
    divideAfter: false
  },
]