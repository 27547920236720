import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
import new_flag from "../../../assets/images/clients/icons/mercy_coffee/new_flag.png"

export const pochutiny = [
  {
    item_uuid: uuid(),
    title: "Nachos - doritos",
    descr: "+ omáčka salsa/cheese dip",
    volume: "0,50 €",
    price: "2,00 €",
    flagBehindTitle: true,
    flagHeight: "25px",
    flag: new_flag,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pistácie",
    descr: "",
    volume: "60 g",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tyčinky",
    descr: "",
    volume: "45 g",
    price: "1,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Chipsy Lays",
    descr: "",
    volume: "70 g",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Chrumky",
    descr: "",
    volume: "60 g",
    price: "1,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mandle pražené v horkej čokoláde",
    descr: "",
    volume: "100 g",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mandle pražené v jogurtovej poleve",
    descr: "",
    volume: "100 g",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mandle pražené v mliečnej čokoláde a škorici",
    descr: "",
    volume: "100 g",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Arašidy pražené v mliečnej čokoláde",
    descr: "",
    volume: "100 g",
    price: "2,20 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]