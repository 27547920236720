import { useEffect } from "react"
import PrednaHora from "../../components/clients/PrednaHora/PrednaHora"

const PrednaHoraPage = () => {

  useEffect(()=> {
    document.title = `MojeQr - Piváreň Predná Hora`
    document.getElementsByTagName('meta')[3].content = `Piváreň Predná Hora využíva službu MojeQR. Mrkni sa sem a v prípade záujmu nás neváhaj kontaktovať na +421 940 850 530 alebo emailom na info@mojeqr.sk. Čakáme na teba!`
    document.body.style.backgroundColor = '#000000'
    document.getElementsByTagName('link')[2].href = "https://www.mojeqr.sk/predna_hora"
  })

  return (
    <PrednaHora />
  )
}

export default PrednaHoraPage

